import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Translator from 'presentation/components/i18n/Translator';

export const schema = yup.object().shape({
  org: yup.object().when('currentStep', {
    is: (value: number) => value === 1,
    then: yup.object().shape({
      name: yup
        .string()
        .required(Translator('O nome da organização é obrigatório')),
      id: yup.number().required(),
    }),
    otherwise: yup.object().shape({
      name: yup.string().optional(),
      id: yup.number().optional(),
    }),
  }),
  name: yup
    .string()
    .max(256)
    .when('currentStep', {
      is: (value: number) => value === 1,
      then: yup
        .string()
        .required(Translator('O título da pesquisa é obrigatório')),
      otherwise: yup.string().optional(),
    }),
  descr: yup
    .string()
    .max(1024)
    .required(Translator('A descrição da pesquisa é obrigatória')),

  organizers: yup.array().of(
    yup
      .object()
      .shape({
        fullname: yup.string().max(256).optional(),
        email: yup.string().email().max(256).optional(),
        userId: yup.string().optional(),
      })
      .optional(),
  ),
  groups: yup.array().of(
    yup.object().shape({
      id: yup.string().optional(),
      name: yup.string().max(256).optional(),
    }),
  ),
  currentStep: yup.number().integer().positive().optional(),
});

export const groupName = yup.object().shape({
  groupName: yup
    .string()
    .max(256)
    .when('currentStep', {
      is: (value: number) => value === 3,
      then: yup.string().required(Translator('O nome do grupo é obrigatório')),
      otherwise: yup.string().optional(),
    }),
});

export const addNewOrganizer = yup.object().shape({
  organizerName: yup
    .string()
    .max(256)
    .required('O nome do responsável é obrigatório'),
  organizerEmail: yup
    .string()
    .email()
    .required(Translator('O e-mail do responsável é obrigatório')),
});

export const schemaAddNewOrganizer = yupResolver(addNewOrganizer);

export const schemaCreateNewSearch = yupResolver(schema);

export const schemaGroupName = yupResolver(groupName);
