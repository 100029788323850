/* eslint-disable react-hooks/exhaustive-deps */
import { makeRemoteCreateResearch } from 'main/factories/usecases/research/CreateResearchFactory';
import { navigate } from 'main/routes/_CustomRouter';
import { IconResearch } from 'presentation/base/icons';
import Translator from 'presentation/components/i18n/Translator';
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Step, StepMagic } from 'presentation/components/steps';
import { Button, Footer } from 'presentation/components/UI';
import React, { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';
import { schemaCreateNewSearch } from 'validation/validators/NewSearchValidator/NewSearchValidator';
import { Header, HeaderSimples } from '../../Header';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import {
  ContainerForm,
  ContentStepForm,
  FooterContent,
  StepContainer,
} from './styles';

interface FormInfoToSend {
  org: { name: string; id: number };
  name: string;
  descr: string;
  organizers: {
    fullname: string;
    email: string;
    userId: string;
  }[];
  currentStep: number;
  groups: {
    name: string;
  }[];
}

export const NewResearchForm: React.FC = (): JSX.Element => {
  const theme = useTheme();

  const formHook = useForm<FormInfoToSend>({
    shouldFocusError: true,
    resolver: schemaCreateNewSearch,
    defaultValues: {
      currentStep: 1,
      organizers: [],
      groups: [],
    },
  });

  const currentForm = useMemo(() => {
    const steps: Record<number, any> = {
      1: <Step1 />,
      2: <Step2 />,
      3: <Step3 />,
      4: <Step4 />,
    };

    return steps[formHook.watch('currentStep')] || null;
  }, [formHook.watch('currentStep')]);

  const handleBar = (value: number) => {
    if (value === 5) {
      createNewSearch();
      return;
    }

    if (value === 3) {
      if (!formHook.getValues()?.organizers.length) {
        toast.error(
          Translator('A pesquisa deve possuir pelo menos um coordenador'),
        );
        return;
      }
    }

    if (value === 4) {
      if (!formHook.getValues()?.groups.length) {
        toast.error(Translator('A pesquisa deve possuir pelo menos um grupo'));
        return;
      }
    }

    if (value === 0 || value > 5) return;

    formHook.setValue('currentStep', value);
  };

  const createNewSearch = () => {
    const organizers = [...formHook.getValues()?.organizers];
    const groups = [...formHook.getValues()?.groups];

    const organizersFormatted = organizers.map(organizer => {
      return {
        fullname: organizer.fullname,
        email: organizer.email,
      };
    });

    const groupsFormatted = groups.map(organizer => {
      return {
        name: organizer.name,
      };
    });

    const objToSend = {
      orgId: formHook.getValues()?.org.id,
      name: formHook.getValues()?.name,
      descr: formHook.getValues()?.descr,
      organizers: organizersFormatted,
      groups: groupsFormatted,
    };

    makeRemoteCreateResearch()
      .create({
        body: objToSend,
      })
      .then(() => {
        toast.success(Translator('Pesquisa criada com sucesso'));
        navigate('/home');
      })
      .catch(err => {
        if (Object.values(err)[0] === 'conflict') {
          console.log(err.message);
          toast.error(
            Translator(
              'Já existe uma pesquisa cadastrada com essas informações.',
            ),
          );
        } else {
          toast.error(Translator('Não foi possível criar a pesquisa'));
        }

        console.error(err);
      });
  };

  return (
    <>
      <Header />
      <HeaderSimples title={Translator('Nova Pesquisa')} Icon={IconResearch} />
      <ContainerForm>
        <StepContainer>
          <div className="step-container">
            <StepMagic current={formHook.watch('currentStep')}>
              <Step title={Translator('Informações')} />
              <Step title={Translator('Coordenadores')} />
              <Step title={Translator('Grupos')} />
              <Step title={Translator('Revisão')} />
            </StepMagic>
          </div>
        </StepContainer>
        <FormProvider {...formHook}>
          <form
            style={{ width: '100%' }}
            onSubmit={formHook.handleSubmit(() =>
              handleBar(formHook.watch('currentStep') + 1),
            )}
          >
            <ContentStepForm>{currentForm}</ContentStepForm>

            <FooterContent>
              <div className="buttons-left">
                <Button
                  size="medium"
                  data-testid="btn_cancel"
                  onClick={() => navigate('/home')}
                  variant="secondary"
                >
                  {Translator('Cancelar')}
                </Button>
              </div>
              <div className="buttons-right">
                <Button
                  size="medium"
                  className="button-right"
                  data-testid="btn_back"
                  variant="secondary"
                  color={theme.colors.secondary.main}
                  onClick={() => handleBar(formHook.watch('currentStep') - 1)}
                >
                  {Translator('Anterior')}
                </Button>
                <Button
                  variant="primary-custom"
                  color={theme.colors.secondary.main}
                  type="submit"
                  size="medium"
                  className="button-right"
                  data-testid="btn_next"
                >
                  {formHook.watch('currentStep') === 4
                    ? Translator('Concluir')
                    : Translator('Próximo')}
                </Button>
              </div>
            </FooterContent>
          </form>
        </FormProvider>
        <Footer />
      </ContainerForm>
    </>
  );
};

export default NewResearchForm;
