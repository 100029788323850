import React from 'react';

const IconOrganization = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width ?? '15px'}
      height={props.height ?? '14px'}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.5 3.11111V0H0V14H15V3.11111H7.5ZM3 12.4444H1.5V10.8889H3V12.4444ZM3 9.33333H1.5V7.77778H3V9.33333ZM3 6.22222H1.5V4.66667H3V6.22222ZM3 3.11111H1.5V1.55556H3V3.11111ZM6 12.4444H4.5V10.8889H6V12.4444ZM6 9.33333H4.5V7.77778H6V9.33333ZM6 6.22222H4.5V4.66667H6V6.22222ZM6 3.11111H4.5V1.55556H6V3.11111ZM13.5 12.4444H7.5V10.8889H9V9.33333H7.5V7.77778H9V6.22222H7.5V4.66667H13.5V12.4444ZM12 6.22222H10.5V7.77778H12V6.22222ZM12 9.33333H10.5V10.8889H12V9.33333Z"
        fill="#7A7A7A"
      />
    </svg>
  );
};

export default IconOrganization;
