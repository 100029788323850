import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { ListOrgsAtendimento as UsecaseRemoteListOrgsAtendimento } from 'domain/usecases/org/remote';

import { RemoteListOrgsAtendimento } from 'data/repository/org';

/**
 * send request via API.
 */
export const makeRemoteListOrgsAtendimento =
  (): UsecaseRemoteListOrgsAtendimento =>
    new RemoteListOrgsAtendimento(
      makeApiUrl('/orgs/atendimento/'),
      makeHttpClient(),
    );
