import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Translator from 'presentation/components/i18n/Translator';

export const schema = yup.object().shape({
  name: yup.string().when('type', {
    is: (type: string) => type === 'IMAGE' || type === 'FORM',
    then: yup
      .string()
      .max(1024, Translator('O nome excedeu o limite de 1024 caracteres'))
      .required(Translator('O nome é obrigatório')),
    otherwise: yup.string().max(1024).optional(),
  }),
  descr: yup
    .string()
    .max(1024)
    .when('type', {
      is: (type: string) => type === 'VIDEO',
      then: yup.string().required(Translator('A descrição é obrigatória')),
      otherwise: yup.string().max(1024).optional().nullable(),
    }),
  url: yup
    .string()
    .max(1024, Translator('A URL não pode ter mais de 1024 caracteres'))
    .url(Translator('A URL deve ser válida'))
    .when('type', {
      is: (type: string) => type === 'VIDEO',
      then: yup
        .string()
        .url(Translator('A URL deve ser válida'))
        .max(1024, Translator('A URL excedeu o limite de 1024 caracteres'))
        .required(Translator('A URL é obrigatória')),
      otherwise: yup.string().url().max(1024).optional(),
    }),
  file: yup.mixed().when('type', {
    is: (type: string) => type === 'IMAGE' || type === 'FORM',
    then: yup
      .mixed()
      .required(Translator('O arquivo é obrigatório'))
      .test('file', Translator('É necessário adicionar um arquivo'), value => {
        return value && value !== null;
      }),
    otherwise: yup.mixed().optional(),
  }),
  type: yup.mixed().oneOf(['VIDEO', 'IMAGE', 'FORM', 'AUDIO', 'TEXT']),
  link: yup
    .string()
    .max(1024, Translator('A URL não pode ter mais de 1024 caracteres'))
    .url(Translator('A URL deve ser válida'))
    .optional(),

  // orgs: yup
  //   .string()
  //   .optional()
  //   .transform(data => (data ? data.split(',') : undefined)),
});

export const schemaCreateMedias = yupResolver(schema);
