import { takeLatest } from 'redux-saga/effects';
import { GroupsTypes } from 'domain/interfaces/redux/groups/types';
import { onGetAll, onGetAllSuccess, onGetAllFailed } from './GetAll';

const tasks = [
  takeLatest(GroupsTypes.GETALL, onGetAll),
  takeLatest(GroupsTypes.GETALL_SUCCESS, onGetAllSuccess),
  takeLatest(GroupsTypes.GETALL_FAILED, onGetAllFailed),
];

export default tasks;
