import styled, { css } from 'styled-components';

interface ContainerProps {
  color: string;
  size: string;
  variant: string;
  height: string;
  nowrap: boolean;
  rounded: boolean;
  active: boolean;
  focus?: boolean;
  hoverColor?: string;
  disableIconHover?: boolean;
}

interface IconProps {
  fill?: string;
  variant: string;
}

export const Container = styled.button<ContainerProps>`
  background: ${props => props.color};
  border: 2px solid ${props => props.theme.colors.primary.main};
  border-radius: ${props => (props.rounded ? '24px' : '5px')};
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 600;
  font-size: ${props => (props.rounded ? '18px' : '14px')};

  cursor: pointer;
  transition: opacity 0.2s;

  padding: 2px 15px;

  height: ${props => props.height};
  width: ${props => {
    switch (props.size) {
      case 'mini':
        return '70px';
      case 'small':
        return '130px';
      case 'medium':
        return '200px';
      case 'large':
        return '240px';
      case 'bigger':
        return '300px';
      default:
        return '100%';
    }
  }};

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5 !important;
    color: gray;
    background-color: ${({ theme }) => theme.colors.status.disable};
    border-color: ${({ theme }) => theme.colors.status.disable};

    :hover {
      background-color: ${({ theme }) => theme.colors.status.disable};
      color: gray;
    }
  }

  &:hover {
    background: ${({ theme, hoverColor }) =>
      hoverColor ?? theme.colors.primary.light};
    color: ${({ theme }) => theme.colors.typography.lightGray};
    transition: all 0.3s;
    svg {
      path {
        fill: ${({ disableIconHover }) => !disableIconHover && '#fff'};
        stroke: ${({ disableIconHover }) => !disableIconHover && '#fff'};
      }
    }
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.primary.light};
    transition: 0.2s;
  }

  ${props =>
    props.nowrap === true &&
    css`
      overflow: hidden;
      white-space: nowrap;
      text-overflow: inherit;
    `}

  ${props =>
    props.variant === 'primary' &&
    css`
      border: none;
    `}

  ${props =>
    props.variant === 'primary-custom' &&
    css`
      border: none;

      &:hover {
        color: #fff;
        background-color: ${props.color};
        opacity: 0.9;
      }
    `}

  ${props =>
    props.variant === 'secondary' &&
    props.active === false &&
    css`
      border: 2px solid ${props.color};
      background-color: transparent;
      color: ${props.color};

      &:focus {
        /* border: none;
        border-style: double; */
      }

      &:disabled {
        background-color: transparent;

        &:hover {
          color: #fff;
          background-color: ${props.color};
        }
      }
      &:hover {
        color: #fff;
        background-color: ${props.color};
      }
    `}

  ${props =>
    props.variant === 'secondary' &&
    props.active === true &&
    css`
      color: #fff;
      background-color: ${props.color};

      &:focus {
        border: 2p solid ${props.color};

        :hover {
          color: #fff;
          background-color: ${props.color};
        }
      }

      &:hover {
        color: #fff;
        background-color: ${props.color};
      }
    `}

  ${props =>
    props.variant === 'disabled' &&
    css`
      color: gray;
      background-color: ${props.theme.colors.status.disable};
      border-color: ${props.theme.colors.status.disable};
      border: 2px solid ${props.theme.colors.status.disable};
      color: ${props.theme.colors.status.disable};
      cursor: not-allowed;
    `}
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  color: '#FFF';
`;
