import { UpdateUser as UsecaseRemoteUpdateUser } from 'domain/usecases/user/remote';
import { makeApiUrl, makeHttpClient } from 'main/factories/http';
// import { GetByIdUser as UsecaseReduxGetByIdUser } from 'domain/usecases/user/redux';

import { RemoteUpdateUser } from 'data/repository/user';
// import { ReduxGetByIdUser } from 'data/store/reducer/user/usecases';

/**
 * send request via API.
 */
export const makeRemoteUpdateUser = (): UsecaseRemoteUpdateUser =>
  new RemoteUpdateUser(makeApiUrl('/users/'), makeHttpClient());

/**
 * send request via REDUX.
 */
/* export const makeReduxGetByIdUser =
  (): UsecaseReduxGetByIdUser =>
    new ReduxGetByIdUser(); */
