/* eslint-disable react/require-default-props */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconBackButton } from 'presentation/base/icons';
import {
  Container,
  ContentHeader,
  ContentBackDescription,
  Back,
  ContentHeaderBackDescription,
} from './styles/StyledHeaderSimples';

interface propsHeaderSimples {
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  title: string;
  // eslint-disable-next-line react/no-unused-prop-types
  backDescription?: string;
}

const HeaderSimples: React.FC<propsHeaderSimples> = ({
  title,
  Icon,
  backDescription,
}): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Container>
      {backDescription ? (
        <ContentBackDescription>
          <Back data-testid="header_back" onClick={() => navigate('/home')}>
            <IconBackButton fill="#7A7A7A" />
            {backDescription}
          </Back>
          <ContentHeaderBackDescription data-testid="header_back_description">
            {Icon && <Icon />}
            {title}
          </ContentHeaderBackDescription>
          <div />
        </ContentBackDescription>
      ) : (
        <ContentHeader data-testid="header_title">
          {Icon && <Icon />}
          {title}
        </ContentHeader>
      )}
    </Container>
  );
};

export default HeaderSimples;
