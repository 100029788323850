import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { CreateResearch as UsecaseRemoteCreateResearch } from 'domain/usecases/research/remote';
// import { CreateResearch as UsecaseReduxCreateResearch } from 'domain/usecases/research/redux';

import { RemoteCreateResearch } from 'data/repository/research';
// import { ReduxCreateResearch } from 'data/store/reducer/research/usecases';

/**
 * send request via API.
 */
export const makeRemoteCreateResearch = (): UsecaseRemoteCreateResearch =>
  new RemoteCreateResearch(makeApiUrl('/researches/'), makeHttpClient());

/**
 * send request via REDUX.
 */
/* export const makeReduxCreateResearch =
  (): UsecaseReduxCreateResearch =>
    new ReduxCreateResearch(); */
