import { iStore } from 'domain/interfaces/models';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { GetByIdUser } from 'domain/usecases/user/remote';
import { makeReduxLogin } from 'main/factories/usecases/auth/LoginFactory';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/UpdateFactory';
import { makeRemoteGetByIdUser } from 'main/factories/usecases/user/GetByIdUserFactory';
import { makeRemoteUpdateUser } from 'main/factories/usecases/user/UpdateUser';
import { translator } from 'presentation/components/i18n';
import { Button, Input } from 'presentation/components/UI';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';
import { Container, Form, Title } from './styles';

const GeneralData: React.FC = () => {
  const { id } = useParams();
  const { user } = useSelector((store: iStore) => store.auth);
  const theme = useTheme();

  const formHook = useForm({
    shouldFocusError: true,
  });

  const {
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isDirty, touchedFields },
    control,
    reset,
  } = formHook;

  async function handleUpdate() {
    try {
      await makeRemoteUpdateUser().update({
        userId: Number(id),
        user: getValues(),
      });

      makeReduxLogin().update({
        fullname: getValues().fullname,
        email: getValues().email,
      });
      toast.success(translator('Usuário atualizado com sucesso!'));
    } catch (err) {
      toast.error(translator('Erro ao atualizar usuário!'));
    } finally {
      reset(getValues());
    }
  }

  useEffect(() => {
    setValue('fullname', user?.fullname ?? '');
    setValue('email', user?.email ?? '');
  }, [user, setValue]);

  return (
    <Container>
      <Title>{translator('Dados pessoais')}</Title>
      <Form onSubmit={handleSubmit(handleUpdate)}>
        <Controller
          name="fullname"
          control={control}
          render={({ field }) => (
            <Input
              data-testid="input_fullname"
              label={translator('Nome completo')}
              error={!!errors?.fullname}
              width="360px"
              defaultValue={getValues()?.fullname}
              {...field}
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <Input
              data-testid="input_email"
              label={translator('E-mail')}
              error={!!errors?.email}
              width="360px"
              defaultValue={getValues()?.email}
              {...field}
            />
          )}
        />
        <Button
          data-testid="btn_update"
          variant="primary-custom"
          size="small"
          type="submit"
          color={theme.colors.secondary.main}
          disabled={!isDirty || !touchedFields}
        >
          {translator('Salvar')}
        </Button>
      </Form>
    </Container>
  );
};

export default GeneralData;
