import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { GetByIdGroup as UsecaseRemoteGetByIdGroup } from 'domain/usecases/group/remote';
// import { GetByIdGroup as UsecaseReduxGetByIdGroup } from 'domain/usecases/group/redux';

import { RemoteGetByIdGroup } from 'data/repository/group';
// import { ReduxGetByIdGroup } from 'data/store/reducer/group/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetByIdGroup = (): UsecaseRemoteGetByIdGroup =>
  new RemoteGetByIdGroup(makeApiUrl('/groups/{group}'), makeHttpClient());

/**
 * send request via REDUX.
 */
/* export const makeReduxGetByIdGroup =
  (): UsecaseReduxGetByIdGroup =>
    new ReduxGetByIdGroup(); */
