import styled from 'styled-components';

export const ContainerAvatars = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 50rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 5px;
  .SYSADMIN {
    display: flex;
    flex-direction: row;
  }
  .ORGANIZER {
    display: flex;
    flex-direction: row;
  }
`;

export const ContainerImage = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
`;

export const Name = styled.span`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.primary.main};
  font-weight: 500;
  text-transform: capitalize;
`;

export const Roles = styled.span`
  font-size: 1.4rem;
  width: 100%;
  display: flex;
  color: #656a6e;
  justify-content: flex-end;
`;

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
