import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { DeleteMessage as UsecaseRemoteDeleteMessage } from 'domain/usecases/message/remote';
// import { DeleteGroup as UsecaseReduxDeleteGroup } from 'domain/usecases/group/redux';

import { RemoteDeleteMessage } from 'data/repository/message';
// import { ReduxDeleteGroup } from 'data/store/reducer/group/usecases';

/**
 * send request via API.
 */
export const makeRemoteDeleteMessage = (): UsecaseRemoteDeleteMessage =>
  new RemoteDeleteMessage(
    makeApiUrl('/messages/{messageId}'),
    makeHttpClient(),
  );

/**
 * send request via REDUX.
 */
/* export const makeReduxDeleteGroup =
  (): UsecaseReduxDeleteGroup =>
    new ReduxDeleteGroup(); */
