/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/jsx-one-expression-per-line */
import { iMessage } from 'domain/interfaces/models';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { makeRemotePostUserGroup } from 'main/factories/usecases/group/PostGroup';
import { Button } from 'presentation/components/UI';
import React, { useMemo, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';
import { translator } from '../i18n';
import Translator from '../i18n/Translator';
import ParticipantsList, {
  Params,
  RefProps,
} from '../Lists/ListParticipants/ListParticipants';
import SearchListBroadcastGroups from '../search-list/SearchListBroadcastGroups';
import {
  Container,
  ContainerModal,
  Footer,
  Header,
  SearchDiv,
} from './styles/StyledModalAddingParticipants';

interface ModalAddingParticipantsProps {
  message: iMessage;
}

const ModalAddingParticipants: React.FC<ModalAddingParticipantsProps> = ({
  message,
}) => {
  const [participantLength, setParticipantLength] = useState(0);

  const theme = useTheme();

  const ref = useRef<RefProps>(null);

  const msgName = MessageOptions.addingParticipantsModal;

  const { active, actionOk, actionCancel } = message;

  const params = useParams<Params>();

  const { search } = useLocation();

  const queryParams = useMemo(
    () => ({
      orgId: new URLSearchParams(search).get('orgId') ?? null,
    }),
    [search],
  );

  const submit = () => {
    if (
      ref.current?.selectedParticipants &&
      queryParams?.orgId &&
      params?.groupId
    ) {
      const usersFormmated = [...ref.current?.selectedParticipants];

      const usersGroup = usersFormmated.map(user => {
        return {
          extId: user.login,
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
          phone: user.phone,
          city: user.city,
        };
      });

      makeRemotePostUserGroup()
        .post({
          groupId: Number(params?.groupId),
          body: {
            usersGroup,
          },
        })
        .then(() => {
          toast.success(translator('Participantes adicionados com sucesso!'));
          actionOk?.();
        })
        .catch(() => {
          toast.error(translator('Erro ao adicionar participantes!'));
        });
    }
  };

  return (
    <>
      {msgName === active && (
        <ContainerModal>
          <Container>
            <Header>
              <div />
              <span>{translator('Selecione os pacientes do grupo')}</span>
              <div />
            </Header>
            <SearchDiv>
              <SearchListBroadcastGroups
                placeholder={Translator('Buscar paciente...')}
              >
                <h4>{translator('Em desenvolvimento')}</h4>
              </SearchListBroadcastGroups>
              <span>
                {participantLength} {translator('selecionados')}
              </span>
            </SearchDiv>
            <ParticipantsList
              ref={ref}
              setParticipantLength={setParticipantLength}
            />
            <Footer>
              <Button
                data-testid="btn_cancel"
                variant="secondary"
                size="medium"
                onClick={() => {
                  setParticipantLength(0);
                  actionCancel?.();
                }}
              >
                {translator('Cancelar')}
              </Button>
              <Button
                data-testid="btn_ok"
                color={theme.colors.secondary.main}
                variant="primary-custom"
                type="submit"
                size="medium"
                onClick={submit}
                // disabled={Boolean(!isValid)}
              >
                {translator('Salvar')}
              </Button>
            </Footer>
          </Container>
        </ContainerModal>
      )}
    </>
  );
};

export default ModalAddingParticipants;
