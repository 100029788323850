import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { PostUserGroup as UsecaseRemotePostUserGroup } from 'domain/usecases/group/remote';
// import { UpdateGroup as UsecaseReduxUpdateGroup } from 'domain/usecases/group/redux';

import { RemotePostUserGroup } from 'data/repository/group';
// import { ReduxUpdateGroup } from 'data/store/reducer/group/usecases';

/**
 * send request via API.
 */
export const makeRemotePostUserGroup = (): UsecaseRemotePostUserGroup =>
  new RemotePostUserGroup(
    makeApiUrl('/groups/{group}/users'),
    makeHttpClient(),
  );

/**
 * send request via REDUX.
 */
/* export const makeReduxUpdateGroup =
  (): UsecaseReduxUpdateGroup =>
    new ReduxUpdateGroup(); */
