import React from 'react';
import { IconEmptyMedia } from 'presentation/base/icons';
import { Container, Message, Image } from './styles/StyledEmptyMedia';

interface iMediaProps {
  type: 'image' | 'video' | 'pdf';
}

const EmptyMedia: React.FC<iMediaProps> = ({ type }) => {
  const getMediaMessage = (): string => {
    switch (type) {
      case 'image':
        return 'Nova Imagem';

      case 'video':
        return 'Novo Vídeo';

      case 'pdf':
        return 'Novo Arquivo';

      default:
        return 'Novo Arquivo';
    }
  };

  return (
    <Container>
      <Message>
        {`Você ainda não fez o upload de nenhum arquivo. Clique no botão “${getMediaMessage()}” para adicionar.`}
      </Message>
      <Image>
        <IconEmptyMedia />
      </Image>
    </Container>
  );
};

export default EmptyMedia;
