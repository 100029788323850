/* eslint-disable react/no-unused-prop-types */
import React from 'react';

import { Container, Content, Point } from './style/StyledStep';

interface ownProps {
  title: string;
}

type Props = ownProps;

const Step: React.FC<Props> = (props): JSX.Element => {
  return <Container />;
};

export default Step;
