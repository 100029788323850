/* eslint-disable react/require-default-props */
/* eslint-disable react/button-has-type */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react';
import {
  IconCheckPrimary,
  IconClosePrimary,
  IconEyeView,
  IconMediaResearch,
  IconPlusPrimary,
  IconPlusSecondary,
  IconTrash2,
} from 'presentation/base/icons';

import { makeStyles, Tooltip } from '@material-ui/core';
import {
  Actions,
  Delete,
  EditIcon,
  DownloadImage,
  ReplaceImage,
  DeleteImage,
  Media,
  TooltipTitle,
} from './styles/StyledActionsList';
import { Button } from '../UI';
import { translator } from '../i18n';

interface ownProps {
  actions?: {
    editGroup?: () => any;
    deleteGroup?: () => any;
    deletePatient?: () => any;
    downloadImage?: () => any;
    replaceImage?: () => any;
    deleteImage?: () => any;
    editMedia?: () => any;
    listPatients?: () => any;
    addOrganization?: () => any;
    removeOrganization?: () => any;
    viewMessage?: () => any;
    deleteMessage?: () => any;
  };
}

const useStyles = makeStyles(theme => ({
  customTooltip: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    pointerEvents: 'auto',
  },
  customTooltipNoPadding: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    pointerEvents: 'auto',
    padding: 0,
  },
  customArrow: {
    color: 'rgba(0, 0, 0, 0.8)',
  },
}));

const ActionsList: React.FC<ownProps> = ({ actions }) => {
  const classes = useStyles();

  const [hover, setHover] = useState({
    addOrganization: false,
    removeOrganization: false,
  });

  return (
    <Actions>
      {actions?.editGroup && (
        <Tooltip
          title={<TooltipTitle>{translator('Editar grupo')}</TooltipTitle>}
          classes={{
            tooltip: classes.customTooltip,
            arrow: classes.customArrow,
          }}
          placement="top"
          arrow
          onClick={actions.editGroup}
        >
          <div>
            <EditIcon id="edit_sector" data-testid="edit_sector" />
          </div>
        </Tooltip>
      )}

      {actions?.deleteGroup && (
        <Tooltip
          title={<TooltipTitle>{translator('Excluir grupo')}</TooltipTitle>}
          classes={{
            tooltip: classes.customTooltip,
            arrow: classes.customArrow,
          }}
          placement="top"
          arrow
          onClick={actions?.deleteGroup}
        >
          <div>
            <Delete id="profile_delete" data-testid="delete_group" />
          </div>
        </Tooltip>
      )}
      {actions?.deletePatient && (
        <Tooltip
          title={<TooltipTitle>{translator('Excluir paciente')}</TooltipTitle>}
          classes={{
            tooltip: classes.customTooltip,
            arrow: classes.customArrow,
          }}
          placement="top"
          arrow
          onClick={actions?.deletePatient}
        >
          <div>
            <Delete id="profile_delete" data-testid="delete_patient" />
          </div>
        </Tooltip>
      )}
      {actions?.downloadImage && (
        <Tooltip
          title={<TooltipTitle>{translator('Baixar imagem')}</TooltipTitle>}
          classes={{
            tooltip: classes.customTooltip,
            arrow: classes.customArrow,
          }}
          placement="top"
          arrow
          onClick={actions?.downloadImage}
        >
          <div>
            <DownloadImage id="profile_delete" data-testid="download_image" />
          </div>
        </Tooltip>
      )}
      {actions?.replaceImage && (
        <Tooltip
          title={<TooltipTitle>{translator('Substituir imagem')}</TooltipTitle>}
          classes={{
            tooltip: classes.customTooltip,
            arrow: classes.customArrow,
          }}
          placement="top"
          arrow
          onClick={actions?.replaceImage}
        >
          <div>
            <ReplaceImage id="profile_delete" data-testid="replace_image" />
          </div>
        </Tooltip>
      )}
      {actions?.deleteImage && (
        <Tooltip
          title={<TooltipTitle>{translator('Excluir imagem')}</TooltipTitle>}
          classes={{
            tooltip: classes.customTooltip,
            arrow: classes.customArrow,
          }}
          placement="top"
          arrow
          onClick={actions?.deleteImage}
        >
          <div>
            <DeleteImage id="profile_delete" data-testid="delete_image" />
          </div>
        </Tooltip>
      )}
      {actions?.editMedia && (
        <Tooltip
          title={<TooltipTitle>{translator('Mídia')}</TooltipTitle>}
          classes={{
            tooltip: classes.customTooltip,
            arrow: classes.customArrow,
          }}
          placement="top"
          arrow
          onClick={actions?.editMedia}
        >
          <Media data-testid="media">
            <IconMediaResearch />
            {translator('Mídias')}
          </Media>
        </Tooltip>
      )}
      {actions?.listPatients && (
        <Tooltip
          title={<TooltipTitle>{translator('Pacientes')}</TooltipTitle>}
          classes={{
            tooltip: classes.customTooltip,
            arrow: classes.customArrow,
          }}
          placement="top"
          arrow
          onClick={actions?.listPatients}
        >
          <Media data-testid="patients">
            <IconMediaResearch />
            {translator('Pacientes')}
          </Media>
        </Tooltip>
      )}

      {actions?.addOrganization && (
        <Button
          icon={hover.addOrganization ? IconPlusSecondary : IconPlusPrimary}
          size="small"
          variant="secondary"
          color="#0B77CD"
          onMouseOver={() => {
            setHover({ ...hover, addOrganization: true });
          }}
          onMouseOut={() => {
            setHover({ ...hover, addOrganization: false });
          }}
          onClick={actions?.addOrganization}
          disableIconHover
        >
          {translator('Adicionar')}
        </Button>
      )}

      {actions?.removeOrganization && (
        <Button
          icon={hover.removeOrganization ? IconClosePrimary : IconCheckPrimary}
          size="small"
          color="#5CB65C"
          hoverColor="#EC5C52"
          onMouseOver={() => {
            setHover({ ...hover, removeOrganization: true });
          }}
          onMouseOut={() => {
            setHover({ ...hover, removeOrganization: false });
          }}
          onClick={actions?.removeOrganization}
          disableIconHover
        >
          {hover.removeOrganization
            ? translator('Remover')
            : translator('Adicionado')}
        </Button>
      )}

      {actions?.viewMessage && (
        <Button
          data-testid="view_message"
          icon={IconEyeView}
          size="small"
          color="#0B77CD"
          variant="secondary"
          onClick={actions?.viewMessage}
        >
          {translator('Visualizar')}
        </Button>
      )}

      {actions?.deleteMessage && (
        <Button
          data-testid="delete_message"
          icon={IconTrash2}
          size="small"
          variant="secondary"
          color="#EC5C52"
          onClick={actions?.deleteMessage}
        >
          {translator('Excluir')}
        </Button>
      )}
    </Actions>
  );
};
export default ActionsList;
