/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { IconPerson } from 'presentation/base/icons';
import { translator } from 'presentation/components/i18n';
import { Button, Input } from 'presentation/components/UI';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTheme } from 'styled-components';
import { v4 as uuid } from 'uuid';
import ListItem from '../listItem';
import {
  Column,
  Container,
  ContainerList,
  ListContainer,
  Row,
  Subtitle,
  Title,
  VerticalDivider,
} from '../styles';

type Group = {
  name: string;
  id: string;
};

export type GroupForm = {
  groups: Group[];
};

export const Step3: React.FC = (): JSX.Element => {
  const theme = useTheme();

  const { setValue, getValues, watch } = useFormContext<GroupForm>();

  const [group, setGroup] = useState<Group>({} as Group);
  const [editing, setEditing] = useState<{
    editing: boolean;
    idToEdit: number;
  }>({ editing: false, idToEdit: -1 });

  const isEmpty = watch('groups')?.length === 0;

  const handleAddGroup = () => {
    const previousArr = [...getValues('groups')];

    if (group.name) {
      const obj = {
        name: group.name,
        id: uuid(),
      };
      previousArr.push(obj);
      setValue('groups', previousArr);
    }

    setGroup({ name: '', id: '' } as Group);
  };

  const handleRemoveGroup = (id: string) => {
    const previousArr = [...getValues('groups')];

    const findIndex = previousArr.findIndex(item => item.id === id);

    if (findIndex >= 0) {
      previousArr.splice(findIndex, 1);
      setValue('groups', previousArr);
    }
  };

  const handleFindGroupToEdit = (id: string) => {
    const previousArr = [...getValues('groups')];

    const findIndex = previousArr.findIndex(item => item.id === id);

    if (findIndex >= 0) {
      setGroup(previousArr[findIndex]); // TODO: Salvar a posição do array
    }
    setEditing({ editing: true, idToEdit: findIndex });
  };

  const handleEditGroup = () => {
    const previousArr = [...getValues('groups')];

    if (editing.idToEdit >= 0) {
      previousArr[editing.idToEdit] = group;
      setValue('groups', previousArr);
    }

    setGroup({ name: '', id: '' } as Group);

    setEditing({ editing: false, idToEdit: -1 });
  };

  return (
    <Container>
      <Column>
        <Title>{translator('Cadastro de grupos')}</Title>
        <Subtitle>
          {translator(
            'Digite o nome do grupo que você deseja incluir na pesquisa e depois clique em “Cadastrar grupo”.',
          )}
        </Subtitle>
        <div>
          <Row size="large">
            <Input
              data-testid="group-name"
              value={group.name}
              onChange={e =>
                setGroup(prevState => ({ ...prevState, name: e.target.value }))
              }
              label={translator('Nome do grupo')}
              placeholder={translator('Digite o nome do grupo')}
              name="group_name"
            />
          </Row>
          <Row size="large">
            <Button
              variant="primary-custom"
              color={theme.colors.secondary.main}
              data-testid="btn_add_group"
              onClick={editing.editing ? handleEditGroup : handleAddGroup}
              icon={IconPerson}
            >
              {editing.editing
                ? translator('Atualizar grupo')
                : translator('Cadastrar grupo')}
            </Button>
          </Row>
        </div>
      </Column>
      <VerticalDivider />
      <Column>
        <Title variant>{translator('Grupos cadastrados:')}</Title>
        <ContainerList isEmpty={isEmpty}>
          {isEmpty ? (
            translator('Você ainda não possui grupos cadastrados.')
          ) : (
            <ListContainer>
              {watch('groups')?.map((item, index) => {
                if (index === editing.idToEdit) return;

                return (
                  <ListItem
                    key={item.id}
                    item={{ id: item.id, name: item.name }}
                    handleDelete={handleRemoveGroup}
                    handleEdit={handleFindGroupToEdit}
                  />
                );
              })}
            </ListContainer>
          )}
        </ContainerList>
      </Column>
    </Container>
  );
};

export default Step3;
