import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 5.1rem;
  align-items: center;
  justify-content: center;
`;

export const Message = styled.p`
  font-family: 'Roboto' sans-serif;
  color: #93989b;
  font-size: 1.6rem;
`;

export const Image = styled.div`
  margin-top: 5.1rem;
`;
