import { AnyAction, combineReducers } from 'redux';
import { persistReducer, createTransform } from 'redux-persist';
import omit from 'lodash/omit';
import storage from 'redux-persist/lib/storage';
import { toast } from 'react-toastify';
import message from './message';
import groups from './groups';
import media from './media';
import auth from './auth';
import research from './research';
import org from './org';

const reducer = combineReducers({
  auth,
  message,
  groups,
  media,
  research,
  org,
});

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === '@auth/LOGOUT') {
    storage.removeItem('persist:@laoha');

    if (
      window.location.pathname !== '/' &&
      !window.location.href.includes('/password')
    ) {
      toast.error('Login expirado, faça login novamente');
      window.location.pathname = '/';
    }

    return reducer(undefined, action);
  }

  return reducer(state, action);
};

// Blacklist specific property to not persist on LocalStorage
const blacklistProperty = (blacklistPaths: Array<string>) => {
  return createTransform((inboundState: any, key) => {
    const blacklistPathsForKey = blacklistPaths
      .filter(path => path.startsWith(`${String(key)}.`))
      .map(path => path.substr(String(key).length + 1));

    return omit(inboundState, ...blacklistPathsForKey);
  }, null);
};

const authPersistConfig = {
  key: '@laoha',
  storage,
  blacklist: ['message'],
  transforms: [blacklistProperty(['auth.loading'])],
};

export default persistReducer(authPersistConfig, rootReducer);
