/* eslint-disable react/require-default-props */
import React from 'react';
import { IconFilter, IconSearch } from 'presentation/base/icons';
import Dropdown from 'presentation/components/drop-down/Dropdown';
import {
  Container,
  ContentSearch,
} from './styles/StyledSearchListBroadcastGroups';
import Translator from '../i18n/Translator';

interface SearchProps {
  placeholder?: string;
  onChange?: (value: string) => void;
  children: JSX.Element[] | JSX.Element;
}

const SearchListBroadcastGroups: React.FC<SearchProps> = ({
  placeholder,
  children,
  onChange,
}) => {
  return (
    <Container>
      <ContentSearch>
        <IconSearch id="btn_searchFilter" />
        <input
          id="input_search"
          placeholder={placeholder || Translator('Buscar pesquisa...')}
          onChange={e => onChange && onChange(e.target.value)}
        />
      </ContentSearch>
      {/* TODO: Temporarily disabled while no filters available */}
      {/* <Dropdown label="Filtros" icon={<IconFilter />}>
        {children}
      </Dropdown> */}
    </Container>
  );
};
export default SearchListBroadcastGroups;
