import { iStore } from 'domain/interfaces/models';
import { GetByIdGroup } from 'domain/usecases/group/remote';
import { makeRemoteGetByIdGroup } from 'main/factories/usecases/group/GetByIdGroupFactory';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/UpdateFactory';
import { IconMessage } from 'presentation/base/icons';
import { Header, HeaderSimples } from 'presentation/components/Header';
import HeaderBox from 'presentation/components/HeaderBox';
import ListGroups from 'presentation/components/Lists/ListGroups/ListGroups';
import SearchListBroadcastGroups from 'presentation/components/search-list/SearchListBroadcastGroups';
import { Button } from 'presentation/components/UI';
import React, { useEffect, useState } from 'react';
import { IoPersonAddSharp } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';
import { closeModal } from 'utils';
import { useDidUpdateEffect } from 'presentation/hooks/useDidUpdateEffect';
import { translator } from 'presentation/components/i18n';
import Translator from 'presentation/components/i18n/Translator';
import {
  BoxSpace,
  ContainerListMessage,
  ContentHeaderSimples,
  ListMessageDiv,
} from '../ListMessagePage/styles/StyledListMessagePage';

import { SearchContent } from '../ListsHomePage/styles/StyledListsHomePage';

type Params = {
  groupId: string;
};

const PatientSelectionPage: React.FC = (): JSX.Element => {
  const { active } = useSelector((store: iStore) => store.message);
  const [loading, setLoading] = useState(true);

  const [groups, setGroups] = useState<GetByIdGroup.Model>(
    {} as GetByIdGroup.Model,
  );
  const [refetch, setRefetch] = useState(false);

  const isFirstRender = useDidUpdateEffect();

  const theme = useTheme();
  const params = useParams<Params>();

  useEffect(() => {
    let isMounted = true;

    async function handleGetGroupById() {
      if (!params.groupId) return;

      try {
        if (isFirstRender) return;

        const response = await makeRemoteGetByIdGroup().getById({
          groupId: Number(params.groupId),
        });

        if (isMounted) {
          setGroups(response);
        }
      } catch (err) {
        console.error(err);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    }

    handleGetGroupById();

    return () => {
      isMounted = false;
    };
  }, [params.groupId, isFirstRender, refetch, active]);

  const handleOpenModal = () => {
    makeReduxActiveMessage().active({
      active: 'addingParticipantsModal',
      actionOk: () => closeModal(),
      actionCancel: () => closeModal(),
    });
  };

  return loading ? (
    <div>loading...</div>
  ) : (
    <>
      <div style={{ width: '100%' }}>
        <Header />

        <ContentHeaderSimples>
          <HeaderSimples
            title={translator('Seleção de Pacientes')}
            Icon={IconMessage}
            backDescription={translator('Voltar para a lista')}
          />
        </ContentHeaderSimples>
        <BoxSpace>
          <HeaderBox title={groups?.name} />
        </BoxSpace>
      </div>
      <ContainerListMessage>
        <SearchContent>
          <SearchListBroadcastGroups
            placeholder={Translator('Buscar paciente...')}
          >
            <h4>{translator('Em desenvolvimento')}</h4>
          </SearchListBroadcastGroups>
          <Button
            data-testid="add_participants"
            variant="primary-custom"
            color={theme.colors.secondary.main}
            size="medium"
            icon={IoPersonAddSharp}
            iconHeight={16}
            iconWidth={21}
            onClick={() => handleOpenModal()}
          >
            {translator('Inserir pacientes')}
          </Button>
        </SearchContent>
        <ListMessageDiv>
          <ListGroups
            dataList={groups?.userGroups ?? []}
            handleRefetch={() => setRefetch(prevState => !prevState)}
          />
        </ListMessageDiv>
      </ContainerListMessage>
    </>
  );
};

export default PatientSelectionPage;
