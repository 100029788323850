import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { GetByIdMedia as UsecaseRemoteGetByIdMedia } from 'domain/usecases/media/remote';
// import { GetByIdMedia as UsecaseReduxGetByIdMedia } from 'domain/usecases/media/redux';

import { RemoteGetByIdMedia } from 'data/repository/media';
// import { ReduxGetByIdMedia } from 'data/store/reducer/media/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetByIdMedia = (): UsecaseRemoteGetByIdMedia =>
  new RemoteGetByIdMedia(makeApiUrl('/medias/{media}'), makeHttpClient());

/**
 * send request via REDUX.
 */
/* export const makeReduxGetByIdMedia =
  (): UsecaseReduxGetByIdMedia =>
    new ReduxGetByIdMedia(); */
