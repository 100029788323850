import React from 'react';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory({ window });

const HistoryProvider: React.FC = ({ children }) => {
  return <HistoryRouter history={history}>{children}</HistoryRouter>;
};

export const navigate = (to: string): void => {
  history.push(to);
};

export default HistoryProvider;
