import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { GetByIdResearch } from 'domain/usecases/research/remote';
import { makeRemoteUpdateResearch } from 'main/factories/usecases/research/UpdateResearchFactory';
import { navigate } from 'main/routes/_CustomRouter';
import { IconOptionsResearch, IconResearch } from 'presentation/base/icons';
import { defaultTheme } from 'presentation/base/themes';
import { Header, HeaderSimples } from 'presentation/components/Header';
import { translator } from 'presentation/components/i18n';
import { Button, Footer } from 'presentation/components/UI';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';
import { schemaCreateNewSearch } from 'validation/validators/NewSearchValidator/NewSearchValidator';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import {
  ContainerForm,
  Content,
  FooterContent,
  TitleReseacrhBox,
} from './styles';

interface FormToUpdate {
  id: number;
  org: {
    id: number;
    name: string;
  };
  name: string;
  descr: string;
  organizers: { id?: number; fullname: string; email: string }[];
  groups: { id: number; name: string }[];
  currentStep: number;
}

interface EditSearchProps {
  research: GetByIdResearch.Model;
}

export const EditSearch: React.FC<EditSearchProps> = ({ research }) => {
  const theme = useTheme();

  const formHook = useForm<FormToUpdate>({
    shouldFocusError: true,
    resolver: schemaCreateNewSearch,
    defaultValues: {
      currentStep: 1,
      descr: research.descr,
      name: research.name,
      org: {
        name: research.shortOrg,
        id: research.orgId,
      },
      groups: research.groups,
      organizers: research.organizers,
    },
  });

  const updateSearch = () => {
    if (!formHook.getValues()?.organizers.length) {
      toast.error(
        translator('A pesquisa deve possuir pelo menos um coordenador'),
      );
      return;
    }

    if (!formHook.getValues()?.groups.length) {
      toast.error(translator('A pesquisa deve possuir pelo menos um grupo'));
      return;
    }

    const organizers = [...formHook.getValues()?.organizers];
    const groups = [...formHook.getValues()?.groups];

    const organizersFormatted = organizers.map(organizer => {
      if (typeof organizer.id === 'number') {
        return {
          fullname: organizer.fullname,
          email: organizer.email,
          id: organizer.id,
        };
      }
      return {
        fullname: organizer.fullname,
        email: organizer.email,
      };
    });

    const groupsFormatted = groups.map(organizer => {
      if (typeof organizer.id === 'number') {
        return {
          id: organizer.id,
          name: organizer.name,
        };
      }
      return {
        name: organizer.name,
      };
    });

    const objToSend = {
      orgId: formHook.getValues()?.org.id,
      name: formHook.getValues()?.name,
      descr: formHook.getValues()?.descr,
      organizers: organizersFormatted,
      groups: groupsFormatted,
    };

    makeRemoteUpdateResearch()
      .update({
        body: objToSend,
        researchId: research.id,
      })
      .then(() => toast.success(translator('Pesquisa atualizada com sucesso')))
      .catch(() => toast.error(translator('Erro ao atualizar pesquisa')));
  };

  return (
    <>
      <Header />
      <HeaderSimples
        title={translator('Configurações de pesquisa')}
        backDescription={translator('Pesquisas')}
        Icon={IconOptionsResearch}
      />
      <ContainerForm>
        <TitleReseacrhBox>
          <IconResearch className="icon" />
          {`(${research?.shortOrg?.toLocaleUpperCase()}) ${research?.name}`}
        </TitleReseacrhBox>
        <FormProvider {...formHook}>
          <Content>
            <Tabs w="full" p={4}>
              <TabList>
                <Tab
                  data-testid="tab-1"
                  _selected={{
                    color: defaultTheme.colors.primary.main,
                    borderBottom: `2px solid ${defaultTheme.colors.primary.main}`,
                  }}
                  _focus={{
                    boxShadow: 'none',
                  }}
                  fontSize="14"
                  p="8px 16px"
                  onClick={() => formHook.setValue('currentStep', 1)}
                >
                  {translator('Informações')}
                </Tab>
                <Tab
                  data-testid="tab-2"
                  _selected={{
                    color: defaultTheme.colors.primary.main,
                    borderBottom: `2px solid ${defaultTheme.colors.primary.main}`,
                  }}
                  _focus={{
                    boxShadow: 'none',
                  }}
                  fontSize="14"
                  p="8px 16px"
                  onClick={() => formHook.setValue('currentStep', 2)}
                >
                  {translator('Coordenadores')}
                </Tab>
                <Tab
                  data-testid="tab-3"
                  _selected={{
                    color: defaultTheme.colors.primary.main,
                    borderBottom: `2px solid ${defaultTheme.colors.primary.main}`,
                  }}
                  _focus={{
                    boxShadow: 'none',
                  }}
                  fontSize="14"
                  p="8px 16px"
                  onClick={() => formHook.setValue('currentStep', 3)}
                >
                  {translator('Grupos')}
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Step1 />
                </TabPanel>
                <TabPanel>
                  <Step2 />
                </TabPanel>
                <TabPanel>
                  <Step3 />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Content>
        </FormProvider>
        <FooterContent>
          <div className="buttons-left">
            <Button
              data-testid="btn_cancel"
              size="medium"
              variant="secondary"
              onClick={() => navigate('/home')}
            >
              {translator('Cancelar')}
            </Button>
          </div>
          <div className="buttons-right">
            <Button
              variant="primary-custom"
              color={theme.colors.secondary.main}
              size="medium"
              className="button-right"
              data-testid="btn_save"
              onClick={formHook.handleSubmit(updateSearch)}
            >
              {translator('Salvar')}
            </Button>
          </div>
        </FooterContent>
        <Footer />
      </ContainerForm>
    </>
  );
};

export default EditSearch;
