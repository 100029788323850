import { takeLatest } from 'redux-saga/effects';
import { ResearchTypes } from 'domain/interfaces/redux/research/types';
import { onList, onListSuccess, onListFailed } from './List';

const tasks = [
  takeLatest(ResearchTypes.LIST, onList),
  takeLatest(ResearchTypes.LIST_SUCCESS, onListSuccess),
  takeLatest(ResearchTypes.LIST_FAILED, onListFailed),
];

export default tasks;
