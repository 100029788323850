/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable array-callback-return */
// eslint-disable-next-line react/jsx-one-expression-per-line
/* eslint-disable react/require-default-props */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
  ListMessages,
  MessageRecords,
} from 'domain/usecases/message/remote/ListMessages';
import { navigate } from 'main/routes/_CustomRouter';
import { IconOptionsResearch, IconPerson } from 'presentation/base/icons';
import ActionsList from 'presentation/components/actions-list/ActionsList';
import Pagination from 'presentation/components/Pagination';
import { useAccess } from 'presentation/hooks/access';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { formattedDate } from 'utils/formattedDate';
import { iStore } from 'domain/interfaces/models';
import _, { map } from 'lodash';
import ModalViewMessage from 'presentation/components/modalViewMessage';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/UpdateFactory';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { makeRemoteDeleteMessage } from 'main/factories/usecases/message/DeleteMessage';
import { closeModal } from 'utils';
import Translator from 'presentation/components/i18n/Translator';
import { iListMessageResponse } from '../interface';
import {
  Container,
  ContainerAccordion,
  ContentLabelAccordion,
  ExpandedContainer,
  Groups,
  LabelAccordion,
} from './styles/StyledListMessage';

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  container: {
    maxHeight: '200px',
    width: '100%',
    border: 'none',
    overflowY: 'auto',
  },
  headerTable: {
    padding: '1.2rem',
    border: 'none',
  },
});

type iColumns = 'Grupos' | 'postChannel' | 'postDate' | 'actions';

interface ListMessageProps {
  messages: MessageRecords[];
}

interface ViewMessageProps {
  viewMessageName?: string;
  viewMessageGroupsNames?: string;
  viewMessageDate?: string;
  viewMessageContent?: string;
}

const ListMessage: React.FC<ListMessageProps> = ({ messages }): JSX.Element => {
  const [messagesResponse, setMessagesResponse] = useState<ListMessages.Model>(
    {} as ListMessages.Model,
  );
  const [page, setPage] = useState<number>(1);
  const itemsPerPage = 5;

  const { user } = useSelector((store: iStore) => store.auth);
  const { hasAccess } = useAccess();

  const classes = useStyles();
  const columns = [
    {
      id: 'Grupos',
      label: Translator('Grupos'),
      minWidth: 320,
      align: 'left',
      fontSize: '1.4rem',
    },
    {
      id: 'postChannel',
      label: Translator('Canal de envio'),
      minWidth: 140,
      align: 'left',
      fontSize: '1.4rem',
    },
    {
      id: 'postDate',
      label: Translator('Data de envio'),
      minWidth: 300,
      align: 'left',
      fontSize: '1.4rem',
    },
    {
      id: 'actions',
      label: Translator('Ações'),
      minWidth: 100,
      align: 'left',
      fontSize: '1.4rem',
    },
  ];

  const handleViewMessageModal = ({
    viewMessageContent,
    viewMessageDate,
    viewMessageGroupsNames,
    viewMessageName,
  }: ViewMessageProps) => {
    makeReduxActiveMessage().active({
      active: MessageOptions.viewMessageModal,
      isEditing: false,
      actionOk: () => closeModal(),
      actionCancel: () => closeModal(),
      viewMessageContent,
      viewMessageDate,
      viewMessageGroupsNames,
      viewMessageName,
    });
  };

  const handleDeleteMessageModal = (id: number) => {
    makeReduxActiveMessage().active({
      active: MessageOptions.cancelEditModal,
      titleModal: Translator('Excluir mensagem'),
      cancelMessage: Translator(
        'Tem certeza que deseja excluir o envio dessa mensagem?',
      ),
      primaryActionMessage: Translator('Excluir'),
      secondaryActionMessage: Translator('Cancelar'),
      actionOk: () => {
        makeRemoteDeleteMessage()
          .delete({
            messageId: id,
          })
          .then(() => {
            toast.success(Translator('Mensagem deletada com sucesso!'));
          })
          .catch(() => {
            toast.error(Translator('Falha ao deletar mensagem!'));
          });
        closeModal();
      },
      actionCancel: () => closeModal(),
    });
  };

  const RenderComponents: React.FC<{
    id: iColumns;
    items: MessageRecords['research']['messages'][0];
  }> = ({ id, items }) => {
    const formattedGroups = items.groups?.map(item => item.name);

    const channel = useMemo(() => {
      if (items.channel?.includes('EMAIL') && items.channel?.includes('SMS'))
        return 'E-mail e SMS';

      if (items.channel?.includes('EMAIL')) return 'E-mail';

      if (items.channel?.includes('SMS')) return 'SMS';

      return 'void';
    }, [items.channel]);

    switch (id) {
      case 'Grupos':
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
            <IconPerson width="20px" height="14px" />
            <Groups>{formattedGroups?.join(', ')}</Groups>
          </div>
        );
      case 'postChannel':
        return <div>{channel}</div>;
      case 'postDate':
        return (
          <div>
            <div>
              {items.schedule
                ? formattedDate(items.schedule as Date)
                : formattedDate(items.sent as Date)}
            </div>
          </div>
        );
      case 'actions':
        return (
          <ActionsList
            actions={{
              viewMessage: () =>
                handleViewMessageModal({
                  viewMessageContent: items.content,
                  viewMessageDate: items.schedule
                    ? formattedDate(items.schedule as Date)
                    : formattedDate(items.sent as Date),
                  viewMessageGroupsNames: formattedGroups?.join(', '),
                  viewMessageName: items.subject || items.name,
                }),
              deleteMessage: () => {
                handleDeleteMessageModal(items.id);
              },
            }}
          />
        );
      default:
        return <div>void</div>;
    }
  };

  const displayData = useMemo(() => {
    const orderedRecords = _.orderBy(
      messages,
      item => item.research?.name.toLowerCase(),
      ['asc'],
    );

    const start = (page - 1) * itemsPerPage;

    return {
      ...messages,
      records: orderedRecords?.slice(start, start + itemsPerPage),
    };
  }, [messages, page]);

  return (
    <Container>
      {/* {hasAccess(['RESEARCH.LIST']) && ( */}
      <>
        <Pagination
          currentPage={page}
          setPage={setPage}
          pageSize={
            messages?.length ? Math.ceil(messages?.length / itemsPerPage) : 0
          }
        />
        {displayData?.records?.map((elem, index) => (
          <Accordion allowToggle border="none">
            <AccordionItem key={elem?.research?.id}>
              {({ isExpanded }) => (
                <ExpandedContainer isExpanded={isExpanded} index={index}>
                  <ContainerAccordion>
                    <AccordionButton
                      _focus={{ outline: 'none' }}
                      _hover={{ background: 'transparent' }}
                    >
                      <ContentLabelAccordion>
                        <LabelAccordion data-testid={`MESSAGES_title_${index}`}>
                          <AccordionIcon />
                          <span>{` ${elem?.research?.name}`}</span>
                        </LabelAccordion>
                        {/* {hasAccess(['MESSAGES.UPDATE', 'MESSAGES.READ']) && ( */}
                        {/* <IconOptionsResearch
                          className="icon_research"
                          id="btn_icon_config"
                          data-testid={`btn_icon_config_${index}`}
                          onClick={() =>
                            navigate(`/messages/${elem?.research?.id}`)
                          }
                        /> */}
                        {/* )} */}
                      </ContentLabelAccordion>
                    </AccordionButton>
                  </ContainerAccordion>

                  <AccordionPanel>
                    <TableContainer
                      component={Paper}
                      className={classes.container}
                    >
                      <Table
                        className={classes.table}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            {columns?.map(item => {
                              return (
                                <TableCell
                                  key={`tableCell_${item.id}`}
                                  align="left"
                                  className={classes.headerTable}
                                >
                                  <div
                                    style={{
                                      color: '#8A8A8A',
                                      fontWeight: 'normal',
                                      fontSize: '1.4rem',
                                    }}
                                  >
                                    {item.label}
                                  </div>
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {elem?.research?.messages?.map(message => (
                            <TableRow>
                              {columns?.map((columnProps: any) => {
                                return (
                                  <TableCell
                                    key={`tableCellColumn_${columnProps.id}`}
                                    align={columnProps.align}
                                    style={{
                                      maxWidth: columnProps.maxWidth,
                                      minWidth: columnProps.minWidth,
                                      fontSize: columnProps.fontSize,
                                    }}
                                  >
                                    <RenderComponents
                                      key={columnProps.id}
                                      id={columnProps.id}
                                      items={message}
                                    />
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </AccordionPanel>
                </ExpandedContainer>
              )}
            </AccordionItem>
          </Accordion>
        ))}
      </>
      {/* )} */}
    </Container>
  );
};

export default ListMessage;
