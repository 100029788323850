export default {
  'Bem-vindo(a)!': 'Welcome!',
  'Digite o email cadastrado': 'Enter your registered email',
  'E-mail': 'E-mail',
  Senha: 'Password',
  Entrar: 'Login',
  'Digite sua senha': 'Enter your password',
  'Esqueci minha senha': 'Forgot my password',
  'Configurações de senha': 'Password settings',
  'Políticas de privacidade': 'Privacy policies',
  'Defina sua senha para acessar a plataforma.':
    'Set your password to access the platform.',
  ' e ': ' and ',
  'Termos de uso': 'Terms of use',
  Imagens: 'Images',
  Vídeos: 'Videos',
  Arquivos: 'Files',
  'Em desenvolvimento': 'In development',
  'Nova Imagem': 'New Image',
  'Novo Vídeo': 'New Video',
  'Novo Arquivo': 'New File',
  Pacientes: 'Patients',
  Mídia: 'Media',
  Mídias: 'Media',
  'Excluir imagem': 'Delete image',
  'Substituir imagem': 'Replace image',
  'Baixar imagem': 'Download image',
  'Excluir paciente': 'Delete patient',
  'Excluir grupo': 'Delete group',
  'Editar grupo': 'Edit group',
  'Mídia deletada com sucesso!': 'Media deleted successfully!',
  'Este arquivo pode estar sendo usado em alguma Sala de espera.':
    'This file may be used in some Waiting Room.',
  'Excluir Arquivo': 'Delete File',
  'Tem certeza que deseja excluí-lo?': 'Are you sure you want to delete it?',
  'Sim, excluir': 'Yes, delete',
  'Não excluir': 'Do not delete',
  'Visualizar Mídia': 'View Media',
  'Editar Mídia': 'Edit Media',
  'Baixar Imagem': 'Download Image',
  Excluir: 'Delete',
  'Abrir link em uma nova aba': 'Open link in a new tab',
  'Visualizar PDF': 'View PDF',
  'Baixar PDF': 'Download PDF',
  'Informações gerais': 'General data',
  'Instituição da pesquisa': 'Research institution',
  'Selecione uma instituição': 'Select an institution',
  'Título da pesquisa': 'Research title',
  'Digite o título da pesquisa': 'Enter the research title',
  'Descrição da pesquisa': 'Research description',
  'Digite a descrição': 'Enter the description',
  'Preencha todos os campos corretamente': 'Fill in all fields correctly',
  'Cadastro de coordenadores': 'Coordinator registration',
  'Para cadastrar os coordenadores que farão parte desta pesquisa, preencha os campos abaixo e depois clique em “Cadastrar Coordenador”;':
    'To register the coordinators who will be part of this research, fill in the fields below and then click on "Register Coordinator";',
  'Nome completo': 'Full name',
  'Digite o nome completo': 'Enter the full name',
  'Digite o e-mail': 'Enter the email',
  'Cadastrar coordenador': 'Register coordinator',
  'Coordenadores cadastrados na pesquisa:':
    'Coordinators registered in the research:',
  'Você ainda não possui coordenadores cadastrados.':
    'You do not have registered coordinators yet.',
  'Cadastro de grupos': 'Group registration',
  'Digite o nome do grupo que você deseja incluir na pesquisa e depois clique em “Cadastrar grupo”.':
    'Enter the name of the group you want to include in the research and then click on "Register group".',
  'Nome do grupo': 'Group name',
  'Digite o nome do grupo': 'Enter the group name',
  'Atualizar grupo': 'Update group',
  'Cadastrar grupo': 'Register group',
  'Grupos cadastrados:': 'Registered groups:',
  'Você ainda não possui grupos cadastrados.':
    'You do not have registered groups yet.',
  'A pesquisa deve possuir pelo menos um coordenador':
    'The research must have at least one coordinator',
  'A pesquisa deve possuir pelo menos um grupo':
    'The research must have at least one group',
  'Pesquisa atualizada com sucesso': 'Research updated successfully',
  'Erro ao atualizar pesquisa': 'Error updating research',
  'Configurações de pesquisa': 'Research settings',
  Pesquisas: 'Researches',
  Informações: 'Information',
  Coordenadores: 'Coordinators',
  Grupos: 'Groups',
  Cancelar: 'Cancel',
  Salvar: 'Save',
  Selecione: 'Select',
  'Atualizar coordenador': 'Update coordinator',
  'Seleção de Pacientes': 'Patient Selection',
  'Voltar para a lista': 'Back to list',
  'Inserir pacientes': 'Insert patients',
  'Usuário atualizado com sucesso!': 'User updated successfully!',
  'Erro ao atualizar usuário!': 'Error updating user!',
  'Mudar senha': 'Change password',
  'Nova senha': 'New password',
  'Digite nova senha': 'Enter new password',
  'Repita a nova senha': 'Repeat the new password',
  'Digite novamente a nova senha': 'Enter the new password again',
  'Regras para a nova senha:': 'Rules for the new password:',
  '- Mínimo de 6 dígitos': '- Minimum of 6 digits',
  '- Possuir letras e números': '- Have letters and numbers',
  '- Ser diferente de seus dados pessoais':
    '- Be different from your personal data',
  'Salvar Alterações': 'Save Changes',
  Imagem: 'Image',
  Nome: 'Name',
  Cidade: 'City',
  Contato: 'Contact',
  Ações: 'Actions',
  'Remover paciente': 'Remove patient',
  'Tem certeza que deseja remover este paciente do grupo?':
    'Are you sure you want to remove this patient from the group?',
  'Sim, remover': 'Yes, remove',
  'Não remover': 'Do not remove',
  'Erro ao buscar pacientes!': 'Error searching for patients!',
  'Recuperar senha': 'Recover password',
  'Enviaremos para o seu e-mail um link para você redefinir sua senha.':
    'We will send to your email a link for you to reset your password.',
  'Link enviado para o e-mail': 'Link sent to the email',
  Enviar: 'Send',
  'Dados perfil': 'Profile data',
  'Sair da conta': 'Log out',
  Coordenador: 'Coordinator',
  Administrador: 'Administrator',
  'Clique para ver as notas da release': 'Click to see the release notes',
  'Senha alterada com sucesso!': 'Password changed successfully!',
  'Erro ao alterar senha!': 'Error changing password!',
  'Confimar senha': 'Confirm password',
  'Link copiado!': 'Link copied!',
  'Não foi possível atualizar mídias': 'Unable to update media',
  'Você já possui 3 mídias fixas': 'You already have 3 fixed media',
  'Não foi possível buscar grupo': 'Unable to search group',
  'Não foi possível buscar mídias': 'Unable to search media',
  'Mídias do grupo': 'Group media',
  Fixada: 'Pinned',
  'Fixar mídia': 'Pin media',
  'Nova Pesquisa': 'New Research',
  'Participantes adicionados com sucesso!': 'Participants added successfully!',
  'Erro ao adicionar participantes!': 'Error adding participants!',
  'Selecione os pacientes do grupo': 'Select the patients from the group',
  selecionados: 'selected',
  'Você tem certeza que deseja cancelar a edição?':
    'Are you sure you want to cancel the edit?',
  'Cancelar edição': 'Cancel edit',
  'Deseja realmente cancelar?': 'Do you really want to cancel?',
  'Sim, cancelar': 'Yes, cancel',
  'Não cancelar': 'Do not cancel',
  'Mídia atualizada com sucesso!': 'Media updated successfully!',
  'Erro ao atualizar mídia!': 'Error updating media!',
  'Mídia criada com sucesso!': 'Media created successfully!',
  'Falha ao criar mídia': 'Failed to create media',
  'Erro ao buscar mídia': 'Error searching for media',
  'Atualizar Imagem': 'Update Image',
  'Adicionar Imagem': 'Add Image',
  'Atualizar Vídeo': 'Update Video',
  'Adicionar Vídeo': 'Add Video',
  'Atualizar PDF': 'Update PDF',
  'Adicionar PDF': 'Add PDF',
  'Insira o link do vídeo': 'Insert the video link',
  'Digite aqui a legenda que será apresentada na sala.':
    'Type here the caption that will be presented in the room.',
  'Digite aqui o nome desta imagem': 'Type here the name of this image',
  'Digite aqui o nome do arquivo': 'Type here the file name',
  'Selecione as instituições que podem visualizar está mídia:':
    'Select the institutions that can view this media:',
  'Todas as instituições': 'All institutions',
  Confirmar: 'Confirm',
  'Erro ao buscar pesquisas!': 'Error searching for researches!',
  pacientes: 'patients',
  mídias: 'media',
  'Dados pessoais': 'Personal data',
  'Meu perfil': 'My profile',
  'Grupo de Controle': 'Control group',
  Revisão: 'Revision',
  'Você ainda não possui grupo cadastrados.':
    'You still have no registered group.',
  Editar: 'To edit',
  'Instituição:': 'Institution:',
  'Pesquisa:': 'Search:',
  'Descrição:': 'Description:',
  Anterior: 'Previous',
  Concluir: 'Save',
  Próximo: 'Next',
  'Copiar link': 'Copy Link',
  'E-mail de recuperação enviado com sucesso!':
    'Recovery email sent successfully!',
  'Login realizado com sucesso!': 'Successful login!',
  'Usuário ou senha inválidos': 'username or password is invalid',
  'Erro inesperado': 'unexpected error',
  'Credênciais inválidas.': 'Invalid credence.',
  'Falha interna no servidor, tente novamente em minutos.':
    'Internal server failure, try again in minutes.',
  'Falha ao deletar mídia!': 'Failure to delete media!',
  'Pesquisa criada com sucesso': 'Successful created research',
  'Já existe uma pesquisa cadastrada com essas informações.':
    'There is already a survey registered with this information.',
  'Não foi possível criar a pesquisa':
    'It was not possible to create the research',
  'Paciente removido com sucesso!': 'Patient successfully removed!',
  'Erro ao remover paciente!': 'Error removing patient!',
  'Imagem atualizada com sucesso!': 'Image updated successfully!',
  'Erro ao atualizar imagem!': 'Error update image!',
  'Senhas não coincidem': 'Passwords do not coincide',
  'Email inválido!': 'Invalid email!',
  'Preencha o email!': 'Fill in the email!',
  'Preencha a senha!': 'Fill the password!',
  'Preencha o nome!': 'Fill in the name!',
  'Preencha a idade!': 'Fill the age!',
  'Idade inválida!': 'Invalid age!',
  'Selecione um sexo!': 'Select a sex!',
  'Senha Incorreta': 'Incorrect password',
  'Email não cadastrado': 'UNCONSTRATED EMAIL',
  'Cadastro não localizado': 'NOT LOCATED REGISTRATION',
  'Email inválido.': 'Invalid email.',
  'Senha inválida': 'invalid password',
  'A senha é obrigatória': 'The password is mandatory',
  'A senha deve ter no mínimo 6 caracteres':
    'The password must have at least 6 characters',
  'A senha deve conter pelo menos um número e uma letra maiúscula':
    'The password should contain at least one number and a capital letter',
  'A confirmação de senha é obrigatória': 'Password confirmation is mandatory',
  'As senhas não conferem': 'Passwords do not',
  'O nome excedeu o limite de 1024 caracteres':
    'The name exceeded the limit of 1024 characters',
  'O nome é obrigatório': 'The name is mandatory',
  'A descrição é obrigatória': 'The description is mandatory',
  'A URL não pode ter mais de 1024 caracteres':
    'The URL cannot have more than 1024 characters',
  'A URL deve ser válida': 'The URL must be valid',
  'A URL excedeu o limite de 1024 caracteres':
    'The URL exceeded the limit of 1024 characters',
  'A URL é obrigatória': 'The URL is mandatory',
  'O arquivo é obrigatório': 'The file is mandatory',
  'É necessário adicionar um arquivo': 'It is necessary to add a file',
  'O nome da organização é obrigatório':
    'The name of the organization is mandatory',
  'O título da pesquisa é obrigatório': 'The research title is mandatory',
  'A descrição da pesquisa é obrigatória':
    'The description of the research is mandatory',
  'O nome do grupo é obrigatório': 'The name of the group is mandatory',
  'O nome do responsável é obrigatório':
    'The name of the responsible is mandatory',
  'O e-mail do responsável é obrigatório': 'The responsible email is mandatory',
  Organizações: 'Organizations',
  'Seleção de Organizações': 'Selection of organizations',
  'Adicione as organizações abaixo que fazem parte da LAOHA.':
    'Add the organizations below that are part of Laoha.',
  'Não foi possível carregar as organizações':
    'It was not possible to carry the organizations',
  'Nome da organização': 'Name of the organization',
  Sigla: 'Initials',
  'Data de criação': 'Creation date',
  'Organização removida com sucesso!': 'Organization successfully removed!',
  'Erro ao remover organização.': 'Error removing organization.',
  'Organização criada com sucesso!': 'Organization successfully created!',
  'Erro ao criar organização.': 'Error when creating organization.',
  Adicionar: 'Add',
  Remover: 'Remove',
  Adicionado: 'Added',
  'Buscar pesquisa...': 'Search research...',
  'Biblioteca de Mídias': 'Media Library',
  Perfil: 'Profile',
  Sair: 'Exit',
  '1. Contexto': '1. Context',
  Pesquisa: 'Research',
  '2. Envio': 'Shipping',
  'Tipo de envio': 'Shipping type',
  Imediato: 'Immediate',
  Programado: 'Scheduled',
  'Única vez': 'One time',
  Recorrente: 'Recurrent',
  'Enviar em': 'Send in',
  'Iniciar em': 'Start on',
  Horário: 'Schedule',
  'Repetir em': 'Repeat on',
  'Finalizar em': 'Finish on',
  '3. Mensagem': '3. Message',
  'Enviar por': 'Send on',
  Título: 'Title',
  Conteúdo: 'Content',
  'Digite o título': 'Enter the title',
  'Escreva o conteúdo da mensagem e insira as mídias desejadas':
    'Write the message content and insert the desired media',
  'Enviar nova mensagem': 'Send new message',
  'Enviar mensagem': 'Send message',
  'Nova mensagem': 'New message',
  Mensagens: 'Messages',
  'Canal de envio': 'Sending channel',
  'Data de envio': 'Sending data',
  'Buscar paciente...': 'Search patient...',
  'Buscar mensagem...': 'Search message...',
  'Buscar organização...': 'Search organization...',
  'Buscar mídia...': 'Search media...',
};
