import { makeReduxListOrg } from 'main/factories/usecases/org/ListOrgFactory';
import { NewResearchForm } from 'presentation/components/Forms';
import React, { useEffect } from 'react';

const NewResearchPage: React.FC = (): JSX.Element => {
  useEffect(() => {
    makeReduxListOrg().list({
      query: {
        limit: 9999,
        offset: 0,
      },
    });
  }, []);

  return <NewResearchForm />;
};

export default NewResearchPage;
