import { GetGroups } from 'domain/usecases/groups/redux/GetGroups';
import {
  GroupsTypes,
  iActionGetAll,
  iActionGetAllFailed,
  iActionGetAllSuccess,
} from 'domain/interfaces/redux/groups/getAll';

export const getAllRequest = (payload: GetGroups.Params): iActionGetAll => ({
  type: GroupsTypes.GETALL,
  payload,
});

export const getAllSuccess = (
  params: GetGroups.Model,
): iActionGetAllSuccess => ({
  type: GroupsTypes.GETALL_SUCCESS,
  payload: params,
});

export const getAllFailed = (): iActionGetAllFailed => ({
  type: GroupsTypes.GETALL_FAILED,
});
