import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export const schema = yup.object().shape({
  groups: yup.array().of(yup.number().integer().positive()),
  channel: yup.array().of(yup.string()),
  subject: yup.string(),
  content: yup.string(),
  description: yup.string().max(1024),
  schedule: yup.date().optional(),
  recurrence: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          timezone: yup.number(),
          deadline: yup.string(),
          userId: yup.string().optional(),
          days: yup.array().of(yup.number()),
          hours: yup.array().of(yup.string()),
        })
        .optional(),
    )
    .nullable(),
});

export const schemaNow = yup.object().shape({
  groups: yup.array().of(yup.number().integer().positive()).required(),
  channel: yup.string().required(),
  subject: yup.string().required(),
  content: yup.string().required(),
});

export const schemaScheduled = yup.object().shape({
  groups: yup.array().of(yup.number().integer().positive()),
  channel: yup.string(),
  subject: yup.string(),
  content: yup.string(),
  description: yup.string().max(1024),
  schedule: yup.date().nullable(),
});

export const schemaRecurrence = yup.object().shape({
  groups: yup.array().of(yup.number().integer().positive()),
  channel: yup.string(),
  subject: yup.string(),
  content: yup.string(),
  description: yup.string().max(1024),
  recurrence: yup.array().of(
    yup.object().shape({
      timezone: yup.number(),
      deadline: yup.string(),
      days: yup.array().of(yup.number()),
      hours: yup.array().of(yup.string()),
    }),
  ),
});

export const schemaMessageNow = yupResolver(schemaNow);

export const schemaMessageScheduled = yupResolver(schemaScheduled);

export const schemaMessageRecurrence = yupResolver(schemaRecurrence);

export const schemaCreateNewMessage = yupResolver(schema);
