/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { IconPerson } from 'presentation/base/icons';
import { translator } from 'presentation/components/i18n';
import { Button, Input } from 'presentation/components/UI';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';
import { validateEmail } from 'utils/mask';
import { v4 as uuid } from 'uuid';
import { ListItem } from '../listItem';
import {
  Column,
  Container,
  ContainerList,
  ListContainer,
  Row,
  Subtitle,
  Title,
  VerticalDivider,
} from '../styles';

export type Coordinators = {
  organizers: {
    fullname: string;
    email: string;
    id: string;
  }[];
};

type Coordinator = {
  fullname: string;
  email: string;
  id: string;
};

export const Step2: React.FC = (): JSX.Element => {
  const theme = useTheme();

  const { setValue, getValues, watch } = useFormContext<Coordinators>();

  const [organizer, setOrganizer] = useState<Coordinator>({} as Coordinator);
  const [editing, setEditing] = useState<{
    editing: boolean;
    idToEdit: number;
  }>({ editing: false, idToEdit: -1 });

  const isEmpty = watch('organizers')?.length === 0;

  const handleAddCoordinator = () => {
    const previousArr = [...getValues('organizers')];

    if (
      organizer.fullname === '' ||
      !validateEmail(organizer.email) ||
      !organizer.fullname
    ) {
      return toast.error(translator('Preencha todos os campos corretamente'));
    }

    const obj = {
      fullname: organizer.fullname,
      email: organizer.email,
      id: uuid(),
    };

    previousArr.push(obj);
    setValue('organizers', previousArr);

    setOrganizer({ fullname: '', email: '' } as Coordinator);
  };

  const handleRemoveCoordinator = (id: string) => {
    const previousArr = [...getValues('organizers')];

    const findIndex = previousArr.findIndex(item => item.id === id);

    if (findIndex >= 0) {
      previousArr.splice(findIndex, 1);
      setValue('organizers', previousArr);
    }
  };

  const handleFindCoordinatorToEdit = (id: string) => {
    const previousArr = [...getValues('organizers')];

    const findIndex = previousArr.findIndex(item => item.id === id);

    if (findIndex >= 0) {
      setOrganizer(previousArr[findIndex]);
    }

    setEditing({ editing: true, idToEdit: findIndex });
  };

  const handleUpdateCoordinator = () => {
    const previousArr = [...getValues('organizers')];

    if (editing?.idToEdit >= 0) {
      previousArr[editing?.idToEdit] = organizer;
      setValue('organizers', previousArr);
    }

    setOrganizer({ fullname: '', email: '' } as Coordinator);

    setEditing({ editing: false, idToEdit: -1 });
  };

  return (
    <Container>
      <Column>
        <Title>{translator('Cadastro de coordenadores')}</Title>
        <Subtitle>
          {translator(
            'Para cadastrar os coordenadores que farão parte desta pesquisa, preencha os campos abaixo e depois clique em “Cadastrar Coordenador”;',
          )}
        </Subtitle>
        <form onSubmit={handleAddCoordinator}>
          <Row size="large">
            <Input
              data-testid="organizer_fullname"
              value={organizer.fullname}
              name="organizerName"
              onChange={e =>
                setOrganizer({ ...organizer, fullname: e.target.value })
              }
              label={translator('Nome completo')}
              placeholder={translator('Digite o nome completo')}
            />
          </Row>
          <Row size="large">
            <Input
              data-testid="organizer_email"
              name="email"
              value={organizer.email}
              onChange={e =>
                setOrganizer({ ...organizer, email: e.target.value })
              }
              label={translator('E-mail')}
              placeholder={translator('Digite o e-mail')}
            />
          </Row>
          <Row size="large">
            <Button
              variant="primary-custom"
              data-testid="btn_add_coordinator"
              color={theme.colors.secondary.main}
              // disabled={!isAbleToAdd}
              icon={IconPerson}
              onClick={
                editing?.editing
                  ? handleUpdateCoordinator
                  : handleAddCoordinator
              }
            >
              {translator('Cadastrar coordenador')}
            </Button>
          </Row>
        </form>
      </Column>
      <VerticalDivider />
      <Column>
        <Title variant>
          {translator('Coordenadores cadastrados na pesquisa:')}
        </Title>
        <ContainerList isEmpty={isEmpty}>
          {isEmpty ? (
            translator('Você ainda não possui coordenadores cadastrados.')
          ) : (
            <ListContainer>
              {watch('organizers')?.map((coordinator, index) => {
                if (index === editing.idToEdit) return;
                return (
                  <ListItem
                    key={coordinator.id}
                    item={coordinator}
                    handleDelete={handleRemoveCoordinator}
                    handleEdit={handleFindCoordinatorToEdit}
                  />
                );
              })}
            </ListContainer>
          )}
        </ContainerList>
      </Column>
    </Container>
  );
};

export default Step2;
