/* eslint-disable react/no-unknown-property */
import React from 'react';

const IconClose = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width ?? '18'}
      height={props.height ?? '18'}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 2L16 16"
        stroke="#C9C9C9"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16 2L2 16"
        stroke="#C9C9C9"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default IconClose;
