import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h1`
  color: #000000;
  font-family: 'Roboto';
  font-weight: bold;
  text-align: center;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 21px 0 48px 0;
  gap: 24px;
`;

export const RulesNewPassword = styled.div`
  display: flex;
  flex-direction: column;
  p {
    color: #000000;
    font-family: 'Roboto';
    margin-bottom: 8px;
  }
  span {
    color: #7a7a7a;
    font-family: 'Roboto';
  }
`;
