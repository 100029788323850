import { GetMediasByGroup } from 'domain/usecases/group/remote';
import {
  NotFound,
  BadRequestError,
  Forbidden,
  UnexpectedError,
  BadGateway,
} from 'domain/errors';
import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import 'infra/global/variables';
import { buildSearchParams } from 'utils/buildQueryParams';

export class RemoteGetMediasByGroup implements GetMediasByGroup {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetMediasByGroup.Model>;

  constructor(url: string, httClient: HttpClient<GetMediasByGroup.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  getByGroupId = async (
    params: GetMediasByGroup.Params,
  ): Promise<GetMediasByGroup.Model> => {
    const { groupId, query } = params;

    const url = `${this.url.replace(
      '{group}',
      String(groupId),
    )}?${buildSearchParams<GetMediasByGroup.Params['query']>(query)}`;

    const httpResponse = await this.httpClient.request({
      url,
      method: 'get',
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.accepted:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new Forbidden();
      case HttpStatusCode.badGateway:
        throw new BadGateway();
      default:
        throw new UnexpectedError();
    }
  };
}
