import styled from 'styled-components';

interface iContainer {
  size?: string;
}

export const Container = styled.label<iContainer>`
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  background-color: #0b77cd;
  width: ${props => props.size || '40px'};
  height: ${props => props.size || '40px'};
  cursor: pointer;

  :hover {
    filter: brightness(0.9);
    transition: 0.5s;
  }
`;

export const ContentUploadImage = styled.div`
  display: flex;
  align-items: centerflex;
  justify-content: centerflex;
`;
