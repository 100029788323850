import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { CreateOrg as UsecaseRemoteCreateOrg } from 'domain/usecases/org/remote';

import { RemoteCreateOrg } from 'data/repository/org';

/**
 * send request via API.
 */
export const makeRemoteCreateOrg = (): UsecaseRemoteCreateOrg =>
  new RemoteCreateOrg(makeApiUrl('/orgs/'), makeHttpClient());
