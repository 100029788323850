import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { GetByIdUser as UsecaseRemoteGetByIdUser } from 'domain/usecases/user/remote';
// import { GetByIdUser as UsecaseReduxGetByIdUser } from 'domain/usecases/user/redux';

import { RemoteGetByIdUser } from 'data/repository/user';
// import { ReduxGetByIdUser } from 'data/store/reducer/user/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetByIdUser = (): UsecaseRemoteGetByIdUser =>
  new RemoteGetByIdUser(makeApiUrl('/users/{user}'), makeHttpClient());

/**
 * send request via REDUX.
 */
/* export const makeReduxGetByIdUser =
  (): UsecaseReduxGetByIdUser =>
    new ReduxGetByIdUser(); */
