import React from 'react';

const IconPlusPrimary = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width ?? '16px'}
      height={props.height ?? '16px'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.00165 15.3804C12.0777 15.3804 15.382 12.0762 15.382 8.00012C15.382 3.92409 12.0777 0.619812 8.00165 0.619812C3.92562 0.619812 0.621338 3.92409 0.621338 8.00012C0.621338 12.0762 3.92562 15.3804 8.00165 15.3804Z"
        fill={props.fill ?? '#0B77CD'}
      />
      <path
        d="M8 16C3.58916 16 0 12.4115 0 8C0 3.58916 3.58916 0 8 0C12.4115 0 16 3.58916 16 8C16 12.4115 12.4115 16 8 16ZM8 1.23934C4.27203 1.23934 1.23934 4.27203 1.23934 8C1.23934 11.728 4.27203 14.7607 8 14.7607C11.728 14.7607 14.7607 11.7273 14.7607 8C14.7607 4.27203 11.728 1.23934 8 1.23934Z"
        fill={props.fill ?? '#0B77CD'}
      />
      <path
        d="M8.00201 11.6621C7.65995 11.6621 7.38232 11.3851 7.38232 11.0425V4.8457C7.38232 4.50364 7.65995 4.22601 8.00201 4.22601C8.34408 4.22601 8.6217 4.50364 8.6217 4.8457V11.0425C8.6217 11.3851 8.34408 11.6621 8.00201 11.6621Z"
        fill={props.stroke ?? '#FFFFFF'}
      />
      <path
        d="M11.0999 8.56329H4.90313C4.56107 8.56329 4.28345 8.28566 4.28345 7.9436C4.28345 7.60154 4.56107 7.32391 4.90313 7.32391H11.0999C11.4426 7.32391 11.7196 7.60154 11.7196 7.9436C11.7196 8.28566 11.4426 8.56329 11.0999 8.56329Z"
        fill={props.stroke ?? '#FFFFFF'}
      />
    </svg>
  );
};

export default IconPlusPrimary;
