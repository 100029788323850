import styled, { css } from 'styled-components';

interface ContainerProps {
  width: string;
}

interface SelectContainerProps {
  height: string;
  focus: boolean;
  disabled: boolean | undefined;
  error: boolean;
  border: boolean;
}

interface MessageProps {
  error: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: ${props => props.width};
  font-family: 'Roboto', Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
  display: flex;
  flex-direction: column;
`;

export const SelectGroup = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 4px;
  font-weight: lighter;
  padding-left: 16px;
  border: 1px solid #dfdfdf;
  background: #ffffff;
`;

export const Label = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #444a51;
  margin-bottom: 2px;
`;

export const SelectContainer = styled.span<SelectContainerProps>`
  position: relative;
  display: flex;
  align-items: center;
  height: ${props => props.height};
  border: ${props => (props.border ? '1px solid' : 'none')};
  box-shadow: ${props =>
    props.border ? 'none' : '0px 0px 5px rgba(0, 0, 0, 0.25)'};
  border-radius: 8px;
  border-color: ${props => (props.focus ? '#E20613' : '#dfdfdf')};
  background: #fff;

  select {
    font-family: inherit;
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #444a51;
    padding-left: 16px;
    z-index: 1;
    cursor: pointer;

    &.decorated {
      option:hover {
        background: #5f6871;
      }
    }

    option {
      :focus {
        background: red;
      }
    }

    ::placeholder {
      color: #bfc4ca;
    }

    :focus {
      #spanfocus {
        border-color: #e20613;
      }
    }

    :after {
      background: white;
    }

    -moz-appearance: none;
    -webkit-appearance: none;
  }

  /* select option:checked,
  select option:hover {
    background: red;
  }

  select:focus > option:hover {
    background: #000 !important;
  }

  select::-ms-expand {
    display: none;
  } */

  button {
    position: absolute;
    right: 0;
    width: 47px;
    height: calc(100% + 2px);
    border: none;
    color: ${props => (props.focus ? '#ffffff' : '#5F6871')};
    border-left: 1px solid;
    border-radius: 0px 3px 3px 0px;
    border-color: ${props => (props.focus ? '#E20613' : '#bfc4ca')};
    background-color: ${props => (props.focus ? '#E20613' : 'transparent')};
    margin-right: -1px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    position: absolute;
    right: 0;
  }

  .icon {
    margin-right: 13px;
  }

  :hover {
    border-color: #e20613;

    button {
      border-color: #e20613;
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #fafafb;

      :hover {
        border-color: #bfc4ca;

        button {
          border-color: #bfc4ca;
        }
      }

      button {
        z-index: 0;
      }
    `}

  ${({ error }) =>
    error &&
    css`
      border-color: #ea3e4f;

      :hover {
        border-color: #ea3e4f;

        button {
          border-color: #ea3e4f;
        }
      }

      button {
        border-color: #ea3e4f;
      }
    `}
`;

export const Message = styled.span<MessageProps>`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #7b8591;

  ${({ error }) =>
    error &&
    css`
      color: #ea3e4f;
    `}
`;
