/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/alt-text */
import axios from 'axios';
import { IconUploader } from 'presentation/base/icons';
import React, { useCallback, useEffect, useState } from 'react';
import {
  DropzoneInputProps as ReactDropzoneProps,
  useDropzone,
} from 'react-dropzone';
import Translator from '../i18n/Translator';
import { DropContainer, DropInput, DropText } from './styles/StyledDropzone';

interface DropzoneProps extends ReactDropzoneProps {
  id?: string;
  handleFileUpload: (file: any) => void;
  fixedHeight?: boolean;
  defaultImage?: string;
}

const LogoDropzone: React.FC<DropzoneProps> = ({
  id,
  defaultImage,
  handleFileUpload,
  fixedHeight,
  ...rest
}) => {
  const [file, setFile] = useState<any>([]);

  useEffect(() => {
    if (!defaultImage) return;

    axios
      .get(`${defaultImage}`, {
        headers: { 'Content-Type': 'application/octet-stream' },
        responseType: 'blob',
      })
      .then(res => {
        const value = new File([res.data], 'defaultImage.png', {
          type: 'image/png',
        });
        const updatedFileValue = Object.assign(value, {
          preview: defaultImage,
        });

        setFile([updatedFileValue]);
      });
  }, [defaultImage]);

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const updatedFile = acceptedFiles.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      );

      setFile(updatedFile);
      handleFileUpload(acceptedFiles[0]);
    },
    [handleFileUpload],
  );

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const files = file.map((file: any) => (
    <div key={file.name}>
      <img src={file.preview} />
    </div>
  ));

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    maxFiles: 1,
    accept: {
      'image/*': [],
      'application/pdf': [],
    },
  });

  return (
    <>
      <DropContainer
        {...rest}
        id={id}
        {...getRootProps()}
        fixedHeight={fixedHeight}
      >
        <DropInput {...getInputProps()} />

        {files.length > 0 ? (
          files[0]
        ) : (
          <>
            <IconUploader width="70px" height="70px" />
            <DropText>
              <span>{Translator('Clique para escolher um arquivo')}</span>
              <p>{Translator('ou arraste-o aqui.')}</p>
            </DropText>
          </>
        )}
      </DropContainer>
    </>
  );
};

export default LogoDropzone;
