import { GetGroups as UseCaseRemoteGetAllGroups } from 'domain/usecases/groups/remote';
import { GetGroups as UseCaseReduxGetAllGroups } from 'domain/usecases/groups/redux';

import { RemoteGetAllGroups } from 'data/repository/groups';
import { ReduxGetAllGroups } from 'data/store/reducer/groups/usecases/';
import { makeApiUrl, makeHttpClient } from 'main/factories/http';

export const makeRemoteGetAllGroups = (): UseCaseRemoteGetAllGroups =>
  new RemoteGetAllGroups(makeApiUrl('/groups/'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxGetAllGroups = (): UseCaseReduxGetAllGroups =>
  new ReduxGetAllGroups();
