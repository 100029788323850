import { Login as UseCaseRemoteLogin } from 'domain/usecases/auth/remote';
import { LoginAuth as UseCaseReduxLogin } from 'domain/usecases/auth/redux';

import { RemoteLoginAuth } from 'data/repository/auth';
import { ReduxLoginAuth } from 'data/store/reducer/auth/usecases/';
import { makeApiUrl, makeHttpClient } from 'main/factories/http';

export const makeRemoteLogin = (): UseCaseRemoteLogin =>
  new RemoteLoginAuth(makeApiUrl('/auth/'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxLogin = (): UseCaseReduxLogin => new ReduxLoginAuth();
