/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable array-callback-return */
// eslint-disable-next-line react/jsx-one-expression-per-line
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ActionsList from 'presentation/components/actions-list/ActionsList';
import { makeRemoteDeleteOrg } from 'main/factories/usecases/org/DeleteOrgFactory';
import { toast } from 'react-toastify';
import { makeRemoteCreateOrg } from 'main/factories/usecases/org/CreateOrgFactory';
import Translator from 'presentation/components/i18n/Translator';
import { iListOrganizations } from '../interface';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  container: {
    maxHeight: '70vh',
    overflowY: 'auto',
  },
  headerTable: {
    padding: '1.2rem',
  },
  accordionStyle: {
    boxShadow: 'none',
    maxHeight: '30rem',
    overflowY: 'auto',
  },
});

interface ownProps {
  dataList: iListOrganizations[];
  haveUpdated?: () => void;
}

type iColumns = 'name' | 'short' | 'created' | 'actions';

const ListOrganization: React.FC<ownProps> = ({
  dataList,
  haveUpdated,
}): JSX.Element => {
  const classes = useStyles();
  const columns = [
    {
      id: 'name',
      label: Translator('Nome da organização'),
      minWidth: 150,
      align: 'left',
      fontSize: '1.4rem',
    },
    {
      id: 'short',
      label: Translator('Sigla'),
      minWidth: 200,
      align: 'left',
      fontSize: '1.4rem',
    },
    {
      id: 'created',
      label: Translator('Data de criação'),
      minWidth: 100,
      align: 'left',
      fontSize: '1.4rem',
    },
    {
      id: 'actions',
      label: Translator('Ações'),
      minWidth: 20,
      align: 'right',
      fontSize: '1.4rem',
    },
  ];

  const RenderComponents: React.FC<{
    id: iColumns;
    list: iListOrganizations;
  }> = ({ id, list }) => {
    switch (id) {
      case 'name':
        return <div>{list.name}</div>;
      case 'short':
        return <div>{list.short}</div>;
      case 'created':
        return (
          <div>
            {list.created ? new Date(list.created).toLocaleDateString() : '-'}
          </div>
        );
      case 'actions':
        return (
          <ActionsList
            actions={
              list.id
                ? {
                    removeOrganization: () => {
                      makeRemoteDeleteOrg()
                        .delete({
                          body: {
                            orgId: String(list.short),
                          },
                        })
                        .then(res => {
                          toast.success(
                            Translator('Organização removida com sucesso!'),
                          );
                          haveUpdated?.();
                        })
                        .catch(err => {
                          toast.error(
                            Translator('Erro ao remover organização.'),
                          );
                        });
                    },
                  }
                : {
                    addOrganization: () => {
                      makeRemoteCreateOrg()
                        .create({
                          body: {
                            name: list.name,
                            short: list.short,
                          },
                        })
                        .then(res => {
                          toast.success(
                            Translator('Organização criada com sucesso!'),
                          );
                          haveUpdated?.();
                        })
                        .catch(err => {
                          toast.error(Translator('Erro ao criar organização.'));
                        });
                    },
                  }
            }
          />
        );
      default:
        return <div>alo</div>;
    }
  };

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {columns.map(item => {
              return (
                <TableCell align="left" className={classes.headerTable}>
                  <div
                    style={{
                      color: '#8A8A8A',
                      fontWeight: 'normal',
                      fontSize: '1.4rem',
                    }}
                  >
                    {item.label}
                  </div>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataList?.map(item => (
            <TableRow key={item.name}>
              {columns.map((columnProps: any) => {
                return (
                  <TableCell
                    align={columnProps.align}
                    style={{
                      maxWidth: columnProps.maxWidth,
                      minWidth: columnProps.minWidth,
                      fontSize: columnProps.fontSize,
                    }}
                  >
                    <RenderComponents id={columnProps.id} list={item} />
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ListOrganization;
