import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { GetMediasByGroup as UsecaseRemoteGetMediasByGroup } from 'domain/usecases/group/remote';
// import { GetByIdGroup as UsecaseReduxGetByIdGroup } from 'domain/usecases/group/redux';

import { RemoteGetMediasByGroup } from 'data/repository/group';
// import { ReduxGetByIdGroup } from 'data/store/reducer/group/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetMediasByGroup = (): UsecaseRemoteGetMediasByGroup =>
  new RemoteGetMediasByGroup(
    makeApiUrl('/groups/{group}/medias'),
    makeHttpClient(),
  );

/**
 * send request via REDUX.
 */
/* export const makeReduxGetByIdGroup =
  (): UsecaseReduxGetByIdGroup =>
    new ReduxGetByIdGroup(); */
