import { call, put, select } from 'redux-saga/effects';
import { iActionLogin } from 'domain/interfaces/redux/auth/login';
import {
  loginSuccess,
  loginFailed,
} from 'data/store/reducer/auth/actions/login';
import { toast } from 'react-toastify';
import { makeRemoteLogin } from 'main/factories/usecases/auth/LoginFactory';
import { Login } from 'domain/usecases/auth/remote/Login';
import { navigate } from 'main/routes/_CustomRouter';
import Translator from 'presentation/components/i18n/Translator';

export function* onLogin(action: iActionLogin) {
  const remoteLoginAuth = makeRemoteLogin();

  try {
    const response: Login.Model = yield call(
      remoteLoginAuth.login,
      action.payload,
    );
    yield put(
      loginSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(loginFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onLoginSuccess() {
  navigate('/home');
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onLoginFailed() {
  toast.error(Translator('Credênciais inválidas.'));
}
