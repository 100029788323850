import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { UploadMedia as UsecaseRemoteUploadMedia } from 'domain/usecases/media/remote';
// import { UploadMedia as UsecaseReduxUploadMedia } from 'domain/usecases/media/redux';

import { RemoteUploadMedia } from 'data/repository/media';
// import { ReduxUploadMedia } from 'data/store/reducer/media/usecases';

/**
 * send request via API.
 */
export const makeRemoteUploadMedia = (): UsecaseRemoteUploadMedia =>
  new RemoteUploadMedia(makeApiUrl('/medias/'), makeHttpClient());

/**
 * send request via REDUX.
 */
/* export const makeReduxUploadMedia =
  (): UsecaseReduxUploadMedia =>
    new ReduxUploadMedia(); */
