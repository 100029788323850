import React from 'react';

const IconMessage = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.75 6.75H3V8.25H9.75V6.75ZM3 3.75H12V5.25H3V3.75ZM0 1.5V15L3 12H13.5C13.8978 12 14.2794 11.842 14.5607 11.5607C14.842 11.2794 15 10.8978 15 10.5V1.5C15 0.675 14.325 0 13.5 0H1.5C1.10218 0 0.720645 0.158035 0.439341 0.43934C0.158035 0.720644 0 1.10218 0 1.5ZM1.5 1.5H13.5V10.5H2.3775L1.5 11.3775V1.5Z"
        fill={props.fill ?? '#656A6E'}
      />
    </svg>
  );
};

export default IconMessage;
