/* eslint-disable react/require-default-props */
import React, {
  createContext,
  useCallback,
  useContext,
  ComponentType,
  useMemo,
  useEffect,
} from 'react';

import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';
import jwtDecode from 'jwt-decode';

type AccessContextData = {
  hasAccess: (roles: string[]) => boolean;
};

const AccessContext = createContext<AccessContextData>({} as AccessContextData);

type iACL = {
  [key: string]: ['CREATE' | 'READ' | 'UPDATE' | 'DELETE' | 'UPLOAD' | 'LIST'];
};

type withAccessProps = {
  roles?: string[];
};

const AccessProvider: React.FC = ({ children }) => {
  const { accessToken } = useSelector((store: iStore) => store.auth);

  const rolesMapped: string[] = useMemo(() => [], [accessToken]);

  if (accessToken) {
    const { acl } = jwtDecode<{ acl: iACL }>(accessToken);

    Object.entries(acl).map(([key, value]) => {
      const actions = value.map(action => {
        return `${key}.${action}`;
      });

      return rolesMapped.push(...actions);
    });
  }

  const hasAccess = useCallback(
    (roles: string[]) => {
      return roles.some(role => rolesMapped.includes(role));
    },
    [rolesMapped],
  );

  return (
    <AccessContext.Provider value={{ hasAccess }}>
      {children}
    </AccessContext.Provider>
  );
};

const useAccess = (): AccessContextData => {
  const context = useContext(AccessContext);

  if (!context) {
    throw new Error('useAccess must be used within an AccessProvider');
  }

  return context;
};

function withAccess<P>(
  Component: ComponentType<P>,
): React.FC<P & withAccessProps> {
  const WithAccessComponent: React.FC<P & withAccessProps> = ({
    roles = [],
    ...props
  }) => {
    const { hasAccess } = useAccess();

    return hasAccess(roles) || roles.length === 0 ? (
      <Component {...(props as P)} />
    ) : (
      <></>
    );
  };

  return WithAccessComponent;
}

export { AccessProvider, useAccess, withAccess };
