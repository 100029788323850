import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { CreateMessage as UsecaseRemoteCreateMessage } from 'domain/usecases/message/remote';
// import { DeleteMedia as UsecaseReduxDeleteMedia } from 'domain/usecases/media/redux';

import { RemoteCreateMessage } from 'data/repository/message';
// import { ReduxDeleteMedia } from 'data/store/reducer/media/usecases';

/**
 * send request via API.
 */
export const makeRemoteCreateMessage = (): UsecaseRemoteCreateMessage =>
  new RemoteCreateMessage(makeApiUrl('/messages'), makeHttpClient());

/**
 * send request via REDUX.
 */
/* export const makeReduxDeleteMedia =
  (): UsecaseReduxDeleteMedia =>
    new ReduxDeleteMedia(); */
