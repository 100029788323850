import styled from 'styled-components';
import { StylesConfig } from 'react-select';

export const General = styled.div`
  display: flex;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* grid-template-columns: 416px 0.2fr 807px; */
  /* grid-template-columns: 0.4fr 0.2fr 1fr; */
  width: 100%;
  height: 100%;
  min-height: 500px;
  padding: 1.5rem 2rem;
  align-items: flex-start;
  /* justify-content: space-between; */

  border-radius: 0.5rem;
  border: 0.1rem solid #e7e7e7;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Separete = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e7e7e7;

  margin: 18px 0;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 100%;
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 16px;

  color: #444a51;
`;

export const SendOptions = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 550px;
  width: 100%;
  gap: 15px;
  font-size: 18px;
`;

export const MultiSelectStyle: StylesConfig = {
  container(base, props) {
    return {
      ...base,
      width: '140px',
      height: '4.9rem',
      cursor: 'pointer',
    };
  },

  control(base, props) {
    return {
      ...base,
      width: '100%',
      height: '100%',
      boxShadow: 'none',
      borderRadius: '0.5rem',
      backgroundColor: '#fcfcfc',
      maxWidth: '30rem',
      fontSize: '1.6rem',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    };
  },

  option(base, props) {
    const { isDisabled, isFocused, isSelected } = props;
    return {
      ...base,
      color: '#000',
      cursor: isDisabled ? 'default' : 'pointer',
      opacity: isDisabled ? 0.5 : 1,
      fontSize: '1.6rem',

      backgroundColor: isSelected && !isDisabled ? '#f46b2144' : '#fcfcfc',
      '&:hover': {
        backgroundColor: isDisabled ? 'none' : '#f46b2180',
        color: isDisabled ? '#000' : '#fff',
      },

      '&:before': {
        content: '""',
        display: 'inline-block',
        width: '0.5rem',
        height: '0.5rem',
        borderRadius: '50%',
        marginRight: '0.5rem',
        backgroundColor: props.isSelected ? '#f46b21' : '#fcfcfc',
      },
    };
  },
};

export const SendDateTimeOptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 8px;
  z-index: 10;

  input {
    width: 138px;
  }

  .nice-dates-popover {
    width: 290px;
    background-color: white;
    padding: 12px;
    box-shadow: 16px;
  }

  .nice-dates-navigation {
    padding-bottom: 12px;

    a {
      padding-bottom: 24px;

      &:before {
        border-color: #686868;
      }
    }
    .nice-dates-navigation_current {
      color: #686868;
      font-size: 16px;
      font-weight: 700;
    }
  }

  .nice-dates-week-header {
    background-color: transparent;
    border-radius: 4px;
    box-shadow: none;
    padding-left: 12px;
    /* padding-right: 12px; */

    .nice-dates-week-header_day {
      width: 100%;
      color: #656a6e;
      font-size: 16px;
      text-transform: capitalize;
    }
  }

  .nice-dates-grid {
    height: 204px !important;
  }

  .nice-dates-grid_container {
    width: 100%;
    padding-left: 12px;
    padding-top: 12px;
  }

  .nice-dates-day {
    font-size: 12px;
    width: 32;
    height: 32px !important;
    padding: 2px 0;

    &:after {
      border-width: 1;
      border-color: #1756e8;
      background-color: white;
    }

    .nice-dates-day_month {
      display: none;
    }

    &.-wide {
      color: #686868;
    }

    &.-outside {
      color: #d2d2d2;
    }

    &:hover {
      color: #1756e8;
    }

    &.-selected {
      color: #686868;

      span {
        color: currentColor;
      }

      &:hover {
        &:after {
          background-color: #20caa8;
        }
      }

      &:after {
        transform: scale(1);
      }

      &:before {
        background-color: #20caa8;
      }

      &.-selected-middle {
        &:before {
          background-color: #72dec8;
        }
      }

      &.-selected-end {
        &:after {
          opacity: 1;
          background-color: #20caa8;
          border-radius: 999px;
        }
        &:before {
          background-color: #72dec8;
          border-color: #72dec8;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      &.-selected-start {
        &:after {
          opacity: 1;
          background-color: #20caa8;
          border-radius: 999px;
        }
        &:before {
          background-color: #72dec8;
          border-color: #72dec8;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }
`;

interface TabOptionProps {
  active?: boolean;
}

export const TabOption = styled.div<TabOptionProps>`
  width: 100%;
  height: 44px;
  cursor: pointer;

  display: flex;
  align-items: center;

  background-color: ${({ active }) => (active ? '#f3f8fc' : 'transparent')};

  border: 1px solid #efefef;
  border-radius: 5px;
`;

export const EmailView = styled.div`
  width: 100%;

  display: flex;
`;

export const Email = styled.div`
  font-weight: 500;
  font-size: 16px;

  color: #df2c26;
  font-weight: bold;

  margin-left: 4px;
`;

export const EmailSubject = styled.div`
  font-weight: bold;
  font-size: 16px;

  color: #444a51;

  margin-bottom: 8px;
`;

export const SubContent = styled.div`
  width: 100%;
`;

export const Box = styled.div`
  height: 256px;
  border: 1px solid #bab9b9;
`;

// ! new style

interface CheckboxViewProps {
  active?: boolean;
}

export const CheckboxView = styled.div<CheckboxViewProps>`
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 18px;
  height: 18px;

  background-color: white;
  border: ${({ active }) =>
    active ? '2px solid #0B77CD' : '1px solid #BFC4CA'};

  border-radius: 50%;
  margin-right: 8px;
`;

export const TabOptionsView = styled.div``;

export const CheckboxChecked = styled.div<CheckboxViewProps>`
  width: 10px;
  height: 10px;

  background: ${({ active }) => (active ? '#0B77CD' : 'transparent')};
  border-radius: 50%;
`;

export const InputContent = styled.div``;

export const InputView = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;
