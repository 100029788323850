import { iGroups } from 'domain/interfaces/models/Groups';
import { GroupsTypes } from 'domain/interfaces/redux/groups/types';
import { GroupActions } from './actions';

export const initialState: iGroups = {
  results: [],
  loading: false,
};

const reducer = (state = initialState, action: GroupActions): iGroups => {
  switch (action.type) {
    case GroupsTypes.GETALL: {
      return { ...state, loading: true };
    }
    case GroupsTypes.GETALL_SUCCESS:
      return { ...state, results: action.payload, loading: false };
    case GroupsTypes.GETALL_FAILED:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default reducer;
