import { IconAddParticipant, IconRemovePatient } from 'presentation/base/icons';
import IconConfirm from 'presentation/base/icons/icon_confirm.png';
import IconRemove from 'presentation/base/icons/icon_remove.png';
import IconAdd from 'presentation/base/icons/icon_add.png';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 16px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  .css-17mg6aq:last-of-type {
    border: none;
  }

  .chakra-accordion__button css-1ygl3x {
    background: red;
  }

  .MuiPaper-elevation1 {
    box-shadow: none;
  }

  .MuiTableCell-sizeSmall {
    padding: 10px;
  }

  .css-1ygl3x:hover {
    background-color: transparent;
  }

  @media (max-width: 1366px) {
    max-height: 50rem;
  }

  @media (max-width: 1366px) and (max-height: 720px) {
    max-height: 43rem;
    overflow-y: auto;
  }
`;

export const PaginationDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
`;

export const IconDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const IconAddStyled = styled.div`
  height: 16px;
  width: 16px;

  cursor: pointer;

  background-image: url(${IconAdd});
  background-repeat: no-repeat;
`;

export const IconAddedStyled = styled.div`
  width: 16px;
  height: 16px;

  cursor: pointer;

  background-image: url(${IconConfirm});
  background-repeat: no-repeat;

  &:hover {
    background-image: url(${IconRemove});
  }
`;
