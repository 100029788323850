import { iResearch } from 'domain/interfaces/models/Research';
import { ResearchTypes } from 'domain/interfaces/redux/research/types';
import { ResearchActions } from './actions';

export const initialState: iResearch = {
  records: undefined,
  metadata: undefined,
};

const reducer = (state = initialState, action: ResearchActions): iResearch => {
  switch (action.type) {
    case ResearchTypes.LIST:
      return state;
    case ResearchTypes.LIST_FAILED:
      return state;
    case ResearchTypes.LIST_SUCCESS: {
      return state;
    }
    default:
      return state;
  }
};

export default reducer;
