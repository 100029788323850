import React, { useState, cloneElement } from 'react';
import { IconMoreOptions } from 'presentation/base/icons';
import Popover from '@material-ui/core/Popover';
import { Container } from './styles/StyledPopover';

interface popoverProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactElement;
}

const PopoverPopupState: React.FC<popoverProps> = ({
  children,
  ...rest
}): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<boolean>(Boolean(anchorEl));

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  // const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Container {...rest}>
      <IconMoreOptions onClick={handleClick} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {cloneElement(children, {
          onClick: handleClose,
        })}
      </Popover>
    </Container>
  );
};

export default PopoverPopupState;
