/* eslint-disable react/require-default-props */
import React from 'react';
import {
  Email,
  Left,
  ListItemContainer,
  Name,
  Right,
  StIconDelete,
  StIconPencil,
} from './styles';

type ListItemProps = {
  id?: string;
  item: {
    id: string;
    fullname?: string;
    name?: string;
    email?: string;
  };
  disabledActions?: boolean;
  handleDelete?: (id: string) => void;
  handleEdit?: (id: string) => void;
};

export const ListItem: React.FC<ListItemProps> = ({
  id,
  item,
  disabledActions = false,
  handleDelete,
  handleEdit,
}) => {
  return (
    <ListItemContainer>
      <Left>
        {/* <img alt="empty" /> */}
        <Name>{item.fullname || item.name}</Name>
      </Left>
      <Right>
        <Email>{item.email}</Email>
        {!disabledActions && (
          <>
            <StIconPencil
              data-testid={`btn_edit_${id}`}
              onClick={() => handleEdit?.(item.id ?? item.email)}
            />
            <StIconDelete
              data-testid={`btn_delete_${item.id}`}
              onClick={() => handleDelete?.(item.id ?? item.email)}
            />
          </>
        )}
      </Right>
    </ListItemContainer>
  );
};

export default ListItem;
