import { makeReduxActiveMessage } from 'main/factories/usecases/message/UpdateFactory';
import { environment } from '../main/environment/environment';

const { REACT_APP_LOCAL_STORAGE_ADJROOMS } = environment;

export const closeModal = (): void => {
  makeReduxActiveMessage().active({
    active: 'none',
  });
};
