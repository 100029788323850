import React from 'react';
import { AvatarImage } from '.';
import {
  ContainerAvatars,
  ContainerImage,
  Name,
  Roles,
  ContainerInfo,
} from './styles/StyledAvatar';

interface propsFunction {
  role: 'SYSADMIN' | 'ORGANIZER';
  name: string;
  // eslint-disable-next-line react/require-default-props
  src?: string;
  // eslint-disable-next-line react/require-default-props
  size?: 'small' | 'orange' | 'large' | 'medium' | 'root' | 'mini';
}

const AvatarRoles: React.FC<propsFunction> = ({
  role,
  name,
  src,
  size = 'root',
}) => {
  const userType = {
    SYSADMIN: 'Administrador',
    ORGANIZER: 'Coordenador',
  };

  return (
    <ContainerAvatars>
      <div className={role}>
        <ContainerInfo>
          <Name>{name}</Name>
          <Roles>{userType[role]}</Roles>
        </ContainerInfo>
        <ContainerImage id="profile">
          <AvatarImage size={size} name={name} src={src} />
        </ContainerImage>
      </div>
    </ContainerAvatars>
  );
};

export default AvatarRoles;
