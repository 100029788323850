/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import ImagePDF from 'presentation/base/icons/imagePDF.png';
import React, { useEffect, useState } from 'react';

import { iMedia, iStore } from 'domain/interfaces/models';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { makeReduxListMedia } from 'main/factories/usecases/media/ListMediaFactory';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/UpdateFactory';
import { makeReduxListOrg } from 'main/factories/usecases/org/ListOrgFactory';
import { IconEmptyVideo, IconPlus } from 'presentation/base/icons';
import Card from 'presentation/components/Cards/Card';
import EmptyMedia from 'presentation/components/emptyMedia';
import SearchListBroadcastGroup from 'presentation/components/search-list/SearchListBroadcastGroups';
import { SelectMedias } from 'presentation/components/select-medias';
import { Button, Footer } from 'presentation/components/UI';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { formattedUrl } from 'utils/formattedUrl';
import { closeModal } from 'utils/modalFunctions';
import { useAccess, withAccess } from 'presentation/hooks/access';
import { translator } from 'presentation/components/i18n';
import Translator from 'presentation/components/i18n/Translator';
import {
  AddingMedia,
  ContainerCards,
  ContainerMediaLibrary,
  ListMediaLibrarys,
  SearchContent,
} from './styles/StyledMediaLibraryPage';

const selectMediaOptions = [
  Translator('Imagens'),
  Translator('Vídeos'),
  Translator('Arquivos'),
];

const MediaLibraryPage: React.FC = (): JSX.Element => {
  const [activeSelectMedias, setActiveSelectMedias] = useState<number>(0);
  const [imageData, setImageData] = useState<iMedia['records']>([]);
  const [pdfData, setPdfData] = useState<iMedia['records']>([]);
  const [videoData, setVideoData] = useState<iMedia['records']>([]);

  const theme = useTheme();

  const { active } = useSelector((store: iStore) => store.message);

  const { records: medias } = useSelector((store: iStore) => store.media);

  const { hasAccess } = useAccess();

  const handleSubmit = (id: number, url: string) => {
    makeReduxActiveMessage().active({
      active: MessageOptions.ImageModal,
      id,
      url,
      actionOk: () => closeModal(),
      actionCancel: () => closeModal(),
    });
  };

  const handleAddingMediaModal = (
    type: 'FORM' | 'IMAGE' | 'VIDEO' | 'AUDIO' | 'TEXT',
  ) => {
    makeReduxActiveMessage().active({
      active: MessageOptions.addingMediaModal,
      type,
      isEditing: false,
      actionOk: () => closeModal(),
      actionCancel: () => closeModal(),
    });
  };

  useEffect(() => {
    makeReduxListMedia().list({
      query: {
        limit: 9999,
      },
    });
  }, [active]);

  useEffect(() => {
    medias?.map(item => {
      if (item.type === 'IMAGE') {
        setImageData(prevState => [...prevState, item]);
      }

      if (item.type === 'VIDEO') {
        setVideoData(prevState => [...prevState, item]);
      }

      if (item.type === 'FORM') {
        setPdfData(prevState => [...prevState, item]);
      }
    });
  }, [medias]);

  useEffect(() => {
    makeReduxListOrg().list({
      query: {
        limit: 9999,
        offset: 0,
      },
    });
  }, []);

  return (
    <ContainerMediaLibrary>
      <SearchContent>
        <SelectMedias
          active={activeSelectMedias}
          options={selectMediaOptions}
          setState={setActiveSelectMedias}
        />
        <AddingMedia>
          <SearchListBroadcastGroup placeholder={Translator('Buscar mídia...')}>
            <h4>{translator('Em desenvolvimento')}</h4>
          </SearchListBroadcastGroup>
          {activeSelectMedias === 0 && (
            <>
              {hasAccess(['MEDIA.UPLOAD']) && (
                <Button
                  data-testid="add-image"
                  color={theme.colors.secondary.main}
                  variant="primary-custom"
                  size="medium"
                  icon={IconPlus}
                  onClick={() => handleAddingMediaModal('IMAGE')}
                >
                  {translator('Nova Imagem')}
                </Button>
              )}
            </>
          )}

          {activeSelectMedias === 1 && (
            <>
              {hasAccess(['MEDIA.UPLOAD']) && (
                <Button
                  data-testid="add-video"
                  color={theme.colors.secondary.main}
                  variant="primary-custom"
                  size="medium"
                  icon={IconPlus}
                  onClick={() => handleAddingMediaModal('VIDEO')}
                >
                  {translator('Novo Vídeo')}
                </Button>
              )}
            </>
          )}

          {activeSelectMedias === 2 && (
            <>
              {hasAccess(['MEDIA.UPLOAD']) && (
                <Button
                  data-testid="add-file"
                  color={theme.colors.secondary.main}
                  variant="primary-custom"
                  size="medium"
                  icon={IconPlus}
                  onClick={() => handleAddingMediaModal('FORM')}
                >
                  {translator('Novo Arquivo')}
                </Button>
              )}
            </>
          )}
        </AddingMedia>
      </SearchContent>
      <ListMediaLibrarys>
        {activeSelectMedias === 0 && (
          <ContainerCards data-testid="cards_list">
            {imageData.length ? (
              <>
                {medias?.map(item => (
                  <>
                    {item.type === 'IMAGE' && (
                      <div key={item.id}>
                        <Card
                          name={item.name}
                          type={item.type}
                          id={item.id}
                          url={item?.path}
                        >
                          <img
                            src={item.path}
                            alt={item.name}
                            onClick={() => handleSubmit(item.id, item.path)}
                          />
                        </Card>
                      </div>
                    )}
                  </>
                ))}
              </>
            ) : (
              <EmptyMedia data-testid="empty_image" type="image" />
            )}
          </ContainerCards>
        )}

        {activeSelectMedias === 1 && (
          <ContainerCards data-testid="cards_list">
            {videoData.length ? (
              <>
                {medias?.map(item => (
                  <>
                    {item.type === 'VIDEO' && (
                      <div key={item.id}>
                        <Card
                          name={item.name}
                          type={item.type}
                          id={item.id}
                          url={item.url}
                        >
                          {!item.url ||
                          !item.url.match(
                            /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/,
                          ) ? (
                            <IconEmptyVideo
                              data-testid={`empty_video_${item.id}`}
                              width="100%"
                              height="90%"
                            />
                          ) : (
                            <iframe
                              width="100%"
                              height="100%"
                              src={formattedUrl(item.url)}
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                              title="Embedded youtube"
                            />
                          )}
                        </Card>
                      </div>
                    )}
                  </>
                ))}
              </>
            ) : (
              <EmptyMedia data-testid="empty_video" type="video" />
            )}
          </ContainerCards>
        )}

        {activeSelectMedias === 2 && (
          <ContainerCards data-testid="cards_list">
            {pdfData.length ? (
              <>
                {medias?.map(item => (
                  <>
                    {item.type === 'FORM' && (
                      <div key={item.id}>
                        <Card
                          name={item.name}
                          type="FORM"
                          id={item.id}
                          url={item.path}
                        >
                          <img src={ImagePDF} alt={item.name} />
                        </Card>
                      </div>
                    )}
                  </>
                ))}
              </>
            ) : (
              <EmptyMedia data-testid="empty_file" type="pdf" />
            )}
          </ContainerCards>
        )}
      </ListMediaLibrarys>
      <Footer />
    </ContainerMediaLibrary>
  );
};

export default withAccess(MediaLibraryPage);
