/* eslint-disable react/require-default-props */
import { IconCamera } from 'presentation/base/icons';
import React from 'react';

import { makeReduxLogin } from 'main/factories/usecases/auth/LoginFactory';
import { makeRemoteGetByIdUser } from 'main/factories/usecases/user/GetByIdUserFactory';
import { makeRemoteUpdateImageUser } from 'main/factories/usecases/user/UpdateImageUser';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Translator from 'presentation/components/i18n/Translator';
import { Container, ContentUploadImage } from './styles/StyledUploader';

interface ownProps {
  setUpdate?(state: boolean): void;
  size?: string;
  haveUpdated?: () => void;
}

const Uploader: React.FC<ownProps> = ({ setUpdate, size, haveUpdated }) => {
  const { id } = useParams();

  const onChange = (data: any) => {
    const formData = new FormData();

    if (!data.target.files.length) return;

    formData.append('file', data.target.files[0]);

    makeRemoteUpdateImageUser()
      .update({
        file: formData,
        user: Number(id),
      })
      .then(() => {
        toast.success(Translator('Imagem atualizada com sucesso!'));
      })
      .catch(() => {
        toast.error(Translator('Erro ao atualizar imagem!'));
      })
      .finally(async () => {
        const response = await makeRemoteGetByIdUser().getById({
          user: Number(id),
        });
        makeReduxLogin().update(response);
      });
  };

  return (
    <>
      <Container size={size}>
        <ContentUploadImage>
          <IconCamera width="50px" height="50px" />
        </ContentUploadImage>
        <input
          type="file"
          id="photo"
          name="photo"
          accept="image/png, image/jpeg"
          onChange={onChange}
          style={{ display: 'none' }}
        />
      </Container>
    </>
  );
};

export default Uploader;
