/* eslint-disable react/require-default-props */
import React, { useEffect, useMemo, useState } from 'react';

import { GetByIdUser } from 'domain/usecases/user/remote';
import { makeReduxLogoutAuth } from 'main/factories/usecases/auth/LogoutAuthFactory';
import { makeRemoteGetByIdUser } from 'main/factories/usecases/user/GetByIdUserFactory';
import {
  IconDefaultUser,
  IconExitAccountBlue,
  IconPadLockRed,
  IconPersonRed,
} from 'presentation/base/icons';
import { useNavigate, useParams } from 'react-router-dom';

import { iStore } from 'domain/interfaces/models';
import { useSelector } from 'react-redux';
import {
  Actions,
  Container,
  InfoUser,
  Options,
} from './styles/StyledProfileNavigator';
import { AvatarWithUploader } from '../UI';
import { translator } from '../i18n';

interface OwnProps {
  nav: Function;
  active: number;
  haveUpdated?: () => void;
}

const PerfilNavigator: React.FC<OwnProps> = ({ nav, active, haveUpdated }) => {
  const navigate = useNavigate();
  const { user } = useSelector((store: iStore) => store.auth);

  const generalData = useMemo(() => {
    return (
      <Options
        data-testid="button_generalData"
        id="button_generalData"
        variant="PRIMARY"
        onClick={() => nav(1)}
        active={active === 1}
      >
        <div>
          <IconPersonRed fill={active === 1 ? 'white' : '#df2c26'} />
        </div>
        {translator('Dados perfil')}
      </Options>
    );
  }, [active, nav]);

  const changePassword = useMemo(() => {
    return (
      <Options
        data-testid="button_changePassword"
        id="button_sectors"
        variant="PRIMARY"
        onClick={() => nav(2)}
        active={active === 2}
      >
        <div>
          <IconPadLockRed fill={active === 2 ? 'white' : '#df2c26'} />
        </div>
        {translator('Mudar senha')}
      </Options>
    );
  }, [active, nav]);

  /* TODO: Quando Ajustar o Botão substituir o Options pelo Botão */
  const exitAccount = useMemo(() => {
    return (
      <div style={{ marginTop: '64px' }}>
        <Options
          data-testid="button_exitAccount"
          id="button_sectors"
          variant="SECONDARY"
          onClick={() => {
            nav(3);
            navigate('/');
            makeReduxLogoutAuth().logout({});
          }}
          active={active === 3}
        >
          <div>
            <IconExitAccountBlue fill={active === 3 ? 'white' : '#0B77CD'} />
          </div>
          {translator('Sair da conta')}
        </Options>
      </div>
    );
  }, [active, nav, navigate]);

  return (
    <Container>
      <AvatarWithUploader
        data-testid="avatar"
        fullName={user?.fullname}
        src={user?.avatar}
        size="205px"
        haveUpdated={haveUpdated}
      />

      <InfoUser>
        <p data-testid="user_fullname">{user?.fullname}</p>
        <span data-testid="user_role">
          {user?.role === 'ORGANIZER'
            ? translator('Coordenador')
            : translator('Administrador')}
        </span>
      </InfoUser>

      <Actions>
        {generalData}
        {changePassword}
        {exitAccount}
      </Actions>
    </Container>
  );
};

export default PerfilNavigator;
