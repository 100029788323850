/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { FormEvent, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import Input from 'presentation/components/UI/input/index';
import { Button } from 'presentation/components/UI';
import { Icon } from 'presentation/components/icon';
import {
  IconLogo,
  IconEmail,
  ImageLogin,
  IconCheck,
} from 'presentation/base/icons';
import { makeRemoteForgotPassword } from 'main/factories/usecases/auth/ForgotFactory';
import { toast } from 'react-toastify';
import { translator } from 'presentation/components/i18n';
import {
  Container,
  Header,
  Body,
  ContentRight,
  Title,
  ContentLeft,
  ContainerRight,
  PrivacyPolice,
  Information,
  BackButton,
  SendEmail,
} from './styles/RecoveryPasswordPageStyled';

const RecoveryPassword: React.FC = (): JSX.Element => {
  const [sent, setSent] = useState(false);
  const [email, setEmail] = useState('');

  const handleSubmit = useCallback(
    (e: FormEvent): void => {
      e.preventDefault();

      makeRemoteForgotPassword()
        .forgot({
          email,
        })
        .then(res => {
          setSent(true);

          setTimeout(() => {
            setSent(false);
          }, 5000);
        })
        .catch(err => {
          if (err.name === 'NotFound') {
            toast.error('E-mail não encontrado.');
          } else {
            toast.error('Não foi possível enviar o email de recuperação.');
          }
        });
    },
    [email],
  );

  const disabled = email.length === 0 || !email.match(/^[\w-.]+@[\w-.]+$/);

  return (
    <Container>
      <Body>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ContainerRight>
            <ContentRight id="forgotAuthentication" onSubmit={handleSubmit}>
              <Header>
                <Icon src={IconLogo} width="200px" height="100px" />
              </Header>
              <Title>{translator('Recuperar senha')}</Title>

              <Information>
                {translator(
                  'Enviaremos para o seu e-mail um link para você redefinir sua senha.',
                )}
              </Information>

              <Input
                id="loginInput"
                data-testid="input_login"
                name="email"
                type="text"
                icon={IconEmail}
                label={translator('E-mail')}
                placeholder={translator('Digite seu e-mail')}
                value={email}
                onChange={(e): void => setEmail(e.target.value)}
                autoFocus
              />

              {sent && (
                <SendEmail>
                  <IconCheck id="check_icon" />
                  {
                    <h1 data-testid="confirmation_message">
                      {translator('Link enviado para o e-mail')} <b>{email}</b>
                    </h1>
                  }
                </SendEmail>
              )}

              <Button
                id="loginSubmit"
                data-testid="btn_submit"
                type="submit"
                rounded
                height="48px"
                disabled={disabled}
              >
                {translator('Enviar')}
              </Button>

              <BackButton>
                <Link to="/">{translator('Voltar')}</Link>
              </BackButton>
            </ContentRight>
          </ContainerRight>
          <PrivacyPolice>
            <p data-testid="policies">
              {`@ ${new Date().getFullYear()} Copyright | `}
              {/* <a href="/" target="_blank"> */}
              {translator('Políticas de privacidade')}
              {/* </a> */}
              {translator(' e ')}
              {/* <a href="/" target="_blank"> */}
              {translator('Termos de uso')}
              {/* </a> */}
            </p>
          </PrivacyPolice>
        </div>
        <ContentLeft>
          <ImageLogin />
        </ContentLeft>
      </Body>
    </Container>
  );
};

export default RecoveryPassword;
