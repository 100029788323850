/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { call, put } from 'redux-saga/effects';
import { iActionGetAll } from 'domain/interfaces/redux/groups/getAll';
import { GetGroups } from 'domain/usecases/groups/redux/GetGroups';
import { makeRemoteGetAllGroups } from 'main/factories/usecases/groups/GetGroupsFactory';
import {
  getAllSuccess,
  getAllFailed,
} from 'data/store/reducer/groups/actions/getAll';

export function* onGetAll(action: iActionGetAll) {
  const remoteGetAllGroups = makeRemoteGetAllGroups();

  try {
    const response: GetGroups.Model = yield call(
      remoteGetAllGroups.getAll,
      action.payload,
    );

    yield put(getAllSuccess(response));
  } catch (e) {
    yield put(getAllFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetAllSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetAllFailed() {}
