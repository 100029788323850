import React from 'react';

const IconDefaultImage = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 34 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M30.7604 1.23636C31.8668 1.23636 32.7636 2.13323 32.7636 3.23958V22.9844C32.7636 24.0908 31.8668 24.9877 30.7604 24.9877H2.00322C0.89687 24.9877 0 24.0908 0 22.9844V3.23958C0 2.13323 0.896872 1.23636 2.00322 1.23636H30.7604Z"
        fill="#BABABA"
      />
      <path
        d="M31.9968 0C33.1031 0 34 0.896872 34 2.00322V21.7481C34 22.8544 33.1031 23.7513 31.9968 23.7513H3.23958C2.13323 23.7513 1.23636 22.8544 1.23636 21.7481V2.00322C1.23636 0.896872 2.13323 0 3.23958 0H31.9968Z"
        fill="#F4F4F4"
      />
      <path
        d="M27.2506 3.14752C26.6471 3.02018 26.018 3.11372 25.4777 3.41111C24.9374 3.70851 24.5219 4.19 24.3066 4.76797C24.0914 5.34594 24.0908 5.98197 24.305 6.56033C24.5192 7.13868 24.9339 7.62093 25.4736 7.9193C26.0134 8.21768 26.6423 8.31236 27.246 8.18611C27.8497 8.05986 28.388 7.72108 28.763 7.23141C29.1379 6.74174 29.3246 6.13373 29.2891 5.51801C29.2535 4.90229 28.9981 4.3198 28.5693 3.87651C28.2118 3.50811 27.7527 3.25436 27.2506 3.14752ZM20.0882 16.6731C18.1305 13.9506 16.1721 11.2285 14.213 8.50673C13.9663 8.1639 13.456 8.16404 13.2094 8.50701L4.95794 19.9837C4.66391 20.3927 4.95617 20.9628 5.45986 20.9628H16.6914C16.8904 20.9628 17.0773 20.8669 17.1934 20.7052C18.1585 19.362 19.1242 18.0191 20.0906 16.6766L20.0882 16.6731ZM25.2074 12.5728C24.9607 12.23 24.4505 12.23 24.2039 12.5729C22.4271 15.0425 20.6509 17.5124 18.8752 19.9826C18.5813 20.3916 18.8735 20.9616 19.3772 20.9616H30.0351C30.5387 20.9616 30.831 20.3916 30.537 19.9826C28.7613 17.5124 26.9847 15.0425 25.2074 12.5728Z"
        fill="#CFCECE"
      />
    </svg>
  );
};

export default IconDefaultImage;
