import { ListResearch } from 'domain/usecases/research/redux/ListResearch';
import {
  iActionList,
  iActionListFailed,
  iActionListSuccess,
  ResearchTypes,
} from 'domain/interfaces/redux/research/list';

export const listRequest = (payload: ListResearch.Params): iActionList => ({
  type: ResearchTypes.LIST,
  payload,
});

export const listSuccess = (
  params: ListResearch.Model,
): iActionListSuccess => ({
  type: ResearchTypes.LIST_SUCCESS,
  payload: params,
});

export const listFailed = (): iActionListFailed => ({
  type: ResearchTypes.LIST_FAILED,
});
