import React from 'react';

import ImageModalMedia from 'presentation/components/imageModalMedia/ImageModalMedia';
import ModalAddingMedias from '../modalAddingMedias';
import ModalAddingParticipants from '../modalAddingParticipants';
import ModalCancelEdit from '../cancelEditModal';
import ModalViewMessage from '../modalViewMessage';

import { ownProps } from './interface';
import { ConnectComponent } from './mapper/mapperMessage';

const Notifications: React.FC<ownProps> = ({
  children,
  message,
}): JSX.Element => {
  return (
    <>
      <ImageModalMedia message={message} />
      <ModalAddingMedias message={message} />
      <ModalAddingParticipants message={message} />
      <ModalCancelEdit message={message} />
      <ModalViewMessage message={message} />
      {children}
    </>
  );
};

export default ConnectComponent(Notifications);
