/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-closing-tag-location */
import { TableSortLabel } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { ListParticipantsByOrg } from 'domain/usecases/org/remote';
import _ from 'lodash';
import { makeRemoteListParticipantsByOrg } from 'main/factories/usecases/org/ListParticipantByOrg';
import { makeRemoteGetByIdResearch } from 'main/factories/usecases/research/GetByIdResearchFactory';
import { IconSort } from 'presentation/base/icons';
import { translator } from 'presentation/components/i18n';
import Pagination from 'presentation/components/Pagination';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Container,
  IconAddedStyled,
  IconAddStyled,
  PaginationDiv,
} from './styles/StyledListParticipants';

const useStyles = makeStyles({
  table: {
    width: '100%',
    minWidth: 700,
    marginTop: '24px',
  },
  container: {
    minHeight: '500px',
    maxHeight: '550px',
    width: '100%',
    border: 'none',
    overflowY: 'auto',
  },
  headerTable: {
    padding: '1.2rem',
    border: 'none',
  },
});

type iColumns = 'icons' | 'patients' | 'city' | 'email';

export type Params = {
  researchId: string;
  groupId: string;
};

export interface RefProps {
  selectedParticipants: ListParticipantsByOrg.Model['records'][0]['user'][];
}

interface ParticipantListProps {
  setParticipantLength: Function;
}

const ParticipantList: React.ForwardRefRenderFunction<
  RefProps,
  ParticipantListProps
> = ({ setParticipantLength }, ref): JSX.Element => {
  const [participantsResponse, setParticipantsResponse] =
    useState<ListParticipantsByOrg.Model>();
  const [selectedParticipants, setSelectedParticipants] = useState<
    ListParticipantsByOrg.Model['records'][0]['user'][]
  >([]);
  const [order, setOrder] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);

  const params = useParams<Params>();

  const { search } = useLocation();

  const queryParams = useMemo(
    () => ({
      orgId: new URLSearchParams(search).get('orgId') ?? null,
    }),
    [search],
  );

  const itemsPerPage = 8;

  const classes = useStyles();
  const columns = [
    {
      id: 'icons',
      label: '',
      minWidth: 40,
      align: 'left',
      fontSize: '1.4rem',
    },
    {
      id: 'patients',
      label: translator('Pacientes'),
      minWidth: 100,
      align: 'left',
      fontSize: '1.4rem',
    },
    {
      id: 'city',
      label: translator('Cidade'),
      minWidth: 100,
      align: 'left',
      fontSize: '1.4rem',
    },
    {
      id: 'email',
      label: translator('E-mail'),
      minWidth: 100,
      align: 'left',
      fontSize: '1.4rem',
    },
  ];

  const handleAddParticipants = (
    items: ListParticipantsByOrg.Model['records'][0],
  ) => {
    setSelectedParticipants(prevState => [...prevState, items.user]);
    setParticipantLength((prevState: number) => prevState + 1);
  };

  const handleRemoveParticipants = (participantId: number) => {
    setSelectedParticipants(prevState =>
      prevState.filter(participant => participant.id !== participantId),
    );
    setParticipantLength((prevState: number) => prevState - 1);
  };

  useImperativeHandle(ref, () => ({
    selectedParticipants,
  }));

  useEffect(() => {
    if (queryParams.orgId)
      makeRemoteListParticipantsByOrg()
        .list({
          query: {
            org: Number(queryParams.orgId),
            limit: 9999,
          },
        })
        .then(response => {
          setParticipantsResponse(response);
          setTotalPages(response.metadata.total);
        })
        .catch(() => {
          toast.error(translator('Erro ao buscar pacientes!'));
        });
  }, [params.researchId, queryParams.orgId]);

  const RenderComponents: React.FC<{
    id: iColumns;
    items: ListParticipantsByOrg.Model['records'][0];
  }> = ({ id, items }) => {
    const findIndex = selectedParticipants.findIndex(
      participant => participant.id === items.user.id,
    );

    switch (id) {
      case 'icons':
        if (findIndex === -1) <div />;

        if (findIndex >= 0) {
          return (
            <IconAddedStyled
              data-testid={`icon-added-${items.user.id}`}
              onClick={() => handleRemoveParticipants(items.user.id)}
            />
          );
        }
        return (
          <IconAddStyled
            data-testid={`icon-add-${items.user.id}`}
            onClick={() => handleAddParticipants(items)}
          />
        );
      case 'patients':
        return (
          <span
            data-testid={`patient-name-${items.user.id}`}
          >{`${items?.user.firstName} ${items?.user.lastName}`}</span>
        );
      case 'city':
        return (
          <span data-testid={`patient-city-${items.user.id}`}>{`${
            items?.user.city ?? '-'
          }`}</span>
        );
      case 'email':
        return (
          <span
            data-testid={`patient-email-${items.user.id}`}
          >{`${items?.user.email}`}</span>
        );
      default:
        return <div />;
    }
  };

  const displayData = useMemo(() => {
    const orderedRecords = _.orderBy(
      participantsResponse?.records,
      item => item.user?.firstName.toLowerCase(),
      ['asc'],
    );

    const start = (page - 1) * itemsPerPage;

    return {
      ...participantsResponse,
      records: orderedRecords?.slice(start, start + itemsPerPage),
    };
  }, [participantsResponse, page]);

  const unselectedDisplayData = displayData?.records?.filter(
    item => !item.wasSelected,
  );

  return (
    <Container>
      <TableContainer component={Paper} className={classes.container}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              {columns.map(item => {
                return (
                  <TableCell
                    key={`tableCell_${item.id}`}
                    align="left"
                    className={classes.headerTable}
                  >
                    {unselectedDisplayData ? (
                      <TableSortLabel
                        active
                        IconComponent={() => (
                          <div style={{ paddingLeft: 5 }}>
                            {item.label !== '' && (
                              <IconSort id={`sort_${item.id}`} />
                            )}
                          </div>
                        )}
                        style={{
                          color: '#8A8A8A',
                          fontWeight: 'normal',
                          fontSize: '1.4rem',
                        }}
                        onClick={() => {
                          setOrder(prevState => !prevState);
                        }}
                      >
                        {item.label}
                      </TableSortLabel>
                    ) : (
                      <div
                        style={{
                          color: '#8A8A8A',
                          fontWeight: 'normal',
                          fontSize: '1.4rem',
                        }}
                      >
                        {item.label}
                      </div>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {unselectedDisplayData?.map(item => (
              <TableRow
                key={`tableRow_${item.user.firstName + item.user.lastName}`}
              >
                {columns.map((columnProps: any) => {
                  return (
                    <TableCell
                      key={`tableCellColumn_${item.user.id}_${columnProps.id}`}
                      align={columnProps.align}
                      style={{
                        maxWidth: columnProps.maxWidth,
                        minWidth: columnProps.minWidth,
                        fontSize: columnProps.fontSize,
                      }}
                    >
                      <RenderComponents
                        key={`component_${item.user.id}_${columnProps.id}`}
                        id={columnProps.id}
                        items={item}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationDiv>
        <span data-testid="total_pagination">
          {`Mostrando ${
            totalPages > unselectedDisplayData?.length
              ? unselectedDisplayData?.length
              : totalPages
          } de ${unselectedDisplayData?.length}`}
        </span>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '70%',
          }}
        >
          <Pagination
            currentPage={page}
            setPage={setPage}
            pageSize={
              participantsResponse?.records?.length
                ? Math.ceil(
                    participantsResponse?.records?.length / itemsPerPage,
                  )
                : 0
            }
          />
        </div>
      </PaginationDiv>
    </Container>
  );
};

export default forwardRef(ParticipantList);
