import styled from 'styled-components';

interface expandedProps {
  isExpanded?: boolean;
  index?: number;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 16px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  .css-17mg6aq:last-of-type {
    border: none;
  }

  .chakra-accordion__button css-1ygl3x {
    background: red;
  }

  .MuiPaper-elevation1 {
    box-shadow: none;
  }

  .MuiTableContainer-root {
    padding: 10px 10px 0px 50px;
  }

  .MuiTableCell-sizeSmall {
    padding: 10px;
  }

  .css-1ygl3x:hover {
    background-color: transparent;
  }

  @media (max-width: 1366px) {
    max-height: 50rem;
  }

  @media (max-width: 1366px) and (max-height: 720px) {
    max-height: 43rem;
    overflow-y: auto;
  }
`;

export const Groups = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ContainerAccordion = styled.div`
  padding: 10px 15px 10px 20px;
  border: none;
`;

export const ContentLabelAccordion = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #7a7a7a;

  :hover {
    .icon_research {
      path {
        stroke: #0b77cd;
      }
    }
    color: #444a51;

    svg {
      color: red;
    }
  }
`;

export const LabelAccordion = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;
  font-size: 16px;
  text-align: left;
`;

export const ExpandedContainer = styled.div<expandedProps>`
  border: 1px solid #efefef;
  border-radius: 5px 5px 0px 0px;
`;
