import { IconMessage } from 'presentation/base/icons';
import { Header, HeaderSimples } from 'presentation/components/Header';
import React from 'react';
import ListMessagePage from '../ListMessagePage';
import {
  Body,
  Container,
  ContentHeaderSimples,
} from './styles/StyledListMessage';

const ListMessageLandingPage: React.FC = (): JSX.Element => {
  return (
    <Container>
      <Header />

      <ContentHeaderSimples>
        <HeaderSimples title="Mensagens" Icon={IconMessage} />
      </ContentHeaderSimples>
      <Body>
        <ListMessagePage />
      </Body>
    </Container>
  );
};

export default ListMessageLandingPage;
