import { AuthTypes, iActionLogin } from 'domain/interfaces/redux/auth/login';
import { iActionUpdate } from 'domain/interfaces/redux/auth/update';
import { LoginAuth } from 'domain/usecases/auth/redux/LoginAuth';
import { UpdateAuth } from 'domain/usecases/auth/redux/updateAuth';

export const updateRequest = (
  payload: Partial<LoginAuth.Model['user']>,
): iActionUpdate => ({
  type: AuthTypes.UPDATE_USER,
  payload,
});
