/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/jsx-one-expression-per-line */
import { iMessage } from 'domain/interfaces/models';
import { MessageOptions } from 'domain/interfaces/redux/message';
import React, { useMemo, useRef, useState } from 'react';
import { Box, Textarea } from '@chakra-ui/react';
import { useLocation, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { IconCalendar, IconClose, IconGroups } from 'presentation/base/icons';
import { closeModal } from 'utils';
import { translator } from '../i18n';
import {
  Container,
  ContainerModal,
  ContentMessageDiv,
  Header,
  InfoDiv,
} from './styles/StyledModalViewMessage';

interface ModalViewMessageProps {
  message: iMessage;
}

const ModalViewMessage: React.FC<ModalViewMessageProps> = ({ message }) => {
  const theme = useTheme();

  const msgName = MessageOptions.viewMessageModal;

  const {
    active,
    actionOk,
    actionCancel,
    viewMessageName,
    viewMessageGroupsNames,
    viewMessageDate,
    viewMessageContent,
  } = message;

  return (
    <>
      {msgName === active && (
        <ContainerModal>
          <Container>
            <Header>
              <div />
              <span data-testid="groupName">
                {viewMessageName ?? translator('Nome do grupo')}
              </span>
              <div>
                <IconClose
                  onClick={() => closeModal()}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            </Header>
            <InfoDiv>
              <div className="horizontal-align" data-testid="groups">
                <IconGroups width="23px" height="25px" />
                <span>{viewMessageGroupsNames ?? translator('Grupos')}</span>
              </div>
              <div className="horizontal-align" data-testid="sentDate">
                <IconCalendar width="23px" height="22px" />
                <span>{viewMessageDate ?? translator('Enviado em')}</span>
              </div>
            </InfoDiv>
            <ContentMessageDiv>
              <span data-testid="messageText">
                {translator('Texto da mensagem')}
              </span>
              <Box
                width="100%"
                height="200px"
                borderWidth="1px"
                borderRadius="lg"
                overflow="hidden"
              >
                <Textarea
                  data-testid="messageContent"
                  value={viewMessageContent ?? ''}
                  disabled
                  fontSize="16px"
                  placeholder="..."
                  height="299px"
                  borderWidth="1px"
                  resize="none"
                />
              </Box>
            </ContentMessageDiv>
          </Container>
        </ContainerModal>
      )}
    </>
  );
};

export default ModalViewMessage;
