import styled from 'styled-components';

export const ContainerListMessage = styled.div`
  margin-top: 2.4rem;
  width: 100%;
  padding: 0 7.2rem 0 7.2rem;
`;

export const SearchContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 4rem;
`;

export const ListMessageDiv = styled.div`
  width: 100%;
  margin-top: 2rem;
`;

export const BoxSpace = styled.div`
  padding: 0 7.2rem 0 7.2rem;
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1.6rem;
  margin-top: 1.6rem;
  margin-bottom: 4rem;
  border-radius: 0.5rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.background.grey};
  font: ${({ theme }) => theme.typography.h5};
  color: ${({ theme }) => theme.colors.typography.gray};
`;
