/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/require-default-props */
import React from 'react';
import { IconPoweredWisecareLaoha } from 'presentation/base/icons';
import { Tooltip } from '@material-ui/core';
import { translator } from 'presentation/components/i18n';
import {
  Container,
  Version,
  PoweredBy,
  Content,
  TooltipTitle,
} from './styles/StyledFooter';

interface iFooter {
  isPadding?: boolean;
}

const Footer: React.FC<iFooter> = ({ isPadding }) => {
  return (
    <Container isPadding={isPadding}>
      <Content>
        <PoweredBy>
          <IconPoweredWisecareLaoha />
        </PoweredBy>
        <Tooltip
          title={
            <TooltipTitle>
              {translator('Clique para ver as notas da release')}
            </TooltipTitle>
          }
        >
          <Version
            href="https://docs.google.com/document/d/1wzKtw1edIJdBs-1gR-rtJbSgLPYjeQg1wislJZ-FOwg"
            target="_blank"
          >
            v0.16.0R
          </Version>
        </Tooltip>
      </Content>
    </Container>
  );
};

export default Footer;
