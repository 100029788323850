import styled, { css } from 'styled-components';

interface tagProps {
  active: boolean;
}

export const Container = styled.header`
  width: 100%;
  color: ${({ theme }) => theme.colors.background.main};
  background: ${({ theme }) => theme.colors.background.main};
  height: 6rem;
  top: 0;
  z-index: 2;
`;

export const Content = styled.div`
  width: 100%;
  max-height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DivLogo = styled.div`
  display: flex;
  align-items: center;
  margin-left: 3.2rem;
`;

export const NavigationDiv = styled.div`
  display: flex;
  margin-left: 3.2rem;
  gap: 4.2rem;
`;

export const AvatarDiv = styled.div`
  display: flex;
  width: 100%;
  margin-right: 7.2rem;
`;

export const Options = styled.div`
  display: flex;
  gap: 4.2rem;
  color: ${({ theme }) => theme.colors.typography.darkGray};
  align-items: center;
  font-family: Roboto;
  cursor: pointer;

  @media (max-width: 1366px) {
    gap: 3rem;
  }
`;

export const Menu = styled.div<tagProps>`
  color: ${({ active, theme }) =>
    active ? theme.colors.background.main : theme.colors.typography.darkGray};
  background: ${({ active, theme }) => active && theme.colors.primary.main};
  border-radius: ${({ active }) => active && '4px'};
  font-size: 1.6rem;
  font-weight: ${({ active }) => active && '600'};
  padding: 6px 1rem 6px 1rem;
  white-space: nowrap;
  :hover {
    color: ${({ active, theme }) => !active && theme.colors.primary.main};
  }
`;

export const UserOptions = styled.div`
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 200px;
  button {
    width: 100%;
    border: none;
    font-size: 16px;
  }

  span {
    color: #df2c26;
    font-family: 'Roboto';
    font-weight: bold;
  }
`;

export const Drop = styled.div`
  display: flex;
  font-size: 18px;
  align-items: center;
  justify-content: flex-start;
  padding: 13px 15px;
  color: black;
  background: white;
  cursor: pointer;
  border: none;

  span {
    margin-left: 15px;
  }

  :hover {
    background: rgba(226, 6, 19, 0.1);
  }
`;
