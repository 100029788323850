import styled from 'styled-components';

export const ContainerNotes = styled.div`
  width: 100%;
  height: 100%;

  h5 {
    font-size: 14px;
    color: #09090a;
  }
`;

export const ContentNotes = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .eye-icon {
    margin-right: 3px;
  }
  p {
    display: flex;
    align-items: center;
  }
`;

export const MessageSubject = styled.div`
  font-weight: bold;
  font-size: 16px;

  color: #444a51;

  margin-bottom: 8px;
`;

export const MessageContent = styled.div`
  font-weight: bold;
  font-size: 18px;

  color: #444a51;

  margin-top: 10px;
  margin-bottom: 8px;
`;
