export default {
  'Bem-vindo(a)!': '¡Bienvenido!',
  'Digite o email cadastrado': 'Ingrese su correo registrado',
  'E-mail': 'Correo electrónico',
  Senha: 'Contraseña',
  Entrar: 'Iniciar sesión',
  'Digite sua senha': 'Ingrese su contraseña',
  'Esqueci minha senha': 'Olvidé mi contraseña',
  'Configurações de senha': 'Configuración de contraseña',
  'Políticas de privacidade': 'Políticas de privacidad',
  'Defina sua senha para acessar a plataforma.':
    'Establece tu contraseña de acceso a la plataforma.',
  ' e ': ' y ',
  'Termos de uso': 'Términos de uso',
  Imagenes: 'Imágenes',
  Vídeos: 'Vídeos',
  Arquivos: 'Archivos',
  'Em desenvolvimento': 'En desarrollo',
  'Nova Imagem': 'Nueva Imagen',
  'Novo Vídeo': 'Nuevo Vídeo',
  'Novo Arquivo': 'Nuevo Archivo',
  Pacientes: 'Pacientes',
  Mídia: 'Medios de comunicación',
  Mídias: 'Medios de comunicación',
  'Excluir imagem': 'Borrar imagen',
  'Substituir imagem': 'Reemplazar imagen',
  'Baixar imagem': 'Descargar imagen',
  'Excluir paciente': 'Borrar paciente',
  'Excluir grupo': 'Borrar grupo',
  'Editar grupo': 'Editar grupo',
  'Mídia eliminada com sucesso!': '¡Medios eliminados con éxito!',
  'Este arquivo pode estar sendo usado em alguma Sala de espera.':
    'Este archivo puede ser usado en alguna Sala de Espera.',
  'Excluir Arquivo': 'Borrar Archivo',
  'Tem certeza que desea excluí-lo?': '¿Está seguro de que desea eliminarlo?',
  'Sim, eliminar': 'Sí, eliminar',
  'Não eliminar': 'No eliminar',
  'Visualizar Mídia': 'Ver Medios',
  'Editar Medios': 'Editar Medios',
  'Baixar Imagem': 'Descargar Imagen',
  Excluir: 'Borrar',
  'Abrir enlace em uma nova aba': 'Abrir enlace en una pestaña nueva',
  'Visualizar PDF': 'Ver PDF',
  'Baixar PDF': 'Descargar PDF',
  'Informações gerais': 'Datos generales',
  'Instituição da pesquisa': 'Institución de investigación',
  'Selectione uma instituição': 'Seleccione una institución',
  'Título da pesquisa': 'Título de la investigación',
  'Digite o título da pesquisa': 'Ingrese el título de la investigación',
  'Descrição da pesquisa': 'Descripción de la investigación',
  'Digite a descrição': 'Ingrese la descripción',
  'Preencha todos los campos corretamente':
    'Rellene todos los campos correctamente',
  'Cadastro de coordenadores': 'Registro de coordinadores',
  'Para cadastrar os coordenadores que farão parte desta pesquisa, preencha os campos abaixo e depois clique em “Cadastrar Coordenador”;':
    'Para registrar a los coordinadores que formarán parte de esta investigación, complete los campos a continuación y luego haga clic en " Registrar Coordinador";',
  'Nome completo': 'Nombre completo',
  'Digite o nome completo': 'Ingrese el nombre completo',
  'Digite o e-mail': 'Ingrese el correo electrónico',
  'Cadastrar coordenador': 'Registrar coordinador',
  'Coordenadores cadastrados na pesquisa:':
    'Coordinadores registrados en la investigación:',
  'Você ainda não possui coordenadores cadastrados.':
    'Aún no tienes coordinadores registrados.',
  'Cadastro de grupos': 'Registro de grupos',
  'Digite o nome do grupo que você deseja incluir na pesquisa e depois clique em “Cadastrar grupo”.':
    'Ingrese el nombre del grupo que desea incluir en la investigación y luego haga clic en "Registrar grupo".',
  'Nome do grupo': 'Nombre del grupo',
  'Digite o nome do grupo': 'Ingrese el nombre del grupo',
  'Actualizar grupo': 'Actualizar grupo',
  'Cadastrar grupo': 'Registrar grupo',
  'Grupos catastrados:': 'Grupos registrados:',
  'Você ainda não possui grupos catastrados.':
    'Aún no tienes grupos registrados.',
  'A pesquisa deve possuir pelo menos um coordenador':
    'La investigación debe tener al menos un coordinador',
  'A pesquisa deve possuir pelo menos um grupo':
    'La investigación debe tener al menos un grupo',
  'Pesquisa atualizada com sucesso': 'Investigación actualizada con éxito',
  'Erro ao actualizar pesquisa': 'Error al actualizar la investigación',
  'Configurações de pesquisa': 'Configuraciones de búsqueda',
  Pesquisas: 'Investigaciones',
  Informações: 'Información',
  Coordinadores: 'Coordinadores',
  Grupos: 'Grupos',
  Cancelar: 'Cancelar',
  Salvar: 'Guardar',
  Selección: 'Seleccionar',
  'Actualizar coordinador': 'Actualizar coordinador',
  'Seleção de Pacientes': 'Selección de Pacientes',
  'Voltar para a lista': 'Volver a la lista',
  'Inserir pacientes': 'Insertar pacientes',
  'Usuário actualizado con éxito!': '¡Usuario actualizado con éxito!',
  'Erro ao actualizar el usuario!': '¡Error al actualizar el usuario!',
  'Mudar senha': 'Cambiar contraseña',
  'Nova senha': 'Nueva contraseña',
  'Digite nova senha': 'Ingrese nueva contraseña',
  'Repita a nova senha': 'Repita la nueva contraseña',
  'Digite novamente a nova senha': 'Ingrese la nueva contraseña nuevamente',
  'Regras para a nova senha:': 'Reglas para la nueva contraseña:',
  '- Mínimo de 6 dígitos': '- Mínimo de 6 dígitos',
  '- Possuir letras e números': '- Tener letras y números',
  '- Ser diferente de seus dados pessoais':
    '- Ser diferente de sus datos personales',
  'Salvar Alterações': 'Guardar Cambios',
  Imagem: 'Imagen',
  Nombre: 'Nombre',
  Cidade: 'Ciudad',
  Contato: 'Contacto',
  Ações: 'Acciones',
  'Remover paciente': 'Quitar paciente',
  'Tem certeza que desea remover paciente este do grupo?':
    '¿Está seguro de que desea eliminar a este paciente del grupo?',
  'Sim, eliminador': 'Sí, eliminar',
  'Não remover': 'No quitar',
  'Erro ao buscar pacientes!': '¡Error al buscar pacientes!',
  'Recuperar senha': 'Recuperar contraseña',
  'Enviaremos para o seu e-mail um link para você redefinir sua senha.':
    'Le enviaremos a su correo electrónico un enlace para que restablezca su contraseña.',
  'Enlace enviado para o correo electrónico':
    'Enlace enviado al correo electrónico',
  Enviar: 'Enviar',
  'Dados perfil': 'Datos del perfil',
  'Sair da conta': 'Cerrar sesión',
  Coordinador: 'Coordinador',
  Administrador: 'Administrador',
  'Clique para ver as notas da release':
    'Click para ver las notas de la versión',
  'Senha alterada com sucesso!': '¡Contraseña cambiada con éxito!',
  'Erro ao alterar senha!': '¡Error al cambiar la contraseña!',
  'Confimar senha': 'Confirmar contraseña',
  '¡Enlace copiado!': '¡Enlace copiado!',
  'Não foi possível actualizar mídias': 'No se pueden actualizar los medios',
  'Você já possui 3 mídias fixas': 'Ya tienes 3 medios fijos',
  'Não foi possível buscar grupo': 'No se pudo buscar grupo',
  'Não foi possível buscar mídias': 'No se pueden buscar medios',
  'Mídias do grupo': 'Medios del grupo',
  Fixada: 'Fijado',
  'Fixar mídia': 'Fijar medio',
  'Nova Pesquisa': 'Nuevas Investigaciones',
  'Participantes adicionados com sucesso!':
    '¡Participantes agregados con éxito!',
  'Erro ao adicionar participantes!': '¡Error al agregar participantes!',
  'Selectione os pacientes do grupo': 'Seleccionar los pacientes del grupo',
  selecionados: 'seleccionado',
  'Você tem certeza que deseja cancelar a edição?':
    '¿Está seguro de que desea cancelar la edición?',
  'Cancelar edición': 'Cancelar edición',
  'Deseja realmente cancelar?': '¿Realmente quieres cancelar?',
  'Sim, cancelar': 'Sí, cancelar',
  'Não cancelar': 'No cancelar',
  'Mídia atualizada com sucesso!': '¡Medios actualizados con éxito!',
  'Erro ao actualizar mídia!': '¡Error al actualizar los medios!',
  'Mídia criada com sucesso!': '¡Medio creado con éxito!',
  'Falha ao criar mídia': 'Error al crear medios',
  'Erro ao buscar mídia': 'Error al buscar medios',
  'Actualizar Imagen': 'Actualizar Imagen',
  'Agregar Imagen': 'Agregar Imagen',
  'Actualizar Vídeo': 'Actualizar Vídeo',
  'Agregar Vídeo': 'Añadir Vídeo',
  'Actualizar PDF': 'Actualizar PDF',
  'Agregar PDF': 'Agregar PDF',
  'Insira o link do video': 'Inserta el enlace del video',
  'Digite aqui a legenda que será apresentada na sala.':
    'Escriba aquí el pie de foto que se presentará en la sala.',
  'Digite aqui o nome desta imagem': 'Escriba aquí el nombre de esta imagen',
  'Digite aqui o nome do arquivo': 'Escriba aquí el nombre del archivo',
  'Selecione as instituições que podem visualizar está mídia:':
    'Seleccione las instituciones que pueden ver este medio:',
  'Todas as instituições': 'Todas las instituciones',
  Confirmar: 'Confirmar',
  'Erro ao buscar pesquisas!': '¡Error al buscar pesquisas!',
  pacientes: 'pacientes',
  mídias: 'medios',
  'Dados pessoais': 'Datos personales',
  'Meu perfil': 'Mi perfil',
  'Biblioteca de Mídias': 'Mediateca',
  'Buscar pesquisa...': 'Buscar investigación...',
  Sair: 'Salir',
  Voltar: 'Volver',
  'Digite seu e-mail': 'Escribe tu correo electrónico',
  Imagens: 'Imágenes',
  'Grupo de Controle': 'Grupo de Control',
  Nome: 'Nombre',
  Coordenadores: 'Coordinadores',
  Revisão: 'Revisión',
  Selecione: 'Seleccione',
  'Grupos cadastrados:': 'Grupos registrados:',
  'Você ainda não possui grupo cadastrados.':
    'Aún no tienes grupos registrados.',
  Editar: 'Editar',
  'Instituição:': 'Institución:',
  'Pesquisa:': 'Investigación:',
  'Descrição:': 'Descripción:',
  Anterior: 'Anterior',
  Concluir: 'Concluir',
  Próximo: 'Próximo',
  'Clique para escolher um arquivo': 'Haga clic para elegir un archivo',
  'ou arraste-o aqui.': 'o arrástrelo aquí.',
  'Insira o link do vídeo': 'Inserta el enlace del video',
  'Atualizar Imagem': 'Actualizar Imagen',
  'Adicionar Imagem': 'Agregar Imagen',
  'Atualizar Vídeo': 'Actualizar Vídeo',
  'Adicionar Vídeo': 'Añadir Vídeo',
  'Atualizar PDF': 'Actualizar PDF',
  'Adicionar PDF': 'Agregar PDF',
  'Editar Mídia': 'Editar Medio',
  'Abrir link em uma nova aba': 'Abrir enlace en una nueva pestaña',
  'Copiar link': 'Copiar enlace',
  'E-mail de recuperação enviado com sucesso!':
    '¡Correo electrónico de recuperación enviado con éxito!',
  'Login realizado com sucesso!': '¡Inicio de sesión realizado con éxito!',
  'Usuário ou senha inválidos': 'Usuario o contraseña inválidos',
  'Erro inesperado': 'Error inesperado',
  'Credênciais inválidas.': 'Credenciales inválidas.',
  'Falha interna no servidor, tente novamente em minutos.':
    'Error interno del servidor, inténtelo de nuevo en minutos.',
  'Falha ao deletar mídia!': '¡Error al eliminar medios!',
  'Pesquisa criada com sucesso': 'Investigación creada con éxito',
  'Já existe uma pesquisa cadastrada com essas informações.':
    'Ya existe una investigación registrada con esta información.',
  'Não foi possível criar a pesquisa': 'No se pudo crear la investigación',
  'Preencha todos os campos corretamente':
    'Rellene todos los campos correctamente',
  'Usuário atualizado com sucesso!': '¡Usuario actualizado con éxito!',
  'Erro ao atualizar usuário!': '¡Error al actualizar el usuario!',
  'Paciente removido com sucesso!': '¡Paciente eliminado con éxito!',
  'Erro ao remover paciente!': '¡Error al eliminar paciente!',
  'Erro ao atualizar mídia!': '¡Error al actualizar los medios!',
  'Imagem atualizada com sucesso!': '¡Imagen actualizada con éxito!',
  'Erro ao atualizar imagem!': '¡Error al actualizar la imagen!',
  'Não foi possível atualizar mídias': 'No se pudo actualizar los medios',
  'Senhas não coincidem': 'Las contraseñas no coinciden',
  'Email inválido!': '¡Correo electrónico inválido!',
  'Preencha o email!': '¡Rellene el correo electrónico!',
  'Preencha a senha!': '¡Rellene la contraseña!',
  'Preencha o nome!': '¡Rellene el nombre!',
  'Preencha a idade!': '¡Rellene la edad!',
  'Idade inválida!': '¡Edad inválida!',
  'Selecione um sexo!': '¡Seleccione un sexo!',
  'Senha Incorreta': 'Contraseña incorrecta',
  'Email não cadastrado': 'Correo electrónico no registrado',
  'Cadastro não localizado': 'Registro no encontrado',
  'Email inválido.': 'Correo electrónico inválido.',
  'Senha inválida': 'Contraseña inválida',
  'A senha é obrigatória': 'La contraseña es obligatoria',
  'A senha deve ter no mínimo 6 caracteres':
    'La contraseña debe tener al menos 6 caracteres',
  'A senha deve conter pelo menos um número e uma letra maiúscula':
    'La contraseña debe contener al menos un número y una letra mayúscula',
  'A confirmação de senha é obrigatória':
    'La confirmación de la contraseña es obligatoria',
  'As senhas não conferem': 'Las contraseñas no coinciden',
  'O nome excedeu o limite de 1024 caracteres':
    'El nombre excedió el límite de 1024 caracteres',
  'O nome é obrigatório': 'El nombre es obligatorio',
  'A descrição é obrigatória': 'La descripción es obligatoria',
  'A URL não pode ter mais de 1024 caracteres':
    'La URL no puede tener más de 1024 caracteres',
  'A URL deve ser válida': 'La URL debe ser válida',
  'A URL excedeu o limite de 1024 caracteres':
    'La URL excedió el límite de 1024 caracteres',
  'A URL é obrigatória': 'La URL es obligatoria',
  'O arquivo é obrigatório': 'El archivo es obligatorio',
  'É necessário adicionar um arquivo': 'Es necesario agregar un archivo',
  'O nome da organização é obrigatório':
    'El nombre de la organización es obligatorio',
  'O título da pesquisa é obrigatório':
    'El título de la investigación es obligatorio',
  'A descrição da pesquisa é obrigatória':
    'La descripción de la investigación es obligatoria',
  'O nome do grupo é obrigatório': 'El nombre del grupo es obligatorio',
  'O nome do responsável é obrigatório':
    'El nombre del responsable es obligatorio',
  'O e-mail do responsável é obrigatório':
    'El correo electrónico del responsable es obligatorio',
  Organizações: 'Organizaciones',
  'Seleção de Organizações': 'Selección de organizaciones',
  'Adicione as organizações abaixo que fazem parte da LAOHA.':
    'Agregue las organizaciones a continuación que forman parte de LAOHA.',
  'Não foi possível carregar as organizações':
    'No se pudo cargar las organizaciones',
  'Nome da organização': 'Nombre de la organización',
  Sigla: 'Sigla',
  'Data de criação': 'Fecha de creación',
  'Organização removida com sucesso!': '¡Organización eliminada con éxito!',
  'Erro ao remover organização.': 'Error al eliminar la organización.',
  'Organização criada com sucesso!': '¡Organización creada con éxito!',
  'Erro ao criar organização.': 'Error al crear la organización.',
  Adicionar: 'Añadir',
  Remover: 'Eliminar',
  Adicionado: 'Añadido',
  'Sim, excluir': 'Sí, eliminar',
  'Sim, remover': 'Sí, eliminar',
  'Mídia deletada com sucesso!': '¡Medios eliminados con éxito!',
  'Tem certeza que deseja excluí-lo?':
    '¿Estás seguro de que quieres eliminarlo?',
  'Não excluir': 'No excluyas',
  'Selecione uma instituição': 'Seleccione una institución',
  'Atualizar grupo': 'Grupo de actualización',
  'Você ainda não possui grupos cadastrados.':
    'Todavía no tienes grupos registrados.',
  'Erro ao atualizar pesquisa': 'Investigación de actualización de errores',
  'Atualizar coordenador': 'Coordinador de actualización',
  'Tem certeza que deseja remover este paciente do grupo?':
    '¿Estás seguro de que quieres eliminar a este paciente del grupo?',
  'Link enviado para o e-mail': 'Enlace enviado al correo electrónico',
  Coordenador: 'Coordinador',
  'Link copiado!': 'Enlace copiado!',
  'Selecione os pacientes do grupo': 'Seleccione los pacientes del grupo',
  'Cancelar edição': 'Cancelar edición',
  Perfil: 'Perfil',
  '1. Contexto': '1. Contexto',
  Pesquisa: 'Búsqueda',
  '2. Envio': 'Envío',
  'Tipo de envio': 'Tipo de envío',
  Imediato: 'Inmediato',
  Programado: 'Programado',
  'Única vez': 'Una vez',
  Recorrente: 'Recurrente',
  'Enviar em': 'Enviar en',
  'Iniciar em': 'Empezar en',
  Horário: 'Programar',
  'Repetir em': 'Repetir en',
  'Finalizar em': 'Finalizar en',
  '3. Mensagem': '3. Mensaje',
  'Enviar por': 'Enviar por',
  Título: 'Título',
  Conteúdo: 'Contenido',
  'Digite o título': 'Ingrese el título',
  'Escreva o conteúdo da mensagem e insira as mídias desejadas':
    'Escriba el contenido del mensaje e inserte los medios deseados',
  'Enviar nova mensagem': 'Envíe un nuevo mensaje',
  'Enviar mensagem': 'Enviar mensaje',
  'Nova mensagem': 'Nuevo mensaje',
  Mensagens: 'Mensajes',
  'Canal de envio': 'Canal de envío',
  'Data de envio': 'Fecha de envío',
  'Buscar paciente...': 'Buscar paciente...',
  'Buscar mensagem...': 'Buscar mensaje...',
  'Buscar organização...': 'Buscar organización...',
  'Buscar mídia...': 'Buscar medios...',
};
