import { ListOrgsAtendimento } from 'domain/usecases/org/remote';
import { makeRemoteListOrgsAtendimento } from 'main/factories/usecases/org/ListOrgsAtendimentoFactory';
import { IconPlus } from 'presentation/base/icons';
import {
  iListMessageResponse,
  iListOrganizations,
} from 'presentation/components/Lists/interface';
import ListOrganization from 'presentation/components/Lists/ListOrganizations/ListOrganization';
import ListMessage from 'presentation/components/Lists/ListMessage/ListMessage';
import SearchListBroadcastGroup from 'presentation/components/search-list/SearchListBroadcastGroups';
import { Button } from 'presentation/components/UI';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';
import Translator from 'presentation/components/i18n/Translator';
import {
  ContainerListMessage,
  ListMessageDiv,
  SearchContent,
  Box,
} from './styles/StyledListOrganizationPage';

const ListOrganizationPage: React.FC = (): JSX.Element => {
  const theme = useTheme();

  const [orgs, setOrgs] = useState<ListOrgsAtendimento.Model['records']>([]);
  const [haveUpdated, setHaveUpdated] = useState<boolean>(false);

  useEffect(() => {
    makeRemoteListOrgsAtendimento()
      .list({})
      .then(res => {
        setOrgs(res.records);
      })
      .catch(err => {
        toast.error(Translator('Não foi possível carregar as organizações'));
      });
  }, [haveUpdated]);

  return (
    <ContainerListMessage>
      <Box>
        {Translator(
          'Adicione as organizações abaixo que fazem parte da LAOHA.',
        )}
      </Box>
      <SearchContent>
        <SearchListBroadcastGroup
          placeholder={Translator('Buscar organização...')}
        >
          <h4>Em desenvolvimento</h4>
        </SearchListBroadcastGroup>
        <div />
      </SearchContent>
      <ListMessageDiv>
        <ListOrganization
          dataList={orgs}
          haveUpdated={() => setHaveUpdated(prev => !prev)}
        />
      </ListMessageDiv>
    </ContainerListMessage>
  );
};

export default ListOrganizationPage;
