/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
import { padWithZeroes } from './padwithzeros';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function formattedDate(date: Date, duration?: number): string {
  const dateWithoutTimezone = getDateWithoutTimezone(date);
  const roundedTime = roundTime(
    dateWithoutTimezone.getHours(),
    dateWithoutTimezone.getMinutes(),
  );

  const formattedTime = `${roundedTime.split(':')[0]}h${
    roundedTime.split(':')[1]
  }`;

  const day = padWithZeroes(
    Number(
      dateWithoutTimezone.toLocaleDateString('pt-BR', {
        day: '2-digit',
      }),
    ),
    2,
  );

  const month = padWithZeroes(
    Number(
      dateWithoutTimezone.toLocaleDateString('pt-BR', {
        month: 'numeric',
      }),
    ),
    2,
  );

  const year = dateWithoutTimezone.toLocaleDateString('pt-BR', {
    year: 'numeric',
  });

  console.log('dateWithoutTimezone', dateWithoutTimezone);

  const newStringDate = `${day}/${month}/${year}`;

  return newStringDate;
}

export function formatToIso(
  year: string,
  month: string,
  day: string,
  time: string,
  timezone?: string,
): string {
  return `${year}-${padWithZeroes(Number(month), 2)}-${padWithZeroes(
    Number(day),
    2,
  )}T${time}:00${timezone ?? ''}`;
}

export function addMinutesToDate(date: Date, minutes: number): Date {
  return new Date(date.getTime() + minutes * 60000);
}

export function addPadWithZeroes(value: number): string {
  return String(value).padStart(2, '0');
}

export function roundTime(hours: number, minutes: number): string {
  const roundMinutes = (parseInt(String((minutes + 7.5) / 15), 10) * 15) % 60;
  const roundHours = minutes > 52 ? (hours === 23 ? 0 : ++hours) : hours;

  return `${addPadWithZeroes(roundHours)}:${addPadWithZeroes(roundMinutes)}`;
}

export function getDateWithoutTimezone(value: Date): Date {
  const formatted = new Date(value);
  formatted.setMinutes(formatted.getMinutes() + formatted.getTimezoneOffset());

  return formatted;
}

export function incrementMinutesOnTimeString(
  time: string,
  duration: number,
): string {
  const [hours, minutes] = time.split(':').map(Number);
  const newMinutes = minutes + duration;
  const newHours = hours + Math.floor(newMinutes / 60);
  const newMinutesRemainder = newMinutes % 60;

  return `${newHours}h${addPadWithZeroes(newMinutesRemainder)}`;
}
