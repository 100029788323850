export default {
  'Bem-vindo(a)!': 'Bem-vindo(a)!',
  'Digite o email cadastrado': 'Digite o email cadastrado',
  'E-mail': 'E-mail',
  Senha: 'Senha',
  Entrar: 'Entrar',
  'Digite sua senha': 'Digite sua senha',
  'Esqueci minha senha': 'Esqueci minha senha',
  'Configurações de senha': 'Configurações de senha',
  'Políticas de privacidade': 'Políticas de privacidade',
  'Defina sua senha para acessar a plataforma.':
    'Defina sua senha para acessar a plataforma.',
  ' e ': ' e ',
  'Termos de uso': 'Termos de uso',
  Imagens: 'Imagens',
  Vídeos: 'Vídeos',
  Arquivos: 'Arquivos',
  'Em desenvolvimento': 'Em desenvolvimento',
  'Nova Imagem': 'Nova Imagem',
  'Novo Vídeo': 'Novo Vídeo',
  'Novo Arquivo': 'Novo Arquivo',
  Pacientes: 'Pacientes',
  Mídia: 'Mídia',
  Mídias: 'Mídias',
  'Excluir imagem': 'Excluir imagem',
  'Substituir imagem': 'Substituir imagem',
  'Baixar imagem': 'Baixar imagem',
  'Excluir paciente': 'Excluir paciente',
  'Excluir grupo': 'Excluir grupo',
  'Editar grupo': 'Editar grupo',
  'Mídia deletada com sucesso!': 'Mídia deletada com sucesso!',
  'Este arquivo pode estar sendo usado em alguma Sala de espera.':
    'Este arquivo pode estar sendo usado em alguma Sala de espera.',
  'Excluir Arquivo': 'Excluir Arquivo',
  'Tem certeza que deseja excluí-lo?': 'Tem certeza que deseja excluí-lo?',
  'Sim, excluir': 'Sim, excluir',
  'Não excluir': 'Não excluir',
  'Visualizar Mídia': 'Visualizar Mídia',
  'Editar Mídia': 'Editar Mídia',
  'Baixar Imagem': 'Baixar Imagem',
  Excluir: 'Excluir',
  'Abrir link em uma nova aba': 'Abrir link em uma nova aba',
  'Visualizar PDF': 'Visualizar PDF',
  'Baixar PDF': 'Baixar PDF',
  'Informações gerais': 'Informações gerais',
  'Instituição da pesquisa': 'Instituição da pesquisa',
  'Selecione uma instituição': 'Selecione uma instituição',
  'Título da pesquisa': 'Título da pesquisa',
  'Digite o título da pesquisa': 'Digite o título da pesquisa',
  'Descrição da pesquisa': 'Descrição da pesquisa',
  'Digite a descrição': 'Digite a descrição',
  'Preencha todos os campos corretamente':
    'Preencha todos os campos corretamente',
  'Cadastro de coordenadores': 'Cadastro de coordenadores',
  'Para cadastrar os coordenadores que farão parte desta pesquisa, preencha os campos abaixo e depois clique em “Cadastrar Coordenador”;':
    'Para cadastrar os coordenadores que farão parte desta pesquisa, preencha os campos abaixo e depois clique em “Cadastrar Coordenador”;',
  'Nome completo': 'Nome completo',
  'Digite o nome completo': 'Digite o nome completo',
  'Digite o e-mail': 'Digite o e-mail',
  'Cadastrar coordenador': 'Cadastrar coordenador',
  'Coordenadores cadastrados na pesquisa:':
    'Coordenadores cadastrados na pesquisa:',
  'Você ainda não possui coordenadores cadastrados.':
    'Você ainda não possui coordenadores cadastrados.',
  'Cadastro de grupos': 'Cadastro de grupos',
  'Digite o nome do grupo que você deseja incluir na pesquisa e depois clique em “Cadastrar grupo”.':
    'Digite o nome do grupo que você deseja incluir na pesquisa e depois clique em “Cadastrar grupo”.',
  'Nome do grupo': 'Nome do grupo',
  'Digite o nome do grupo': 'Digite o nome do grupo',
  'Atualizar grupo': 'Atualizar grupo',
  'Cadastrar grupo': 'Cadastrar grupo',
  'Grupos cadastrados:': 'Grupos cadastrados:',
  'Você ainda não possui grupos cadastrados.':
    'Você ainda não possui grupos cadastrados.',
  'A pesquisa deve possuir pelo menos um coordenador':
    'A pesquisa deve possuir pelo menos um coordenador',
  'A pesquisa deve possuir pelo menos um grupo':
    'A pesquisa deve possuir pelo menos um grupo',
  'Pesquisa atualizada com sucesso': 'Pesquisa atualizada com sucesso',
  'Erro ao atualizar pesquisa': 'Erro ao atualizar pesquisa',
  'Configurações de pesquisa': 'Configurações de pesquisa',
  Pesquisas: 'Pesquisas',
  Informações: 'Informações',
  Coordenadores: 'Coordenadores',
  Grupos: 'Grupos',
  Cancelar: 'Cancelar',
  Salvar: 'Salvar',
  Selecione: 'Selecione',
  'Atualizar coordenador': 'Atualizar coordenador',
  'Seleção de Pacientes': 'Seleção de Pacientes',
  'Voltar para a lista': 'Voltar para a lista',
  'Inserir pacientes': 'Inserir pacientes',
  'Usuário atualizado com sucesso!': 'Usuário atualizado com sucesso!',
  'Erro ao atualizar usuário!': 'Erro ao atualizar usuário!',
  'Mudar senha': 'Mudar senha',
  'Nova senha': 'Nova senha',
  'Digite nova senha': 'Digite nova senha',
  'Repita a nova senha': 'Repita a nova senha',
  'Digite novamente a nova senha': 'Digite novamente a nova senha',
  'Regras para a nova senha:': 'Regras para a nova senha:',
  '- Mínimo de 6 dígitos': '- Mínimo de 6 dígitos',
  '- Possuir letras e números': '- Possuir letras e números',
  '- Ser diferente de seus dados pessoais':
    '- Ser diferente de seus dados pessoais',
  'Salvar Alterações': 'Salvar Alterações',
  Imagem: 'Imagem',
  Nome: 'Nome',
  Cidade: 'Cidade',
  Contato: 'Contato',
  Ações: 'Ações',
  'Remover paciente': 'Remover paciente',
  'Tem certeza que deseja remover este paciente do grupo?':
    'Tem certeza que deseja remover este paciente do grupo?',
  'Sim, remover': 'Sim, remover',
  'Não remover': 'Não remover',
  'Erro ao buscar pacientes!': 'Erro ao buscar pacientes!',
  'Recuperar senha': 'Recuperar senha',
  'Enviaremos para o seu e-mail um link para você redefinir sua senha.':
    'Enviaremos para o seu e-mail um link para você redefinir sua senha.',
  'Link enviado para o e-mail': 'Link enviado para o e-mail',
  Enviar: 'Enviar',
  'Dados perfil': 'Dados perfil',
  'Sair da conta': 'Sair da conta',
  Coordenador: 'Coordenador',
  Administrador: 'Administrador',
  'Clique para ver as notas da release': 'Clique para ver as notas da release',
  'Senha alterada com sucesso!': 'Senha alterada com sucesso!',
  'Erro ao alterar senha!': 'Erro ao alterar senha!',
  'Confimar senha': 'Confimar senha',
  'Link copiado!': 'Link copiado!',
  'Não foi possível atualizar mídias': 'Não foi possível atualizar mídias',
  'Você já possui 3 mídias fixas': 'Você já possui 3 mídias fixas',
  'Não foi possível buscar grupo': 'Não foi possível buscar grupo',
  'Não foi possível buscar mídias': 'Não foi possível buscar mídias',
  'Mídias do grupo': 'Mídias do grupo',
  Fixada: 'Fixada',
  'Fixar mídia': 'Fixar mídia',
  'Nova Pesquisa': 'Nova Pesquisa',
  'Participantes adicionados com sucesso!':
    'Participantes adicionados com sucesso!',
  'Erro ao adicionar participantes!': 'Erro ao adicionar participantes!',
  'Selecione os pacientes do grupo': 'Selecione os pacientes do grupo',
  selecionados: 'selecionados',
  'Você tem certeza que deseja cancelar a edição?':
    'Você tem certeza que deseja cancelar a edição?',
  'Cancelar edição': 'Cancelar edição',
  'Deseja realmente cancelar?': 'Deseja realmente cancelar?',
  'Sim, cancelar': 'Sim, cancelar',
  'Não cancelar': 'Não cancelar',
  'Mídia atualizada com sucesso!': 'Mídia atualizada com sucesso!',
  'Erro ao atualizar mídia!': 'Erro ao atualizar mídia!',
  'Mídia criada com sucesso!': 'Mídia criada com sucesso!',
  'Falha ao criar mídia': 'Falha ao criar mídia',
  'Erro ao buscar mídia': 'Erro ao buscar mídia',
  'Atualizar Imagem': 'Atualizar Imagem',
  'Adicionar Imagem': 'Adicionar Imagem',
  'Atualizar Vídeo': 'Atualizar Vídeo',
  'Adicionar Vídeo': 'Adicionar Vídeo',
  'Atualizar PDF': 'Atualizar PDF',
  'Adicionar PDF': 'Adicionar PDF',
  'Insira o link do vídeo': 'Insira o link do vídeo',
  'Digite aqui a legenda que será apresentada na sala.':
    'Digite aqui a legenda que será apresentada na sala.',
  'Digite aqui o nome desta imagem': 'Digite aqui o nome desta imagem',
  'Digite aqui o nome do arquivo': 'Digite aqui o nome do arquivo',
  'Selecione as instituições que podem visualizar está mídia:':
    'Selecione as instituições que podem visualizar está mídia:',
  'Todas as instituições': 'Todas as instituições',
  Confirmar: 'Confirmar',
  'Erro ao buscar pesquisas!': 'Erro ao buscar pesquisas!',
  pacientes: 'pacientes',
  mídias: 'mídias',
  'Dados pessoais': 'Dados pessoais',
  'Meu perfil': 'Meu perfil',
  'Grupo de Controle': 'Grupo de Controle',
  Revisão: 'Revisão',
  'Você ainda não possui grupo cadastrados.':
    'Você ainda não possui grupo cadastrados.',
  Editar: 'Editar',
  'Instituição:': 'Instituição:',
  'Pesquisa:': 'Pesquisa:',
  'Descrição:': 'Descrição:',
  Anterior: 'Anterior',
  Concluir: 'Concluir',
  Próximo: 'Próximo',
  'Copiar link': 'Copiar link',
  'E-mail de recuperação enviado com sucesso!':
    'E-mail de recuperação enviado com sucesso!',
  'Login realizado com sucesso!': 'Login realizado com sucesso!',
  'Usuário ou senha inválidos': 'Usuário ou senha inválidos',
  'Erro inesperado': 'Erro inesperado',
  'Credênciais inválidas.': 'Credênciais inválidas.',
  'Falha interna no servidor, tente novamente em minutos.':
    'Falha interna no servidor, tente novamente em minutos.',
  'Falha ao deletar mídia!': 'Falha ao deletar mídia!',
  'Pesquisa criada com sucesso': 'Pesquisa criada com sucesso',
  'Já existe uma pesquisa cadastrada com essas informações.':
    'Já existe uma pesquisa cadastrada com essas informações.',
  'Não foi possível criar a pesquisa': 'Não foi possível criar a pesquisa',
  'Paciente removido com sucesso!': 'Paciente removido com sucesso!',
  'Erro ao remover paciente!': 'Erro ao remover paciente!',
  'Imagem atualizada com sucesso!': 'Imagem atualizada com sucesso!',
  'Erro ao atualizar imagem!': 'Erro ao atualizar imagem!',
  'Senhas não coincidem': 'Senhas não coincidem',
  'Email inválido!': 'Email inválido!',
  'Preencha o email!': 'Preencha o email!',
  'Preencha a senha!': 'Preencha a senha!',
  'Preencha o nome!': 'Preencha o nome!',
  'Preencha a idade!': 'Preencha a idade!',
  'Idade inválida!': 'Idade inválida!',
  'Selecione um sexo!': 'Selecione um sexo!',
  'Senha Incorreta': 'Senha Incorreta',
  'Email não cadastrado': 'Email não cadastrado',
  'Cadastro não localizado': 'Cadastro não localizado',
  'Email inválido.': 'Email inválido.',
  'Senha inválida': 'Senha inválida',
  'A senha é obrigatória': 'A senha é obrigatória',
  'A senha deve ter no mínimo 6 caracteres':
    'A senha deve ter no mínimo 6 caracteres',
  'A senha deve conter pelo menos um número e uma letra maiúscula':
    'A senha deve conter pelo menos um número e uma letra maiúscula',
  'A confirmação de senha é obrigatória':
    'A confirmação de senha é obrigatória',
  'As senhas não conferem': 'As senhas não conferem',
  'O nome excedeu o limite de 1024 caracteres':
    'O nome excedeu o limite de 1024 caracteres',
  'O nome é obrigatório': 'O nome é obrigatório',
  'A descrição é obrigatória': 'A descrição é obrigatória',
  'A URL não pode ter mais de 1024 caracteres':
    'A URL não pode ter mais de 1024 caracteres',
  'A URL deve ser válida': 'A URL deve ser válida',
  'A URL excedeu o limite de 1024 caracteres':
    'A URL excedeu o limite de 1024 caracteres',
  'A URL é obrigatória': 'A URL é obrigatória',
  'O arquivo é obrigatório': 'O arquivo é obrigatório',
  'É necessário adicionar um arquivo': 'É necessário adicionar um arquivo',
  'O nome da organização é obrigatório': 'O nome da organização é obrigatório',
  'O título da pesquisa é obrigatório': 'O título da pesquisa é obrigatório',
  'A descrição da pesquisa é obrigatória':
    'A descrição da pesquisa é obrigatória',
  'O nome do grupo é obrigatório': 'O nome do grupo é obrigatório',
  'O nome do responsável é obrigatório': 'O nome do responsável é obrigatório',
  'O e-mail do responsável é obrigatório':
    'O e-mail do responsável é obrigatório',
  Organizações: 'Organizações',
  'Seleção de Organizações': 'Seleção de Organizações',
  'Adicione as organizações abaixo que fazem parte da LAOHA.':
    'Adicione as organizações abaixo que fazem parte da LAOHA.',
  'Não foi possível carregar as organizações':
    'Não foi possível carregar as organizações',
  'Nome da organização': 'Nome da organização',
  Sigla: 'Sigla',
  'Data de criação': 'Data de criação',
  'Organização removida com sucesso!': 'Organização removida com sucesso!',
  'Erro ao remover organização.': 'Erro ao remover organização.',
  'Organização criada com sucesso!': 'Organização criada com sucesso!',
  'Erro ao criar organização.': 'Erro ao criar organização.',
  Adicionar: 'Adicionar',
  Remover: 'Remover',
  Adicionado: 'Adicionado',
  'Buscar paciente...': 'Buscar paciente...',
  'Buscar mensagem...': 'Buscar mensagem...',
  'Buscar organização...': 'Buscar organização...',
  'Buscar mídia...': 'Buscar mídia...',
};
