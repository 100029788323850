import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { ListResearch as UsecaseRemoteListResearch } from 'domain/usecases/research/remote';
import { ListResearch as UsecaseReduxListResearch } from 'domain/usecases/research/redux';

import { RemoteListResearch } from 'data/repository/research';
import { ReduxListResearch } from 'data/store/reducer/research/usecases';

/**
 * send request via API.
 */
export const makeRemoteListResearch = (): UsecaseRemoteListResearch =>
  new RemoteListResearch(makeApiUrl('/researches/'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxListResearch = (): UsecaseReduxListResearch =>
  new ReduxListResearch();
