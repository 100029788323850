/* eslint-disable react/no-unknown-property */
import React from 'react';

const IconDefaultDoc = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 24 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 3.69827C0 2.53852 1.03734 1.45855 2.24699 1.35896L18.664 0.00731251C19.7414 -0.0813936 20.5695 0.646338 20.567 1.67973L20.5099 25.2397C20.5073 26.334 19.5777 27.3696 18.4328 27.5537L2.07281 30.1845C0.927676 30.3686 0 29.6314 0 28.5373V3.69827Z"
        fill="#BABABA"
      />
      <path
        d="M0 3.88595H21.4286C22.8487 3.88595 24 4.97005 24 6.30735V31.3286C24 32.6659 22.8487 33.75 21.4286 33.75H2.57143C1.15127 33.75 0 32.6659 0 31.3286V3.88595Z"
        fill="#F4F4F4"
      />
      <path
        d="M20.5714 6.786C20.5714 6.34023 20.1877 5.97886 19.7143 5.97886H4.28571C3.81233 5.97886 3.42857 6.34023 3.42857 6.786V10.0145C3.42857 10.4603 3.81233 10.8217 4.28571 10.8217H19.7143C20.1877 10.8217 20.5714 10.4603 20.5714 10.0145V6.786Z"
        fill="#CFCECE"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.3903 16.2145C12.5827 16.5591 11.4173 16.5591 10.6097 16.2145C9.9536 15.9345 9.2197 15.9086 8.54253 16.202C7.1138 16.821 6.47281 18.6297 7.09411 20.2616C7.09438 20.2623 7.09451 20.2631 7.09449 20.2638L7.28859 20.6842C7.94471 22.1053 8.43926 23.5949 8.76304 25.1255L9.07104 26.5814C9.17763 26.9999 9.55429 27.2929 9.98591 27.2929C10.4495 27.2929 10.8445 26.9561 10.918 26.4981L11.3523 23.7957C11.4034 23.4775 11.6779 23.2434 12 23.2434C12.3221 23.2434 12.5966 23.4775 12.6478 23.7957L13.082 26.4981C13.1555 26.9561 13.5505 27.2929 14.0141 27.2929C14.4457 27.2929 14.8224 26.9999 14.929 26.5814L15.237 25.1255C15.5607 23.5949 16.0553 22.1053 16.7114 20.6842L16.9055 20.2638C16.9055 20.2631 16.9056 20.2623 16.9059 20.2616C17.5272 18.6297 16.8862 16.821 15.4575 16.202C14.7803 15.9086 14.0464 15.9345 13.3903 16.2145Z"
        fill="#CFCECE"
      />
    </svg>
  );
};

export default IconDefaultDoc;
