import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { GetMediasByGroup } from 'domain/usecases/group/remote';
import { makeRemoteGetByIdGroup } from 'main/factories/usecases/group/GetByIdGroupFactory';
import { Header, HeaderSimples } from 'presentation/components/Header';
import { useParams } from 'react-router-dom';

import { Skeleton } from '@chakra-ui/react';
import { GetByIdResearch } from 'domain/usecases/research/remote';
import { makeRemoteGetMediasByGroup } from 'main/factories/usecases/group/GetMediasByGroup';
import { makeRemoteUpdateMediasFromGroup } from 'main/factories/usecases/group/UpdateMediasFromGroup';
import { makeRemoteGetByIdResearch } from 'main/factories/usecases/research/GetByIdResearchFactory';
import { IconEmptyVideo, IconResearch } from 'presentation/base/icons';
import { SelectMedias } from 'presentation/components/select-medias';
import { BsPinAngleFill } from 'react-icons/bs';
import { CgScreen } from 'react-icons/cg';
import { toast } from 'react-toastify';
import { formattedUrl } from 'utils/formattedUrl';
import { translator } from 'presentation/components/i18n';
import Translator from 'presentation/components/i18n/Translator';
import Card from './Card';
import { ContainerCards, Tag, TitleReseacrhBox } from './styles';

type iParams = {
  id: string;
};

type Media = GetMediasByGroup.Model['records'][0];

export const GroupsMedias: React.FC = () => {
  const { id } = useParams<iParams>();
  const [research, setResearch] = useState({} as GetByIdResearch.Model);
  const [loading, setLoading] = useState(true);

  const [activeSelectMedias, setActiveSelectMedias] = useState<number>(0);
  const selectMediaOptions: Record<string, string> = {
    IMAGE: Translator('Imagens'),
    VIDEO: Translator('Vídeos'),
    FORM: Translator('Arquivos'),
  };

  const [medias, setMedias] = useState<Media[]>([] as Media[]);

  const handleSave = useCallback(
    async (media: GetMediasByGroup.Model['records'][0]) => {
      try {
        await makeRemoteUpdateMediasFromGroup().updateMedias({
          groupId: Number(id),
          body: {
            medias: [
              {
                id: media.id,
                fixed: media.fixed,
                disabledToGroup: media.fixed ? false : media.disabledToGroup,
              },
            ],
          },
        });
      } catch (error) {
        toast.error(translator('Não foi possível atualizar mídias'), {
          position: 'top-right',
        });
      }
    },
    [],
  );

  const handleFixed = useCallback(
    (mediaId: number) => {
      const newMedias = medias?.map(media => {
        if (media.id === mediaId) {
          const newMedia: Media = {
            ...media,
            fixed: !media.fixed,
            disabledToGroup: !media.fixed ? false : media.disabledToGroup,
          };

          handleSave(newMedia);

          return newMedia;
        }
        return media;
      });

      setMedias([...newMedias]);
    },
    [handleSave, medias],
  );

  const handleChecked = useCallback(
    (mediaId: number) => {
      const newMedias = medias?.map(media => {
        if (media.id === mediaId) {
          const newMedia: Media = {
            ...media,
            fixed: !media.disabledToGroup ? false : media.fixed,
            disabledToGroup: !media.disabledToGroup,
          };

          handleSave(newMedia);

          return newMedia;
        }
        return media;
      });

      setMedias([...newMedias]);
    },
    [handleSave, medias],
  );

  const handleGetGroupById = useCallback(async () => {
    try {
      const groupResponse = await makeRemoteGetByIdGroup().getById({
        groupId: Number(id),
      });

      const researchResponse = await makeRemoteGetByIdResearch().getById({
        researchId: groupResponse.researchId,
      });

      setResearch(researchResponse);
      handleGetMedias(researchResponse.orgId);
    } catch (error) {
      toast.error(translator('Não foi possível buscar grupo'), {
        position: 'top-right',
      });
    } finally {
      setLoading(false);
    }
  }, [id]);

  const handleGetMedias = useCallback(
    async (researchId: number) => {
      try {
        const mediasResponse = await makeRemoteGetMediasByGroup().getByGroupId({
          groupId: Number(id),
          query: {
            org: researchId,
            limit: 9999,
          },
        });

        setMedias([...mediasResponse?.records]);
      } catch (error) {
        toast.error(translator('Não foi possível buscar mídias'), {
          position: 'top-right',
        });
      } finally {
        setLoading(false);
      }
    },
    [id],
  );

  useEffect(() => {
    handleGetGroupById();
  }, [handleGetGroupById]);

  return (
    <>
      <Header />
      <HeaderSimples
        title={translator('Mídias do grupo')}
        Icon={CgScreen}
        backDescription={translator('Voltar para a lista')}
      />
      <div style={{ padding: '0 7.2rem' }}>
        <TitleReseacrhBox data-testid={`research_title_${research?.id}`}>
          <IconResearch className="icon" />
          {loading ? (
            <Skeleton height="18px" width="30rem" />
          ) : (
            `(${research?.shortOrg?.toLocaleUpperCase()}) ${research?.name}`
          )}
        </TitleReseacrhBox>
        <div
          style={{
            border: '1px solid #efefef',
            padding: '24px',
            borderRadius: '5px',
            minHeight: '70rem',
          }}
        >
          <div style={{ marginBottom: '3.2rem' }}>
            <SelectMedias
              active={activeSelectMedias}
              options={Object.values(selectMediaOptions)}
              setState={setActiveSelectMedias}
            />
          </div>
          <ContainerCards>
            {medias
              ?.filter(
                media =>
                  media.type ===
                  Object.keys(selectMediaOptions)[activeSelectMedias],
              )
              .sort((a, b) => Number(b.fixed) - Number(a.fixed))
              .map(media => (
                <Card
                  id={media.id}
                  name={media.name}
                  type={media.type}
                  disabledToGroup={media.disabledToGroup}
                  onChecked={handleChecked}
                >
                  <Tag
                    data-testid={`media_tag_${media.id}`}
                    fixed={media.fixed}
                    onClick={() => handleFixed(media.id)}
                  >
                    <BsPinAngleFill size={10} />
                    <p>
                      {media.fixed
                        ? translator('Fixada')
                        : translator('Fixar mídia')}
                    </p>
                  </Tag>
                  {media.type === 'IMAGE' && (
                    <img src={media.path ?? media.url} alt={media.name} />
                  )}
                  {media.type === 'VIDEO' &&
                    (!media.url ||
                    !media.url.match(
                      /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/,
                    ) ? (
                      <IconEmptyVideo width="100%" height="90%" />
                    ) : (
                      <iframe
                        width="100%"
                        height="100%"
                        src={formattedUrl(media.url)}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                      />
                    ))}
                </Card>
              ))}
          </ContainerCards>
        </div>
      </div>
    </>
  );
};

export default GroupsMedias;
