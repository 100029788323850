/* eslint-disable react/require-default-props */
import React, { ButtonHTMLAttributes, ReactElement } from 'react';

import { IconLogo } from 'presentation/base/icons';
import { withAccess } from 'presentation/hooks/access';
import { Container, Icon } from './styles/StyledButton';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'primary-custom';
  size?: 'mini' | 'small' | 'medium' | 'large' | 'bigger';
  color?: string;
  hoverColor?: string;
  height?: string;
  nowrap?: boolean;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  iconHeight?: number;
  iconWidth?: number;
  rounded?: boolean;
  active?: boolean;
  disableIconHover?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  color,
  hoverColor,
  type = 'button',
  size = 'default',
  variant = 'primary',
  height = '39px',
  nowrap = false,
  icon,
  iconHeight,
  iconWidth,
  rounded = false,
  active = false,
  disableIconHover = false,
  ...rest
}) => {
  const Logo = icon || IconLogo;

  return (
    <Container
      color={color || '#DF2C26'}
      type={type}
      size={size}
      variant={variant}
      height={height}
      nowrap={nowrap}
      rounded={rounded}
      active={active}
      hoverColor={hoverColor}
      disableIconHover={disableIconHover}
      {...rest}
    >
      {icon && (
        <Icon>
          <Logo style={{ height: iconHeight, width: iconWidth }} />
        </Icon>
      )}
      {children}
    </Container>
  );
};

export default withAccess(Button);
