/* eslint-disable array-callback-return */
export function buildSearchParams<T>(data: T | any): URLSearchParams {
  const params = new URLSearchParams();

  Object.keys(data).map(key => {
    if (Array.isArray(data[key])) {
      data[key].forEach((term: string) => params.append(key, term));
    } else if (data[key]) {
      params.append(key, data[key]);
    }
  });

  return params;
}
