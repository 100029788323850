/* eslint-disable react/require-default-props */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/jsx-one-expression-per-line */
import { Box, Textarea } from '@chakra-ui/react';
import React from 'react';

// import { iListMessageResponse } from '../Lists/interface';
import Translator from 'presentation/components/i18n/Translator';
import { CreateMessageProps } from '../..';
import { ContainerNotes, ContentNotes } from './styles/StyledWritingArea';

// interface iStoreParams {
//   // message: iListMessageResponse['records'][0];
//   //   message: any;
// }

interface EmailWritingAreaProps {
  createMessage: CreateMessageProps;
  setCreateMessage: (prevState: CreateMessageProps) => any;
  characterLimit?: number;
}

const EmailWritingArea: React.FC<EmailWritingAreaProps> = ({
  createMessage,
  setCreateMessage,
  characterLimit = 1600,
}) => {
  const handleTextArea = (e: any) => {
    const textValue = e.target.value;

    if (textValue.length > characterLimit) {
      return;
    }

    setCreateMessage({ ...createMessage, content: textValue });
  };

  return (
    <ContainerNotes>
      <ContentNotes>
        <Box
          data-testid="notes"
          width="100%"
          height="170px"
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
        >
          <Textarea
            value={createMessage?.content}
            onChange={handleTextArea}
            fontSize="16px"
            placeholder={Translator(
              'Escreva o conteúdo da mensagem e insira as mídias desejadas',
            )}
            height="168px"
            borderWidth="1px"
            resize="none"
          />
        </Box>
      </ContentNotes>
    </ContainerNotes>
  );
};

export default EmailWritingArea;
