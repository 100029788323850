/* eslint-disable jsx-a11y/label-has-associated-control */
import Translator from 'presentation/components/i18n/Translator';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import ListItem from '../listItem';
import { Coordinators } from '../Step2';
import { GroupForm } from '../Step3';
import {
  Column,
  Container,
  ListContainer,
  Row,
  ContainerList,
} from '../styles';
import {
  Information,
  Description,
  LinkToEdit,
  Title,
  TitleInformation,
  HorizontalDivider,
} from './styles';

type FormInfo = {
  groups: GroupForm[];
  organizers: Coordinators['organizers'];
  currentStep: number;
  name: string;
  org: {
    name: string;
    id: number;
  };
  descr: string;
};

export const Step4: React.FC = (): JSX.Element => {
  // trocar por um array de grupos
  const { setValue, getValues } = useFormContext<FormInfo>();

  return (
    <Container>
      <Column>
        <Row noMb size="full" disabledResponsive>
          <Title>
            {Translator('Informações')}
            <LinkToEdit
              data-testid="btn_edit_information"
              onClick={() => setValue('currentStep', 1)}
            >
              {Translator('Editar')}
            </LinkToEdit>
          </Title>
        </Row>
        <Row disabledResponsive>
          <Information>
            <TitleInformation>{Translator('Instituição:')}</TitleInformation>
            <Description>{getValues()?.org.name}</Description>
          </Information>

          <Information>
            <TitleInformation>{Translator('Pesquisa:')}</TitleInformation>
            <Description>{getValues()?.name}</Description>
          </Information>

          <Information>
            <TitleInformation>{Translator('Descrição:')}</TitleInformation>
            <Description>{getValues()?.descr}</Description>
          </Information>
        </Row>
        <HorizontalDivider />
        <Row noMb disabledResponsive>
          <Column disablePadding>
            <Row noMb size="full">
              <Title>
                {Translator('Coordenadores')}
                <LinkToEdit
                  data-testid="btn_edit_coordinator"
                  onClick={() => setValue('currentStep', 2)}
                >
                  {Translator('Editar')}
                </LinkToEdit>
              </Title>
            </Row>
            <Row noMb disabledResponsive>
              <ContainerList>
                <ListContainer>
                  {getValues()?.organizers?.map(item => (
                    <ListItem disabledActions key={item.userId} item={item} />
                  ))}
                </ListContainer>
              </ContainerList>
            </Row>
          </Column>
          <Column disablePadding>
            <Row noMb size="full">
              <Title>
                {Translator('Grupos')}
                <LinkToEdit
                  data-testid="btn_edit_group"
                  onClick={() => setValue('currentStep', 3)}
                >
                  {Translator('Editar')}
                </LinkToEdit>
              </Title>
            </Row>
            <Row disabledResponsive>
              <ContainerList>
                <ListContainer>
                  {getValues()?.groups?.map(item => (
                    <ListItem
                      disabledActions
                      key={item.id}
                      item={{ userId: item.id, fullname: item.name }}
                    />
                  ))}
                </ListContainer>
              </ContainerList>
            </Row>
          </Column>
        </Row>
      </Column>
    </Container>
  );
};

export default Step4;
