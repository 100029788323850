import React from 'react';

const IconPadLockRed = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6667 16C14.6667 16 16 16 16 14.6667C16 13.3333 14.6667 9.33333 8 9.33333C1.33333 9.33333 0 13.3333 0 14.6667C0 16 1.33333 16 1.33333 16H14.6667ZM12 4C12 5.06087 11.5786 6.07828 10.8284 6.82843C10.0783 7.57857 9.06087 8 8 8C6.93913 8 5.92172 7.57857 5.17157 6.82843C4.42143 6.07828 4 5.06087 4 4C4 2.93913 4.42143 1.92172 5.17157 1.17157C5.92172 0.421427 6.93913 0 8 0C9.06087 0 10.0783 0.421427 10.8284 1.17157C11.5786 1.92172 12 2.93913 12 4Z"
        fill={props.fill ?? '#DF2C26'}
      />
    </svg>
  );
};

export default IconPadLockRed;
