import styled, { css } from 'styled-components';
import Card from 'presentation/components/Cards/Card';

export const TitleReseacrhBox = styled.div`
  width: 100%;
  padding: 1.6rem;
  margin: 1.6rem 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 0.5rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.background.grey};
  font: ${({ theme }) => theme.typography.h7};
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.typography.gray};

  .icon {
    margin-right: 0.9rem;
  }
`;

export const GroupMediasCard = styled(Card)`
  border-radius: 100px;
  position: relative;
  bottom: 0;
`;

// CARD

interface propsCard {
  isVideo?: boolean;
}

export const ContainerCard = styled.div`
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border: ${({ theme }) => `1px solid ${theme.colors.status.disable}`};
  border-radius: 2px;
  width: 40.6rem;
  display: flex;
  flex-direction: column;
  height: 24rem;

  position: relative;

  @media (max-width: 1366px) {
    width: 35.6rem;
  }
`;

export const ContentCard = styled.div<propsCard>`
  background: #515151;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 19rem;
  cursor: pointer;

  ${({ isVideo }) =>
    isVideo &&
    css`
      background: none;
    `}
`;

export const FooterCard = styled.div`
  display: flex;
  width: 100%;
  height: 5rem;
  align-items: center;
  justify-content: start;
  padding: 0 1.4rem;
  :hover {
    color: ${({ theme }) => theme.colors.primary.main};
  }

  .chakra-switch__track[data-checked] {
    background-color: #0b77cd;
  }

  .chakra-switch__track {
    background-color: #cbd5e0;
  }
`;

export const ContentChildren = styled.div<propsCard>`
  width: ${({ isVideo }) => (isVideo ? '100%' : '80%')};
  display: flex;
  height: ${({ isVideo }) => (isVideo ? '100%' : '90%')};
  align-items: center;
  justify-content: center;
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`;

export const ContainerPopoverOptions = styled.div`
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  width: 26rem;
`;

export const PopoverOptions = styled.div`
  display: flex;
  gap: 1.7rem;
  align-items: center;
  padding: 1.5rem;
  width: 100%;
  font-size: 1.6rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.typography.gray};
  :hover {
    font-weight: bold;
  }
`;

// Tag

type propsTag = {
  fixedByAdmin?: boolean;
  fixed?: boolean;
};

export const Tag = styled.div<propsTag>`
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 0.6rem;

  & p {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;

    user-select: none;
  }

  position: absolute;
  top: 0.8rem;
  right: 0.8rem;

  padding: 0.2rem 0.8rem;

  svg {
    margin-right: 0.5rem;
  }

  ${({ fixed }) => css`
    background: ${fixed ? '#0b77cd' : '#fff'};
    color: ${fixed ? '#fff' : '#0b77cd'};

    border: 1px solid #0b77cd;

    svg path {
      fill: ${fixed ? '#fff' : '#0b77cd'};
    }
  `}

  ${({ fixedByAdmin }) =>
    fixedByAdmin &&
    css`
      background: #bab9b9;
      color: #fff;
      border: 1px solid #bab9b9;
      svg path {
        fill: #fff;
      }
    `}
`;

export const ContainerCards = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-height: 50.8rem;
  overflow-y: auto;
  gap: 2.4rem;

  @media (max-width: 1366px) {
    max-height: 45rem;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: #e3e3e3;
    border-radius: 5px;
  }
`;
