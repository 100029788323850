import React from 'react';

const IconEmptyVideo = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 205 116"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="205" height="116" rx="4" fill="#EAEAEA" />
      <path
        d="M102 76C112.493 76 121 67.4934 121 57C121 46.5066 112.493 38 102 38C91.5066 38 83 46.5066 83 57C83 67.4934 91.5066 76 102 76Z"
        fill="#DDDDDD"
      />
      <path
        d="M97.0016 62.2788V51.676C97.0018 51.5578 97.0364 51.4416 97.1018 51.3392C97.1672 51.2367 97.2613 51.1515 97.3745 51.092C97.4878 51.0325 97.6164 51.0008 97.7475 51C97.8785 50.9992 98.0075 51.0294 98.1217 51.0876L108.621 56.4278C108.736 56.487 108.832 56.5726 108.898 56.676C108.965 56.7793 109 56.8967 109 57.0163C109 57.1358 108.965 57.2532 108.898 57.3565C108.832 57.4599 108.736 57.5455 108.621 57.6047L98.1217 62.9116C98.0039 62.972 97.8701 63.0025 97.7347 62.9998C97.5992 62.9972 97.4671 62.9616 97.3523 62.8967C97.2374 62.8318 97.1442 62.7401 97.0825 62.6314C97.0207 62.5226 96.9928 62.4007 97.0016 62.2788Z"
        fill="white"
      />
    </svg>
  );
};

export default IconEmptyVideo;
