import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { UpdateMedia as UsecaseRemoteUpdateMedia } from 'domain/usecases/media/remote';
// import { UpdateMedia as UsecaseReduxUpdateMedia } from 'domain/usecases/media/redux';

import { RemoteUpdateMedia } from 'data/repository/media';
// import { ReduxUpdateMedia } from 'data/store/reducer/media/usecases';

/**
 * send request via API.
 */
export const makeRemoteUpdateMedia = (): UsecaseRemoteUpdateMedia =>
  new RemoteUpdateMedia(makeApiUrl('/medias/{media}'), makeHttpClient());

/**
 * send request via REDUX.
 */
/* export const makeReduxUpdateMedia =
  (): UsecaseReduxUpdateMedia =>
    new ReduxUpdateMedia(); */
