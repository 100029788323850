import { IconGroup, IconResearch } from 'presentation/base/icons';
import IconGroupView from 'presentation/base/icons/iconGroup';
import React from 'react';
import Translator from '../i18n/Translator';

import { TitleResearchBox, GroupTitleView, GroupControlView } from './styles';

interface HeaderBox {
  title: string | undefined;
}

const HeaderBox: React.FC<HeaderBox> = ({ title }): JSX.Element => {
  return (
    <TitleResearchBox>
      <GroupTitleView>
        <IconResearch className="icon" />
        {title}
      </GroupTitleView>
      <GroupControlView>
        <IconGroupView style={{ marginRight: 9 }} />
        <p>{Translator('Grupo de Controle')}</p>
      </GroupControlView>
    </TitleResearchBox>
  );
};

export default HeaderBox;
