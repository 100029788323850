import React, { useEffect, useMemo, useState } from 'react';
import ActionsList from 'presentation/components/actions-list/ActionsList';
import { iMessage } from 'domain/interfaces/models';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { IconClose } from 'presentation/base/icons';
import { iResponseMedias } from 'presentation/pages/MediaLibraryPage/interface';
import { makeRemoteListMedia } from 'main/factories/usecases/media/ListMediaFactory';
import {
  Container,
  Content,
  HeaderCard,
  ImageContainer,
  Image,
  Footer,
} from './styles/StyledImageModalMedia';
import { downloadFile } from '../Cards/Card';
import { translator } from '../i18n';

interface propsImageModal {
  message: iMessage;
}

const ImageModalMedia: React.FC<propsImageModal> = ({ message }) => {
  const msgName = MessageOptions.ImageModal;

  const { active, actionCancel, id } = message;

  const [medias, setMedias] = useState<iResponseMedias[]>([]);

  useEffect(() => {
    makeRemoteListMedia()
      .list({
        query: {
          limit: 9999,
        },
      })
      .then(res => {
        console.log('medias: ', res);

        setMedias(res.records);
      })
      .catch(err => {
        console.log('err: ', err);
      });
  }, [active]);

  const item = useMemo(
    () => medias.find(value => value.id === id),
    [id, medias],
  );

  return (
    <>
      {msgName === active && (
        <Container>
          <Content>
            <HeaderCard>
              <div />
              <div>{translator('Imagem')}</div>
              <div style={{ cursor: 'pointer' }}>
                <IconClose data-testid="icon_close" onClick={actionCancel} />
              </div>
            </HeaderCard>
            <ImageContainer>
              <Image data-testid="content_image">
                <img src={item?.path} alt={item?.name} />
              </Image>
            </ImageContainer>
            <Footer>
              <div>{item?.name}</div>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <ActionsList
                  actions={{
                    downloadImage: () => {
                      downloadFile(item?.path ?? '', item?.name ?? '');
                    },
                    // replaceImage: () => {
                    //   console.log('');
                    // },
                    // deleteImage: () => {
                    //   console.log('');
                    // },
                  }}
                />
              </div>
            </Footer>
          </Content>
        </Container>
      )}
    </>
  );
};

export default ImageModalMedia;
