import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { ListParticipantsByOrg as UsecaseRemoteListParticipantsByOrg } from 'domain/usecases/org/remote';
import { ListParticipantsByOrg as UsecaseReduxListParticipantsByOrg } from 'domain/usecases/org/redux';

import { RemoteListParticipantsByOrg } from 'data/repository/org';
import { ReduxListParticipantsByOrg } from 'data/store/reducer/org/usecases';

/**
 * send request via API.
 */
export const makeRemoteListParticipantsByOrg =
  (): UsecaseRemoteListParticipantsByOrg =>
    new RemoteListParticipantsByOrg(
      makeApiUrl('/orgs/{org}/consultants/'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
export const makeReduxListParticipantsByOrg =
  (): UsecaseReduxListParticipantsByOrg => new ReduxListParticipantsByOrg();
