/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Input, Select } from 'presentation/components/UI';
import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';
import { translator } from 'presentation/components/i18n';
import { Column, Container, Row, Title } from '../styles';
import Textarea from '../../../UI/textarea';

export const Step1: React.FC = (): JSX.Element => {
  const {
    register,
    setValue,
    setError,
    getValues,
    formState: { errors },
  } = useFormContext<{
    descr: string;
    name: string;
    org: { name: string; id: number };
  }>();

  const orgs: Record<number, string> = {};

  const { records } = useSelector((store: iStore) => store.org);

  records?.map(item => {
    orgs[item.id] = item.name;
    return item;
  });

  return (
    <Container>
      <Column>
        <Title mb>{translator('Informações gerais')}</Title>
        <Row size="medium">
          <Select
            {...register('org')}
            data-testid="select_org"
            name="org"
            onChange={e => {
              setError('org', {
                type: 'onBlur',
              });
              setValue('org', {
                name: orgs[Number(e.target.value)],
                id: Number(e.target.value),
              });
            }}
            value={getValues()?.org?.id}
            error={Boolean(errors?.org?.name)}
            message={errors?.org?.name && errors.org?.name?.message}
            label={translator('Instituição da pesquisa')}
            placeholder={translator('Selecione uma instituição')}
          >
            <option value={-1} data-testid="default_select_org">
              {translator('Selecione')}
            </option>
            {records?.map(value => (
              <option
                data-testid={`option_org_${value.id}`}
                key={value.id}
                value={value.id}
              >
                {value.name}
              </option>
            ))}
          </Select>
        </Row>
        <Row>
          <Input
            {...register('name')}
            data-testid="input_name"
            label={translator('Título da pesquisa')}
            placeholder={translator('Digite o título da pesquisa')}
            name="name"
            error={errors?.name && Boolean(errors?.name)}
            message={errors?.name && errors.name?.message}
          />
        </Row>
        <Row>
          <Textarea
            {...register('descr')}
            data-testid="textarea_descr"
            error={errors?.descr && Boolean(errors?.descr)}
            message={errors?.descr && errors.descr?.message}
            label={translator('Descrição da pesquisa')}
            id="textarea"
            placeholder={translator('Digite a descrição')}
            name="descr"
          />
        </Row>
      </Column>
    </Container>
  );
};

export default Step1;
