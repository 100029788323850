/* eslint-disable react/jsx-wrap-multilines */
import { HomeFactory } from 'main/factories/pages/Home';
import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';

import {
  ListMessageLandingPage,
  MediaLibraryLandingPage,
  RecoveryPasswordPage,
  UserProfilePage,
  WaitingRoomPage,
  ConfirmNewPasswordPage,
  ListOrganizationLandingPage,
} from 'presentation/pages';
import EditResearchPage from 'presentation/pages/EditResearchPage';
import GroupsMedias from 'presentation/pages/GroupsMedias';
import NewMessagePage from 'presentation/pages/NewMessagePage';
import LoginPage from 'presentation/pages/LoginPage';
import NewSearchPage from 'presentation/pages/NewResearchPage';
import PatientSelectionPage from 'presentation/pages/PatientSelectionPage';
import App from '../../App';
import PrivateRoute from './_PrivateRoute';

const Router: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<LoginPage />} />

        <Route path="password" element={<Outlet />}>
          <Route path="recovery" element={<RecoveryPasswordPage />} />
          <Route path="validate" element={<ConfirmNewPasswordPage />} />
        </Route>

        <Route path="waiting-room" element={<WaitingRoomPage />} />

        <Route
          path="home"
          element={
            <PrivateRoute>
              <HomeFactory />
            </PrivateRoute>
          }
        />

        <Route
          path="medias"
          element={
            <PrivateRoute>
              <MediaLibraryLandingPage />
            </PrivateRoute>
          }
        />

        <Route path="messages" element={<Outlet />}>
          <Route
            path="list"
            element={
              <PrivateRoute>
                <ListMessageLandingPage />
              </PrivateRoute>
            }
          />
          <Route
            path="create"
            element={
              <PrivateRoute>
                <NewMessagePage />
              </PrivateRoute>
            }
          />
        </Route>

        <Route
          path="organizations"
          element={
            <PrivateRoute>
              <ListOrganizationLandingPage />
            </PrivateRoute>
          }
        />

        <Route path="research" element={<Outlet />}>
          <Route
            path="create"
            element={
              <PrivateRoute>
                <NewSearchPage />
              </PrivateRoute>
            }
          />
          <Route
            path="edit/:id"
            element={
              <PrivateRoute>
                <EditResearchPage />
              </PrivateRoute>
            }
          />
          <Route
            path=":researchId/group/:groupId"
            element={
              <PrivateRoute>
                <PatientSelectionPage />
              </PrivateRoute>
            }
          />
        </Route>

        <Route path="profile" element={<Outlet />}>
          <Route
            path=":id"
            element={
              <PrivateRoute>
                <UserProfilePage />
              </PrivateRoute>
            }
          />
        </Route>

        <Route path="group/:id/medias" element={<GroupsMedias />} />

        <Route path="*" element={<LoginPage />} />
      </Route>
    </Routes>
  );
};

export default Router;
