import styled from 'styled-components';

export const ContainerMediaLibrary = styled.div`
  margin-top: 2.4rem;
  width: 100%;
  padding: 0 7.2rem 0 7.2rem;
`;

export const SearchContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 4rem;
`;

export const ListMediaLibrarys = styled.div`
  width: 100%;
  height: 68rem;
  border-radius: 5px;
  margin-top: 4.3rem;
  padding: 2.2rem;
  border: 1px solid #efefef;
  margin-bottom: 3.8rem;

  @media (max-width: 1366px) {
    height: 48rem;
  }
`;

export const ContainerCards = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-height: 50.8rem;
  overflow-y: auto;
  gap: 2.4rem;

  @media (max-width: 1366px) {
    max-height: 45rem;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: #e3e3e3;
    border-radius: 5px;
  }
`;

export const AddingMedia = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
