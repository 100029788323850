import styled from 'styled-components';

interface iImage {
  size?: string;
  hasBorder?: boolean;
}

export const Container = styled.div<iImage>`
  position: relative;
  border: ${({ hasBorder }) => (hasBorder ? '1px solid #d8d8d8' : 'none')};

  width: 15.8rem;
  height: 15.8rem;
  #uploader {
    position: absolute;
    bottom: 18px;
    right: -8px;
  }
`;

export const Img = styled.img<iImage>`
  border: 1px solid #0000001a;
  border-radius: 50%;
  width: ${props => props.size || '215px'};
  height: ${props => props.size || '200px'};
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export const Initials = styled.div<iImage>`
  width: ${props => props.size || '100px'};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 60px;
  background: #bab9b9;
  width: 100%;
  height: 100%;
`;
