import React, { useMemo, useState } from 'react';
import { IconProfileHeader } from 'presentation/base/icons';
import { Header, HeaderSimples } from 'presentation/components/Header';
import { ChangePassword, GeneralData } from 'presentation/components/Forms';
import PerfilNavigator from 'presentation/components/ProfileNavigator/ProfileNavigator';
import { Footer } from 'presentation/components/UI';
import { translator } from 'presentation/components/i18n';
import {
  Container,
  Content,
  Left,
  Rigth,
} from './styles/StyledUserProfilePage';

const UserProfilePage: React.FC = (): JSX.Element => {
  const [navigation, setNavigation] = useState<number>(1);

  const RenderSubPages = useMemo(() => {
    switch (navigation) {
      case 1:
        return <GeneralData />;
      case 2:
        return <ChangePassword />;
      default:
        return <div />;
    }
  }, [navigation]);

  return (
    <Container>
      <Header />
      <HeaderSimples
        title={translator('Meu perfil')}
        Icon={IconProfileHeader}
        backDescription={translator('Pesquisas')}
      />
      <Content>
        <Left>
          <PerfilNavigator active={navigation} nav={setNavigation} />
        </Left>
        <Rigth>{RenderSubPages}</Rigth>
      </Content>
      <div style={{ marginTop: '3.8rem' }}>
        <Footer isPadding />
      </div>
    </Container>
  );
};

export default UserProfilePage;
