import React from 'react';

const IconImageMedia = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 36 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.7">
        <path
          d="M32.5699 1.28645C33.7413 1.28645 34.6909 2.21966 34.6909 3.37083V23.9156C34.6909 25.0668 33.7413 26 32.5699 26H2.12105C0.949627 26 0 25.0668 0 23.9156V3.37083C0 2.21966 0.949629 1.28645 2.12106 1.28645H32.5699Z"
          fill="#CC5159"
        />
        <path
          d="M33.8789 0C35.0504 0 36 0.933208 36 2.08438V22.6292C36 23.7803 35.0504 24.7135 33.8789 24.7135H3.43014C2.25872 24.7135 1.30909 23.7803 1.30909 22.6292V2.08438C1.30909 0.933207 2.25872 0 3.43015 0H33.8789Z"
          fill="#FF656F"
        />
        <path
          d="M28.8536 3.27504C28.2146 3.14254 27.5485 3.23987 26.9764 3.54931C26.4043 3.85876 25.9643 4.35975 25.7364 4.96114C25.5085 5.56252 25.5079 6.22432 25.7347 6.82611C25.9615 7.4279 26.4006 7.92968 26.9721 8.24014C27.5436 8.55061 28.2095 8.64912 28.8487 8.51776C29.4879 8.3864 30.0579 8.03389 30.4549 7.52438C30.8519 7.01487 31.0496 6.38223 31.012 5.74156C30.9743 5.1009 30.7039 4.49481 30.2499 4.03356C29.8713 3.65024 29.3853 3.3862 28.8536 3.27504ZM21.2699 17.3485C19.197 14.5158 17.1234 11.6834 15.0491 8.85137C14.7878 8.49465 14.2475 8.4948 13.9864 8.85166L5.24959 20.7933C4.93826 21.2188 5.24771 21.812 5.78103 21.812H17.6732C17.884 21.812 18.0818 21.7123 18.2048 21.5441C19.2266 20.1464 20.2491 18.7491 21.2724 17.3522L21.2699 17.3485ZM26.6902 13.0821C26.4289 12.7255 25.8888 12.7255 25.6276 13.0822C23.7464 15.6519 21.8657 18.2219 19.9856 20.7922C19.6743 21.2177 19.9837 21.8108 20.517 21.8108H31.8019C32.3351 21.8108 32.6446 21.2177 32.3333 20.7922C30.4531 18.2219 28.572 15.6519 26.6902 13.0821Z"
          fill="#DFEBFA"
        />
      </g>
    </svg>
  );
};

export default IconImageMedia;
