import styled, { css } from 'styled-components';

interface ownProps {
  fixedHeight?: boolean;
}

export const DropContainer = styled.div<ownProps>`
  ${({ fixedHeight }) =>
    fixedHeight &&
    css`
      max-height: 10.5rem;
    `}
  cursor: pointer;
  transition: height 0.2s ease;
  box-sizing: border-box;
  background: #eeeeee;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.233rem;
  border-width: 15px;
  width: 100%;
  height: 180px;
  border: 2px dashed #66729c;
  object-fit: contain;
  overflow: hidden;

  img {
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: contain;
  }
`;

export const DropText = styled.div`
  width: 60%;
  text-align: center;
  text-overflow: ellipsis;

  span {
    color: #66729c;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
  }

  p {
    color: #66729c;
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
    line-height: 13px;
  }
`;

export const DropInput = styled.input``;

export const RemoveImage = styled.button`
  background-color: transparent;
  border: none;

  display: flex;
  flex-direction: row;
  margin-bottom: 260px;
  margin-left: 220px;
  position: absolute;
  z-index: 1;

  font-size: 24px;
  font-weight: bolder;
  color: red;
  cursor: pointer;
`;

export const Label = styled.label`
  background-color: transparent;
  cursor: pointer;
  padding: 15px;
  border-radius: 3px;
  color: #66729c;
`;
