/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { CreateMessage } from 'domain/usecases/message/remote';
import { iGetResearchRecords, iGroups } from 'domain/usecases/research/remote';
import { makeRemoteCreateMessage } from 'main/factories/usecases/message/CreateMessage';
import { makeRemoteListResearch } from 'main/factories/usecases/research/ListResearchFactory';
import { IconMessageFlow } from 'presentation/base/icons';
import Translator from 'presentation/components/i18n/Translator';
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Button, Footer } from 'presentation/components/UI';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';
import {
  schema,
  schemaCreateNewMessage,
  schemaNow,
  schemaScheduled,
} from 'validation/validators/NewMessageValidator/NewMessageValidator';
import { Header } from '../../Header';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import {
  ContainerForm,
  ContentStepForm,
  CurrentFlowTitle,
  SubmitView,
} from './styles';

export interface FormInfoToCreateMessage {
  groups: number[];
  name: string;
  channel: string[];
  subject: string;
  description: string;
  schedule?: Date | null;
  content: string;
  recurrence: {
    start?: Date | null;
    days: number[];
    hours: string[];
    timezone: number;
    deadline: Date | null;
  };
}

export interface CreateMessageProps {
  groupsId: number[];
  groups: iGroups[];
  emailSubject: string;
  name: string;
  research: iGetResearchRecords;
  channel: string[];
  subject: string;
  description: string;
  schedule: Date | null;
  start: Date | null;
  content: string;
  time: string;
  sendType: string;
  recurrence: {
    daysId: number[];
    days: number[];
    hours: string[];
    timezone: number;
    deadline: Date | null;
  };
}

export const NewMessageForm: React.FC = (): JSX.Element => {
  const [researchs, setReaserchs] = useState<iGetResearchRecords[]>([]);

  const [createMessage, setCreateMessage] = useState<CreateMessageProps>({
    time: '',
  } as CreateMessageProps);

  const navigate = useNavigate();
  const theme = useTheme();

  const formHook = useForm<FormInfoToCreateMessage>({
    shouldFocusError: true,
    resolver: schemaCreateNewMessage,
    defaultValues: {
      groups: [],
    },
  });

  const createNewMessage = () => {
    let objToSend: FormInfoToCreateMessage = {
      groups: createMessage?.groupsId,
      name: 'Value',
      channel: createMessage?.channel,
      subject: createMessage?.subject,
      description: createMessage?.description,
      content: createMessage?.content,
      schedule:
        createMessage?.schedule !== null ? createMessage?.schedule : undefined,
      recurrence: {
        start: createMessage?.start,
        days: createMessage?.recurrence?.days,
        hours: createMessage?.recurrence?.hours,
        timezone: new Date().getTimezoneOffset(),
        deadline: createMessage?.recurrence?.deadline,
      },
    };

    schema
      .validate(objToSend)
      .then(res => {
        const newDateSchedule = createMessage.schedule
          ? `${createMessage?.schedule?.getFullYear()}-${
              String(createMessage.schedule?.getMonth()).length === 1
                ? `0${Number(createMessage.schedule?.getMonth()) + 1}`
                : Number(createMessage.schedule?.getMonth()) + 1
            }-${
              String(createMessage.schedule?.getDate()).length === 1
                ? `0${Number(createMessage.schedule?.getDate())}`
                : Number(createMessage.schedule?.getDate())
            }T${createMessage?.recurrence?.hours?.[0]}:00`
          : undefined;

        const newDateStart = createMessage.start
          ? `${createMessage?.start?.getFullYear()}-${
              String(createMessage.start?.getMonth()).length === 1
                ? `0${Number(createMessage.start?.getMonth()) + 1}`
                : Number(createMessage.start?.getMonth()) + 1
            }-${
              String(createMessage.start?.getDate()).length === 1
                ? `0${Number(createMessage.start?.getDate())}`
                : Number(createMessage.start?.getDate())
            }T${createMessage?.recurrence?.hours?.[0]}:00`
          : undefined;

        if (newDateStart)
          objToSend = {
            ...objToSend,
            recurrence: {
              ...objToSend.recurrence,
              start: new Date(newDateStart).toISOString() as any,
            },
          };

        let frequency: CreateMessage.Params['body']['frequency'] = 'NOW';

        if (createMessage.time === 'ONCE') {
          frequency = 'SCHEDULED';
        } else if (createMessage.time === 'RECURRENT') {
          frequency = 'RECURRENCE';
        }

        const newObj = {
          ...objToSend,
          frequency,
          recurrence: objToSend.recurrence.days
            ? objToSend.recurrence
            : undefined,
          schedule: objToSend.schedule
            ? new Date(newDateSchedule as string).toISOString()
            : undefined,
        };

        makeRemoteCreateMessage()
          .create({ body: newObj })
          .then(() => {
            toast.success(Translator('Mensagem criada com sucesso'));
            navigate('/messages/list');
          })
          .catch(() => {
            toast.error(Translator('Não foi possível criar a mensagem'));
          });
      })
      .catch(err => {
        console.log('####errorrrr: ', err);
        toast.error(Translator('Por favor, preencha todos os campos.'));
      });

    console.log('####e passou daqui?');
  };

  useEffect(() => {
    makeRemoteListResearch()
      .list({})
      .then(res => {
        setReaserchs(res.records);
      })
      .catch(console.error);
  }, []);

  return (
    <div style={{ backgroundColor: '#fcfcfc' }}>
      <FormProvider {...formHook}>
        <form
          style={{ width: '100%' }}
          onSubmit={formHook.handleSubmit(createNewMessage)}
        >
          <SubmitView>
            <Button
              data-testid="button_cancelNewMessage"
              size="small"
              variant="primary-custom"
              color={theme.colors.secondary.main}
              onClick={() => navigate('/messages/list')}
            >
              {Translator('Cancelar')}
            </Button>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconMessageFlow />
              <CurrentFlowTitle>
                {Translator('Enviar nova mensagem')}
              </CurrentFlowTitle>
            </div>
            <Button
              data-testid="button_createNewMessage"
              size="medium"
              variant="primary-custom"
              color={theme.colors.secondary.main}
              onClick={createNewMessage}
            >
              {Translator('Enviar mensagem')}
            </Button>
          </SubmitView>
          <ContainerForm>
            <ContentStepForm>
              <Step1
                researchs={researchs}
                createMessage={createMessage}
                setCreateMessage={setCreateMessage}
              />
              <Step2
                createMessage={createMessage}
                setCreateMessage={setCreateMessage}
              />
              <Step3
                createMessage={createMessage}
                setCreateMessage={setCreateMessage}
              />
            </ContentStepForm>
            <Footer />
          </ContainerForm>
        </form>
      </FormProvider>
    </div>
  );
};

export default NewMessageForm;
