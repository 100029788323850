import styled from 'styled-components';

interface iBar {
  active: boolean;
}

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const MiniBar = styled.div<iBar>`
  max-width: 40px;
  height: 2px;
  width: 100%;
  background: ${({ theme, active }) => (active ? 'red' : '#EFEFEF')};
`;

export const Bar = styled.div<iBar>`
  height: 2px;
  width: 100%;
  background: ${({ theme, active }) => (active ? 'red' : '#EFEFEF')};
`;
