/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { Container } from './styles/StyledIcon';

interface ownProps {
  src: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  popover?: string;
  color?: string;
  width?: string;
  height?: string;
  onClick?: () => any;
  className?: string;
}

const Icon: React.FC<ownProps> = props => {
  const Image = props.src;

  return (
    <Container
      width={props.width}
      height={props.height}
      color={props.color}
      onClick={props.onClick}
    >
      <Image />
    </Container>
  );
};

export default Icon;
