import styled, { css } from 'styled-components';

interface ownProps {
  active: boolean;
}

export const ContainerOptionsMedia = styled.div`
  display: flex;
  height: 4rem;
  border-radius: 5px;
  overflow: hidden;
`;

export const OptionsMedia = styled.div<ownProps>`
  min-width: 12.7rem;
  display: flex;
  gap: 9px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background: ${({ active, theme }) =>
    active ? theme.colors.primary.main : theme.colors.background.main};
  color: ${({ active, theme }) =>
    active ? theme.colors.background.main : '#444A57'};
  font-weight: ${({ active }) => active && 'bold'};
  border: ${({ active }) => !active && '1px solid #EFEFEF'};

  ${({ active }) =>
    !active &&
    css`
      :hover {
        color: ${({ theme }) => theme.colors.primary.main};
        svg {
          fill: ${({ theme }) => theme.colors.primary.main};
        }
      }
    `}

  ${({ active }) =>
    active &&
    css`
      svg {
        fill: #fff;
        stroke: #fff;
      }
    `}
`;
