import styled from 'styled-components';

export const ContainerModal = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  z-index: 3;
`;

export const Container = styled.div`
  border-radius: 5px;
  border: 1px solid #efefef;
  background: #ffffff;
  width: 369px;
`;

export const Header = styled.header`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
  border-bottom: 1px solid #efefef;
`;

export const Title = styled.h3`
  color: #1c1413;
  font-weight: bold;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 37px;
`;

export const Message = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
`;

export const Text = styled.p`
  width: 70%;
  color: #656a6e;
  font-family: 'Roboto';
  text-align: center;
`;

export const ActionCancel = styled.p`
  color: #656a6e;
  font-family: 'Roboto';
  font-weight: bold;
  text-align: center;
`;

export const Footer = styled.footer`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 18px;
  margin-top: 37px;
  padding: 0 36px 30px 35px;
`;
