import styled from 'styled-components';

export const TitleResearchBox = styled.div`
  width: 100%;
  padding: 1.6rem;
  margin-top: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.5rem;
  border: 0.1rem solid ${({ theme }) => theme.colors.background.grey};
  font: ${({ theme }) => theme.typography.h7};
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.typography.lightGray};
`;

export const GroupTitleView = styled.div`
  display: flex;
  align-items: center;

  .icon {
    margin-right: 0.9rem;
  }
`;

export const GroupControlView = styled.div`
  display: flex;
  align-items: center;
`;
