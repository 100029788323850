import { ConfirmNewPassword as UseCaseRemoteConfirmNewPassword } from 'domain/usecases/auth/remote';

import { RemoteConfirmNewPassword } from 'data/repository/auth';
import { makeApiUrl, makeHttpClient } from 'main/factories/http';

export const makeRemoteConfirmNewPassword =
  (): UseCaseRemoteConfirmNewPassword =>
    new RemoteConfirmNewPassword(
      makeApiUrl('/password/validate'),
      makeHttpClient(),
    );
