import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background: #f3f3f3;
`;

export const ContentHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  color: #656a6e;
  font-size: 1.6rem;
  font-weight: bold;
  justify-content: center;
  height: 36px;
  gap: 8px;
`;

export const ContentHeaderBackDescription = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  color: #656a6e;
  font-size: 1.6rem;
  font-weight: bold;
  justify-content: flex-start;
  height: 5rem;
  gap: 8px;
`;

export const ContentBackDescription = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  color: #656a6e;
  font-size: 1.6rem;
  font-weight: bold;
  padding-left: 7.2rem;
  justify-content: space-between;
  height: 36px;
  gap: 8px;
`;

export const Back = styled.div`
  display: flex;
  width: 32%;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  color: #7a7a7a;
  font-size: 1.6rem;
  cursor: pointer;
  font-weight: bold;
  gap: 8px;
`;
