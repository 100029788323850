import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Body = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 80px;
`;

export const ContainerRight = styled.div`
  padding: 47px 36px 33px;
  max-width: 412px;
  display: flex;
  border: 1px solid #efefef;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  a {
    color: #005bc8;
    font-weight: bold;
    text-decoration: underline;
    font-family: 'Roboto';
    cursor: pointer;
  }
`;

export const ContentRight = styled.form`
  max-width: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  #loginSubmit {
    margin-top: 2rem;
  }

  a {
    font-weight: 400;
  }
`;

export const ContentLeft = styled.div`
  width: 40rem;
  height: 40rem;
`;

export const Title = styled.div`
  font-weight: bold;
  color: #7a7a7a;
  font-family: 'Roboto';
`;

export const PrivacyPolice = styled.div`
  margin-top: 3.2rem;
  display: flex;
  justify-content: flex-start;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #bdbdbd;

  a {
    text-decoration: underline;
    font-weight: bold;
    color: #7a7a7a;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
