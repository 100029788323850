import styled from 'styled-components';

interface iContainer {
  isPadding?: boolean;
}
export const Container = styled.footer<iContainer>`
  width: 100%;
  height: 36px;
  padding: ${({ isPadding }) => (isPadding ? '0 7.2rem 0 7.2rem' : '0')};
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Version = styled.a`
  font-size: 12px;
  color: #bab9b9;
  font-family: 'Roboto', sans-serif;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    color: black;
  }
`;

export const PoweredBy = styled.div``;

export const TooltipTitle = styled.h4`
  font: ${({ theme }) => theme.typography.h4};
`;
