import React from 'react';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import { deepOrange } from '@material-ui/core/colors';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';

interface avatarBadger extends React.HTMLAttributes<HTMLElement> {
  // eslint-disable-next-line react/require-default-props
  src?: string;
  name: string;
  size: 'small' | 'medium' | 'large' | 'root';
  status: boolean;
}

interface StyleProps {
  status: boolean;
}

const StyledBadge = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    badge: {
      backgroundColor: ({ status }) => (status ? '#44b700' : '#EC5C52'),
      color: ({ status }) => (status ? '#44b700' : '#EC5C52'),
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }),
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: 2,
      },
    },
    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
    },
    small: {
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
    medium: {
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
    large: {
      width: theme.spacing(15),
      height: theme.spacing(15),
    },
  }),
);

const AvatarBadger: React.FC<avatarBadger> = ({
  src,
  name,
  size,
  status = true,
}) => {
  const classes = useStyles();
  const styledBadgeClasses = StyledBadge({ status });

  return (
    <div className={classes.root}>
      <Badge
        classes={styledBadgeClasses}
        overlap="circle"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        variant="dot"
      >
        {src && <Avatar alt={name} src={src} className={classes[size]} />}
        {!src && (
          <Avatar
            alt={name}
            src="/broken-image.jpg"
            className={classes[size]}
          />
        )}
      </Badge>
      <Badge
        overlap="circle"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        {/* Avatar */}
      </Badge>
    </div>
  );
};

export default AvatarBadger;
