import styled from 'styled-components';

export const ContainerModal = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  z-index: 3;
`;

export const Container = styled.div`
  width: 860px;
  min-height: 450px;
  padding: 0px 23px 0px 26px;
  border-radius: 5px;
  border: 1px solid #efefef;
  background: #ffffff;
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 28px 16px 0;
  border-bottom: 1px solid #efefef;

  span {
    font-size: 18px;
    font-weight: bold;
  }
`;

export const InfoDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 25px;
  padding-left: 20px;
  gap: 8px;

  span {
    font-size: 16px;
    color: ${({ theme }) => theme.colors.typography.dark};
  }

  .horizontal-align {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
  }
`;

export const ContentMessageDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin-top: 10px;
  gap: 12px;

  span {
    justify-content: flex-start;
    font-size: 18px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.typography.darkGray};
  }
`;
