/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-one-expression-per-line */
import { IconNewCalendar } from 'presentation/base/icons';
import Translator from 'presentation/components/i18n/Translator';
import Input from 'presentation/components/UI/input';
import React from 'react';
import InputMask from 'react-input-mask';
import { CreateMessageProps } from '..';
import {
  CheckBoxContent,
  Chip,
  ChipContent,
  ContainerSteps,
  NormalTitle,
  OptionsContent,
  SubTitleSteps,
  TitleSteps,
} from '../styles';
import Datepicker from './Datepicker';
import {
  CheckboxChecked,
  CheckboxView,
  InputContent,
  InputView,
} from './styles';

interface Step2Props {
  createMessage: CreateMessageProps;
  setCreateMessage: (prevState: CreateMessageProps) => any;
}

const Step2: React.FC<Step2Props> = ({ createMessage, setCreateMessage }) => {
  // array with week's day
  const weekDays = {
    0: Translator('Dom'),
    1: Translator('Seg'),
    2: Translator('Ter'),
    3: Translator('Qua'),
    4: Translator('Qui'),
    5: Translator('Sex'),
    6: Translator('Sáb'),
  };

  const handleAddWeekDay = (weekId: string) => {
    if (createMessage?.recurrence?.days?.includes(Number(weekId))) {
      const arrayFiltered = createMessage?.recurrence?.days?.filter(
        item => String(item) !== weekId,
      );

      setCreateMessage({
        ...createMessage,
        recurrence: { ...createMessage.recurrence, days: arrayFiltered },
      });

      return;
    }

    const arrayFormatted =
      createMessage?.recurrence?.days?.length > 0
        ? [...createMessage?.recurrence?.days, Number(weekId)]
        : [Number(weekId)];

    setCreateMessage({
      ...createMessage,
      recurrence: { ...createMessage.recurrence, days: arrayFormatted },
    });
  };

  const handleChangeDateDeadline = (date: Date | null) => {
    setCreateMessage({
      ...createMessage,
      recurrence: {
        ...createMessage.recurrence,
        deadline: date,
      },
    });
  };

  const getCurrenceValue = () => {
    let result;

    if (createMessage.time === 'ONCE') {
      result =
        createMessage?.schedule !== null ? createMessage?.schedule : new Date();
    } else {
      result =
        createMessage?.start !== null ? createMessage?.start : new Date();
    }

    return result;
  };

  const handleChangeDateSchedule = (date: Date | null) => {
    if (createMessage.time === 'ONCE') {
      setCreateMessage({
        ...createMessage,
        schedule: date,
      });
    } else {
      console.log('#### entrou aqui data', createMessage);
      setCreateMessage({
        ...createMessage,
        start: date,
      });
    }
  };

  return (
    <ContainerSteps>
      <TitleSteps>{Translator('2. Envio')}</TitleSteps>
      <SubTitleSteps style={{ marginBottom: 12 }}>
        {Translator('Tipo de envio')}
        <p style={{ color: 'red' }}>*</p>
      </SubTitleSteps>
      <OptionsContent>
        <CheckBoxContent
          onClick={() =>
            setCreateMessage({
              ...createMessage,
              sendType: 'NOW',
              time: '',
              recurrence: {} as CreateMessageProps['recurrence'],
            })
          }
        >
          <CheckboxView
            data-testid="checkbox_sendType_now"
            active={createMessage.sendType === 'NOW'}
          >
            <CheckboxChecked active={createMessage.sendType === 'NOW'} />
          </CheckboxView>
          <NormalTitle>{Translator('Imediato')}</NormalTitle>
        </CheckBoxContent>
        <CheckBoxContent
          onClick={() =>
            setCreateMessage({ ...createMessage, sendType: 'SCHEDULE' })
          }
        >
          <CheckboxView
            data-testid="checkbox_sendType_schedule"
            active={createMessage.sendType === 'SCHEDULE'}
          >
            <CheckboxChecked active={createMessage.sendType === 'SCHEDULE'} />
          </CheckboxView>
          <NormalTitle>{Translator('Programado')}</NormalTitle>
        </CheckBoxContent>
      </OptionsContent>

      {createMessage.sendType === 'SCHEDULE' ? (
        <>
          <SubTitleSteps style={{ marginBottom: 12 }}>
            Frequência
            <p style={{ color: 'red' }}>*</p>
          </SubTitleSteps>
          <OptionsContent>
            <CheckBoxContent
              onClick={() =>
                setCreateMessage({
                  ...createMessage,
                  time: 'ONCE',
                  start: null,
                  recurrence: {} as CreateMessageProps['recurrence'],
                })
              }
            >
              <CheckboxView
                data-testid="checkbox_frequence_once"
                active={createMessage.time === 'ONCE'}
              >
                <CheckboxChecked active={createMessage.time === 'ONCE'} />
              </CheckboxView>
              <NormalTitle>{Translator('Única vez')}</NormalTitle>
            </CheckBoxContent>
            <CheckBoxContent
              onClick={() =>
                setCreateMessage({
                  ...createMessage,
                  time: 'RECURRENT',
                  schedule: null,
                })
              }
            >
              <CheckboxView
                data-testid="checkbox_frequence_recurrent"
                active={createMessage.time === 'RECURRENT'}
              >
                <CheckboxChecked active={createMessage.time === 'RECURRENT'} />
              </CheckboxView>
              <NormalTitle>{Translator('Recorrente')}</NormalTitle>
            </CheckBoxContent>
          </OptionsContent>
          <InputView>
            <InputContent style={{ width: 180 }}>
              <SubTitleSteps style={{ marginBottom: 12 }}>
                {createMessage?.time === 'RECURRENT'
                  ? Translator('Iniciar em')
                  : Translator('Enviar em')}
                <p style={{ color: 'red' }}>*</p>
              </SubTitleSteps>

              <div
                data-testid="startDate"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  border: '1px solid #BAB9B9',
                  borderRadius: '5px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#f3f3f3',
                }}
              >
                <Datepicker
                  disabled={createMessage.time === ''}
                  date={
                    createMessage.time === 'ONCE'
                      ? createMessage?.schedule !== null
                        ? createMessage?.schedule
                        : new Date()
                      : createMessage?.start !== null
                      ? createMessage?.start
                      : new Date()
                  }
                  onDateChange={handleChangeDateSchedule}
                />
                <button
                  type="button"
                  style={{
                    margin: '8px',
                  }}
                >
                  <IconNewCalendar
                    width="20px"
                    height="20px"
                    onClick={() => (
                      <Datepicker
                        date={
                          createMessage.time === 'ONCE'
                            ? createMessage?.schedule !== null
                              ? createMessage?.schedule
                              : new Date()
                            : createMessage?.start !== null
                            ? createMessage?.start
                            : new Date()
                        }
                        onDateChange={handleChangeDateSchedule}
                      />
                    )}
                  />
                </button>
              </div>
            </InputContent>
            <InputContent style={{ width: 130 }}>
              <SubTitleSteps style={{ marginBottom: 12 }}>
                {Translator('Horário')}
                <p style={{ color: 'red' }}>*</p>
              </SubTitleSteps>
              <InputMask
                data-testid="inputTime"
                disabled={createMessage?.time === ''}
                mask="99:99"
                onChange={(e: any) =>
                  setCreateMessage({
                    ...createMessage,
                    recurrence: {
                      ...createMessage.recurrence,
                      hours: [e.target.value],
                    },
                  })
                }
              >
                <Input id="input_time" name="time" placeholder="99:99" />
              </InputMask>
            </InputContent>
          </InputView>
        </>
      ) : (
        <div />
      )}
      {createMessage?.time === 'RECURRENT' && (
        <>
          <SubTitleSteps style={{ marginBottom: 12 }}>
            {Translator('Repetir em')}
            <p style={{ color: 'red' }}>*</p>
          </SubTitleSteps>
          <ChipContent>
            {Object.entries(weekDays).map(([key, value]) => (
              <Chip
                data-testid={`chip_weekday_${key}`}
                key={key}
                active={createMessage?.recurrence?.days?.includes(Number(key))}
                onClick={() => handleAddWeekDay(key)}
              >
                {value}
              </Chip>
            ))}
          </ChipContent>
          <SubTitleSteps style={{ marginBottom: 12 }}>
            {Translator('Finalizar em')}
            <p style={{ color: 'red' }}>*</p>
          </SubTitleSteps>
          <InputContent style={{ width: 180 }}>
            <div
              data-testid="deadline"
              style={{
                display: 'flex',
                flexDirection: 'row',
                border: '1px solid #BAB9B9',
                borderRadius: '5px',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#f3f3f3',
              }}
            >
              <Datepicker
                date={
                  createMessage?.recurrence?.deadline !== null
                    ? createMessage?.recurrence?.deadline
                    : new Date()
                }
                onDateChange={handleChangeDateDeadline}
              />
              <button
                type="button"
                style={{
                  margin: '8px',
                }}
              >
                <IconNewCalendar
                  width="20px"
                  height="20px"
                  onClick={() => (
                    <Datepicker
                      date={
                        createMessage?.recurrence?.deadline !== null
                          ? createMessage?.recurrence?.deadline
                          : new Date()
                      }
                      onDateChange={handleChangeDateDeadline}
                    />
                  )}
                />
              </button>
            </div>
          </InputContent>
        </>
      )}
    </ContainerSteps>
  );
};

export default Step2;
