/* eslint-disable no-useless-return */
/* eslint-disable react/jsx-one-expression-per-line */
import id from 'date-fns/esm/locale/id/index.js';
import { iGetResearchRecords, iGroups } from 'domain/usecases/research/remote';
import Translator from 'presentation/components/i18n/Translator';
import { Select } from 'presentation/components/UI';
import React, { useCallback, useState } from 'react';
import { CreateMessageProps } from '..';
import {
  Chip,
  ChipContent,
  ContainerSteps,
  SubTitleSteps,
  TitleSteps,
} from '../styles';

interface Step1Props {
  createMessage: CreateMessageProps;
  setCreateMessage: (prevState: CreateMessageProps) => any;
  researchs: iGetResearchRecords[];
}

const Step1: React.FC<Step1Props> = ({
  createMessage,
  setCreateMessage,
  researchs,
}) => {
  const handleAddGroupsToMessage = useCallback(
    (groupId: string) => {
      const group = createMessage?.research?.groups?.findIndex(
        item => String(item?.id) === groupId,
      );

      if (createMessage?.groupsId?.includes(Number(groupId))) {
        const arrayFiltered = createMessage?.groupsId?.filter(
          item => String(item) !== groupId,
        );

        setCreateMessage({
          ...createMessage,
          groupsId: arrayFiltered,
        });

        return;
      }

      if (group >= 0) {
        setCreateMessage({
          ...createMessage,
          groupsId:
            createMessage?.groupsId?.length > 0
              ? [...createMessage?.groupsId, Number(groupId)]
              : [Number(groupId)],
        });
      }
    },
    [createMessage, setCreateMessage],
  );

  const handleSelectGroupsFromResearch = useCallback(
    (researchId: string) => {
      if (researchId === '-1') return;
      const research = researchs?.find(item => String(item.id) === researchId);

      if (research) {
        setCreateMessage({ ...createMessage, research });
      }
    },
    [createMessage, researchs, setCreateMessage],
  );

  return (
    <ContainerSteps>
      <TitleSteps>{Translator('1. Contexto')}</TitleSteps>
      <SubTitleSteps>
        {Translator('Pesquisa')}
        <p style={{ color: 'red' }}>*</p>
      </SubTitleSteps>
      <Select
        data-testid="select_research"
        onChange={e => handleSelectGroupsFromResearch(e.target.value)}
        value={createMessage?.research?.id}
      >
        <option data-testid="option_research_default" value={-1}>
          {Translator('Selecione')}
        </option>
        {researchs?.map(item => (
          <option
            data-testid={`option_research_${item.id}`}
            key={item.id}
            value={item.id}
          >
            {item.name}
          </option>
        ))}
      </Select>

      <SubTitleSteps>
        {Translator('Grupos')}
        <p style={{ color: 'red' }}>*</p>
      </SubTitleSteps>
      <ChipContent>
        {createMessage?.research?.groups?.map(item => (
          <Chip
            data-testid={`chip_group_${item.id}`}
            key={item.id}
            active={createMessage?.groupsId?.includes(Number(item.id))}
            onClick={() => handleAddGroupsToMessage(String(item.id))}
          >
            {item.name}
          </Chip>
        ))}
      </ChipContent>
    </ContainerSteps>
  );
};

export default Step1;
