import styled from 'styled-components';

import IconPencil from 'presentation/base/icons/iconPencil.svg';
import IconTrash from 'presentation/base/icons/iconTrash.svg';
import IconTrashHover from 'presentation/base/icons/iconTrashHover.svg';
import IconEditHover from 'presentation/base/icons/iconEditHover.svg';
import IconDownloadImageModal from 'presentation/base/icons/iconDownloadModal.svg';
import IconReplaceImageModal from 'presentation/base/icons/iconReplaceImageModal.svg';

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const Container = styled.div``;

export const Delete = styled.div`
  margin: 0px 3px;
  background-image: url(${IconTrash});
  width: 26px;
  height: 26px;
  cursor: pointer;
  :hover {
    background-image: url(${IconTrashHover});
  }
`;

export const EditIcon = styled.div`
  margin: 0px 3px;
  background-image: url(${IconPencil});
  background-repeat: no-repeat;
  width: 26px;
  height: 26px;
  cursor: pointer;
  :hover {
    background-image: url(${IconEditHover});
  }
`;

export const ReplaceImage = styled.div`
  margin: 0px 3px;
  background-image: url(${IconReplaceImageModal});
  background-repeat: no-repeat;
  width: 26px;
  height: 26px;
  cursor: pointer;
`;

export const DownloadImage = styled.div`
  margin: 0px 3px;
  background-image: url(${IconDownloadImageModal});
  background-repeat: no-repeat;
  width: 26px;
  height: 26px;
  cursor: pointer;
`;

export const DeleteImage = styled.div`
  margin: 0px 3px;
  background-image: url(${IconTrash});
  background-repeat: no-repeat;
  width: 26px;
  height: 26px;
  cursor: pointer;
`;

export const Media = styled.div`
  display: flex;
  height: 32px;
  gap: 10px;
  align-items: center;
  font-weight: bold;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
  color: #0b77cd;
  border: 1px solid #0b77cd;
  border-radius: 5px;
  white-space: nowrap;

  :hover {
    background: #0b77cd;
    color: white;
    font-weight: bold;
    path {
      fill: #ffffff;
    }
  }
`;

export const TooltipTitle = styled.h4`
  font: ${({ theme }) => theme.typography.h4};
`;
