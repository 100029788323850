import styled from 'styled-components';

interface iBar {
  active: boolean;
  current: boolean;
}

interface iContent {
  active: boolean;
}

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div<iContent>`
  position: absolute;
  white-space: nowrap;
  top: -30px;
  color: ${({ theme, active }) => (active ? 'red' : '#7A7A7A')};
  font-weight: ${({ active }) => active && '600'};
`;

export const Point = styled.div<iBar>`
  border-radius: 100%;
  width: ${props => (props.current ? '16px' : '12px')};
  height: ${props => (props.current ? '16px' : '12px')};
  background: ${({ theme, active }) => (active ? 'red' : '#EFEFEF')};
`;
