import { iAuth } from 'domain/interfaces/models/Auth';
import { AuthTypes } from 'domain/interfaces/redux/auth/types';
import { AuthActions } from './actions';

export const initialState: iAuth = {
  loading: false,
  accessToken: '',
  accessTokenExpireIn: '',
  refreshToken: '',
  refreshTokenExpireIn: '',
  user: undefined,
};

const reducer = (state = initialState, action: AuthActions): iAuth => {
  switch (action.type) {
    case AuthTypes.LOGIN:
      return { ...state, loading: true };
    case AuthTypes.LOGIN_FAILED:
      return { ...state, loading: false };
    case AuthTypes.LOGIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };

    case AuthTypes.REFRESH:
      return { ...state, loading: true };
    case AuthTypes.REFRESH_FAILED:
      return { ...state, loading: false };
    case AuthTypes.REFRESH_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    }

    case AuthTypes.UPDATE_USER:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
        loading: false,
      };

    case AuthTypes.LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
