/* eslint-disable react/no-array-index-key */

import { iStore } from 'domain/interfaces/models';
import { GetByIdUser } from 'domain/usecases/user/remote';

import { makeReduxLogoutAuth } from 'main/factories/usecases/auth/LogoutAuthFactory';
import { makeRemoteGetByIdUser } from 'main/factories/usecases/user/GetByIdUserFactory';
import { navigate } from 'main/routes/_CustomRouter';
import {
  IconExit,
  IconLogoColgateAlternative,
  IconPerfil,
} from 'presentation/base/icons';
import { AvatarRoles } from 'presentation/components/avatar-roles';
import { useAccess } from 'presentation/hooks/access';
import React, { HTMLAttributes, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Translator from '../i18n/Translator';
import { PopoverHeader } from '../popover';
import {
  AvatarDiv,
  Container,
  Content,
  DivLogo,
  Drop,
  Menu,
  NavigationDiv,
  Options,
  UserOptions,
} from './styles/StyledHeader';

interface propsHeaderMenu extends HTMLAttributes<HTMLDivElement> {
  setState: (e: number) => void;
  active: number;
}

const Header: React.FC = (): JSX.Element => {
  const { user } = useSelector((store: iStore) => store.auth);
  const { hasAccess } = useAccess();

  const { pathname } = useLocation();
  // const [userResponse, setUserResponse] = useState({} as GetByIdUser.Model);

  // useEffect(() => {
  //   makeRemoteGetByIdUser()
  //     .getById({
  //       user: Number(user?.id),
  //     })
  //     .then(res => {
  //       setUserResponse(res);
  //     })
  //     .catch(err => {
  //       console.log('err: ', err);
  //     });
  // }, [user?.id]);

  const options: { title: string; path: string; willBeRender?: boolean }[] =
    hasAccess(['ORG.UPDATE', 'ORG.CREATE'])
      ? [
          { title: Translator('Pesquisas'), path: '/home' },
          {
            title: Translator('Biblioteca de Mídias'),
            path: '/medias',
          },
          { title: 'Mensagens', path: '/messages/list' },

          { title: Translator('Organizações'), path: '/organizations' },
        ]
      : [
          { title: Translator('Pesquisas'), path: '/home' },
          { title: 'Mensagens', path: '/messages/list' },
          {
            title: Translator('Biblioteca de mídias'),
            path: '/medias',
          },
        ];

  const handleNavigate = useCallback((path: string) => {
    navigate(path);
  }, []);

  const userOptions = (
    <UserOptions>
      <button type="button">
        <Drop
          id="btn_login"
          data-testid="btn-login"
          onClick={() => {
            navigate(`/profile/${user?.id}`);
          }}
        >
          <IconPerfil />
          <span>{Translator('Perfil')}</span>
        </Drop>
      </button>
      <Drop
        id="btn_logout"
        data-testid="btn-logout"
        onClick={() => {
          navigate('/');
          makeReduxLogoutAuth().logout({});
        }}
      >
        <IconExit />
        <span>{Translator('Sair')}</span>
      </Drop>
    </UserOptions>
  );

  return (
    <Container>
      <Content>
        <DivLogo>
          <IconLogoColgateAlternative width="200px" height="40px" />
          <NavigationDiv>
            <Options>
              {options.map((elem, index) => (
                <Menu
                  data-testid={`menu_option_${index}`}
                  key={`menu_option_${index}`}
                  active={pathname.startsWith(elem.path)}
                  onClick={() => handleNavigate(elem.path)}
                >
                  {elem.title}
                </Menu>
              ))}
            </Options>
          </NavigationDiv>
        </DivLogo>
        <PopoverHeader
          content={userOptions}
          originX="right"
          originY="bottom"
          positionY="top"
          positionX="right"
        >
          <AvatarDiv>
            <AvatarRoles
              name={user?.fullname ?? 'Usuário padrão'}
              role={user?.role ?? 'ORGANIZER'}
              src={user?.avatar}
            />
          </AvatarDiv>
        </PopoverHeader>
      </Content>
    </Container>
  );
};

export default Header;
