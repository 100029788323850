import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { UpdateResearch as UsecaseRemoteUpdateResearch } from 'domain/usecases/research/remote';
// import { UpdateResearch as UsecaseReduxUpdateResearch } from 'domain/usecases/research/redux';

import { RemoteUpdateResearch } from 'data/repository/research';
// import { ReduxUpdateResearch } from 'data/store/reducer/research/usecases';

/**
 * send request via API.
 */
export const makeRemoteUpdateResearch = (): UsecaseRemoteUpdateResearch =>
  new RemoteUpdateResearch(
    makeApiUrl('/researches/{research}'),
    makeHttpClient(),
  );

/**
 * send request via REDUX.
 */
/* export const makeReduxUpdateResearch =
  (): UsecaseReduxUpdateResearch =>
    new ReduxUpdateResearch(); */
