import styled, { css } from 'styled-components';

interface iPaginationContainer {
  disableMargin?: boolean;
}

export const PaginationContainer = styled.div<iPaginationContainer>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: ${({ disableMargin }) => !disableMargin && '2rem'};
`;

export const Button = styled.button<{ active?: boolean }>`
  display: flex;
  width: 3rem;
  height: 3rem;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  border-radius: 0.5rem;
  padding: 0.5rem;
  cursor: pointer;

  &:hover {
    ${({ active }) =>
      !active &&
      css`
        background: ${({ theme }) => theme.colors.background.grey};
      `}
  }

  svg {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;

export const PageButton = styled(Button)`
  ${({ active, theme }) =>
    active &&
    css`
      background: ${theme.colors.primary.main};
      color: ${theme.colors.background.main};
      font-weight: bold;
    `};
`;
