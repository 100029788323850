import { Dispatch } from 'redux';
import store from 'data/store';
import { listRequest } from 'data/store/reducer/org/actions/list';

import { iActionList } from 'domain/interfaces/redux/org/list';

import { ListOrg } from 'domain/usecases/org/redux';

export class ReduxListOrg implements ListOrg {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  list = (params: ListOrg.Params): iActionList =>
    this.send(listRequest(params));
}
