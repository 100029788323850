/* eslint-disable react/require-default-props */
import { Switch } from '@chakra-ui/react';
import axios from 'axios';
import { translator } from 'presentation/components/i18n';
import React, { useEffect, useState } from 'react';
import {
  ContainerCard,
  ContentCard,
  ContentChildren,
  FooterCard,
} from './styles';

interface propsCard {
  type?: 'VIDEO' | 'IMAGE' | 'FORM' | 'AUDIO' | 'TEXT';
  name: string;
  disabledToGroup: Boolean;
  onChecked: (id: number) => void;
  id: number;
}

export const downloadFile = (url: string, fileName: string): void => {
  axios
    .get(url, {
      headers: { 'Content-Type': 'application/octet-stream' },
      responseType: 'blob',
    })
    .then(response => {
      const link = document.createElement('a');
      const urlToDownload = window.URL.createObjectURL(response.data);

      link.href = urlToDownload;
      link.download = fileName;
      link.click();
    })
    .catch(err => {
      console.error('Falha ao realizar download do arquivo.', err);
    });
};

export const copyLink = (
  url: string,
  handleCopy: (state: string) => void,
): void => {
  handleCopy(translator('Link copiado!'));
  document.execCommand('copy', true, url);
  navigator.clipboard.writeText(url);

  setTimeout(() => {
    handleCopy('Copiar link');
  }, 2000);
};

export const newTab = (url: string): void => {
  window.open(url, '_blank');
};

const Card: React.FC<propsCard> = ({
  children,
  name,
  type,
  id,
  disabledToGroup,
  onChecked,
}): JSX.Element => {
  const [isChecked, setIsChecked] = useState(!disabledToGroup);

  useEffect(() => {
    setIsChecked(prevState => !prevState);
  }, [disabledToGroup]);

  return (
    <ContainerCard>
      <ContentCard isVideo={type === 'VIDEO'}>
        <ContentChildren isVideo={type === 'VIDEO'}>{children}</ContentChildren>
      </ContentCard>
      <FooterCard>
        <Switch
          data-testid={`switch_${id}`}
          className="switch-media-visible"
          prefix="sc"
          // TODO: While we don't fix version issues on Develop/Homolog
          checked={!isChecked}
          isChecked={!isChecked}
          onChange={(): void => onChecked(id)}
          m={0}
          mr={2}
          size="lg"
          _checked={{
            backgroundColor: '#0b77cd',
            borderRadius: '50%',
          }}
        />
        {name}
        {/* <div>
          <Popover>
            <ContainerPopoverOptions>
              {selectOptionsMedia()}
            </ContainerPopoverOptions>
          </Popover>
        </div> */}
      </FooterCard>
    </ContainerCard>
  );
};
export default Card;
