import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  width: 100%;
  margin-top: 16px;
  padding: 0 5.8rem 0 7.2rem;
  display: flex;
  gap: 35px;
`;

export const Left = styled.div`
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 34px 26px 34px 25px;
`;

export const Rigth = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid #efefef;
  border-radius: 5px;
  width: 100%;
  padding-top: 44px;
`;
