import styled from 'styled-components';

export const ContainerModal = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  z-index: 3;
`;

export const Container = styled.div`
  width: 860px;
  min-height: 591px;
  padding: 0px 23px 0px 26px;
  border-radius: 5px;
  border: 1px solid #efefef;
  background: #ffffff;
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0px 19px 0px;
  margin-bottom: 24px;
  border-bottom: 1px solid #efefef;

  span {
    font-size: 20px;
    font-weight: bold;
    color: #1c1413;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  border: 2px solid blue;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 0 36px;
  gap: 15px;
`;

export const SearchDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-size: 16px;
    color: #0b77cd;
  }
`;

export const Footer = styled.footer`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 28px;
  margin-top: 48px;
`;
