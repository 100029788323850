/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { FormEvent, useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Input from 'presentation/components/UI/input/index';
import { Button, Footer } from 'presentation/components/UI';
import { Icon } from 'presentation/components/icon';
import {
  IconLogo,
  IconEmail,
  IconPadlock,
  ImageLogin,
  IconLoginLaoha,
  IconLoginAlternativeLaoha,
  IconLogoColgateLarge,
} from 'presentation/base/icons';

import {
  makeReduxLogin,
  makeRemoteLogin,
} from 'main/factories/usecases/auth/LoginFactory';
import { toast } from 'react-toastify';
import { translator } from 'presentation/components/i18n';
import {
  Container,
  Header,
  Body,
  ContentRight,
  Title,
  ContentLeft,
  ContainerRight,
  PrivacyPolice,
  Content,
} from './styles/LoginPageStyled';

const Login: React.FC = (): JSX.Element => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = useCallback(
    (e: FormEvent): void => {
      e.preventDefault();

      if (email === '' || password === '') {
        toast.error(translator('Preencha todos os campos corretamente'));
      } else {
        makeReduxLogin().login({
          email,
          password,
        });
      }
    },
    [email, password],
  );

  return (
    <Container>
      <Body>
        <Content>
          <ContainerRight>
            <ContentRight id="loginAuthentication" onSubmit={handleSubmit}>
              <Header>
                <Icon src={IconLogoColgateLarge} width="200px" height="100px" />
              </Header>
              <Title>{translator('Bem-vindo(a)!')}</Title>

              <Input
                id="loginInput"
                data-testid="input_login"
                name="email"
                type="text"
                width="338px"
                icon={IconEmail}
                label={translator('E-mail')}
                placeholder={translator('Digite o email cadastrado')}
                value={email}
                onChange={(e): void => setEmail(e.target.value)}
                autoFocus
              />

              <Input
                id="loginPassword"
                data-testid="input_password"
                type="password"
                name="password"
                width="338px"
                label={translator('Senha')}
                icon={IconPadlock}
                placeholder={translator('Digite sua senha')}
                value={password}
                onChange={(e): void => setPassword(e.target.value)}
              />

              <Button
                id="loginSubmit"
                data-testid="btn_login"
                type="submit"
                rounded
                height="48px"
              >
                {translator('Entrar')}
              </Button>
              <Link
                id="loginForgotPass"
                data-testid="btn_forgot"
                to="/password/recovery"
              >
                {translator('Esqueci minha senha')}
              </Link>
            </ContentRight>
          </ContainerRight>
          <PrivacyPolice>
            <p data-testid="policies">
              {`@ ${new Date().getFullYear()} Copyright | `}
              {/* <a href="/" target="_blank"> */}
              {translator('Políticas de privacidade')}
              {/* </a> */}
              {translator(' e ')}
              {/* <a href="/" target="_blank"> */}
              {translator('Termos de uso')}
              {/* </a> */}
            </p>
          </PrivacyPolice>
        </Content>
        <ContentLeft>
          <IconLoginLaoha />
        </ContentLeft>
      </Body>
    </Container>
  );
};

export default Login;
