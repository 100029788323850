import axios from 'axios';
import { iStore } from 'domain/interfaces/models';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { makeRemoteDeleteMedia } from 'main/factories/usecases/media/DeleteMediaFactory';
import { makeReduxListMedia } from 'main/factories/usecases/media/ListMediaFactory';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/UpdateFactory';
import {
  IconCopyLink,
  IconDeleteImage,
  IconDownload,
  IconNewPage,
  IconPdf,
  IconView,
  IconEdit,
} from 'presentation/base/icons';
import { Popover } from 'presentation/components/popover';
import { useAccess } from 'presentation/hooks/access';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { closeModal } from 'utils';
import Translator from '../i18n/Translator';
import {
  ContainerCard,
  ContainerPopoverOptions,
  ContentCard,
  ContentChildren,
  FooterCard,
  PopoverOptions,
} from './styles/StyledCards';

interface propsCard {
  // eslint-disable-next-line react/require-default-props
  type?: 'VIDEO' | 'IMAGE' | 'FORM' | 'AUDIO' | 'TEXT';
  name: string;
  id: number;
  // eslint-disable-next-line react/require-default-props
  url: string;
}

export const downloadFile = (url: string, fileName: string): void => {
  axios
    .get(url, {
      headers: { 'Content-Type': 'application/octet-stream' },
      responseType: 'blob',
    })
    .then(response => {
      const link = document.createElement('a');
      const urlToDownload = window.URL.createObjectURL(response.data);

      link.href = urlToDownload;
      link.download = fileName;
      link.click();
    })
    .catch(err => {
      console.error('Falha ao realizar download do arquivo.', err);
    });
};

export const copyLink = (
  url: string,
  handleCopy: (state: string) => void,
): void => {
  handleCopy('Link copiado!');
  document.execCommand('copy', true, url);
  navigator.clipboard.writeText(url);

  setTimeout(() => {
    handleCopy('Copiar link');
  }, 2000);
};

export const newTab = (url: string): void => {
  window.open(url, '_blank');
};

const Card: React.FC<propsCard> = ({
  children,
  name,
  type,
  id,
  url,
}): JSX.Element => {
  const [copy, setCopy] = useState<string>(Translator('Copiar link'));

  const { user } = useSelector((store: iStore) => store.auth);
  const { hasAccess } = useAccess();

  const handleView = () => {
    makeReduxActiveMessage().active({
      active: MessageOptions.ImageModal,
      id,
      url,
      actionOk: () => closeModal(),
      actionCancel: () => closeModal(),
    });
  };

  const handleEdit = () => {
    makeReduxActiveMessage().active({
      active: MessageOptions.addingMediaModal,
      type,
      id,
      isEditing: true,
      actionOk: () => {
        closeModal();
      },
      actionCancel: () => {
        closeModal();
      },
    });
  };

  const handleDeleteMedia = () => {
    makeReduxActiveMessage().active({
      active: MessageOptions.cancelEditModal,
      actionOk: () => {
        makeRemoteDeleteMedia()
          .delete({
            mediaId: id,
          })
          .then(() => {
            toast.success(Translator('Mídia deletada com sucesso!'));
            makeReduxListMedia().list({ query: { limit: 9999 } });
          })
          .catch(() => {
            toast.error(Translator('Falha ao deletar mídia!'));
          });
        closeModal();
      },
      actionCancel: () => {
        closeModal();
      },
      messageEdit: Translator(
        'Este arquivo pode estar sendo usado em alguma Sala de espera.',
      ),
      titleModal: Translator('Excluir Arquivo'),
      cancelMessage: Translator('Tem certeza que deseja excluí-lo?'),
      primaryActionMessage: Translator('Sim, excluir'),
      secondaryActionMessage: Translator('Não excluir'),
    });
  };

  // eslint-disable-next-line consistent-return
  function selectOptionsMedia() {
    if (type === 'IMAGE') {
      return (
        <div key={id}>
          <PopoverOptions data-testid="view_media" onClick={handleView}>
            <IconView />
            {Translator('Visualizar Mídia')}
          </PopoverOptions>
          {hasAccess(['MEDIA.UPDATE', 'MEDIA.UPLOAD']) && (
            <PopoverOptions data-testid="edit_media" onClick={handleEdit}>
              <IconEdit />
              {Translator('Editar Mídia')}
            </PopoverOptions>
          )}
          <PopoverOptions
            data-testid="download_image"
            onClick={() => downloadFile(url, name)}
          >
            <IconDownload />
            {Translator('Baixar Imagem')}
          </PopoverOptions>
          {hasAccess(['MEDIA.DELETE', 'MEDIA.UPLOAD']) && (
            <PopoverOptions
              data-testid="delete_image"
              onClick={handleDeleteMedia}
            >
              <IconDeleteImage />
              {Translator('Excluir')}
            </PopoverOptions>
          )}
        </div>
      );
    }
    if (type === 'VIDEO' || type === 'AUDIO') {
      return (
        <div key={id}>
          <PopoverOptions data-testid="view_media" onClick={() => newTab(url)}>
            <IconView />
            {Translator('Visualizar Mídia')}
          </PopoverOptions>
          {hasAccess(['MEDIA.UPDATE', 'MEDIA.UPLOAD']) && (
            <PopoverOptions data-testid="edit_media" onClick={handleEdit}>
              <IconEdit />
              {Translator('Editar Mídia')}
            </PopoverOptions>
          )}
          <PopoverOptions
            data-testid="open_new_tab"
            onClick={() => newTab(url)}
          >
            <IconNewPage />
            {Translator('Abrir link em uma nova aba')}
          </PopoverOptions>
          <PopoverOptions
            data-testid="copy_link"
            onClick={() => copyLink(url, setCopy)}
          >
            <IconCopyLink />
            {copy}
          </PopoverOptions>
          {hasAccess(['MEDIA.UPDATE', 'MEDIA.UPLOAD']) && (
            <PopoverOptions
              data-testid="delete_media"
              onClick={handleDeleteMedia}
            >
              <IconDeleteImage />
              {Translator('Excluir')}
            </PopoverOptions>
          )}
        </div>
      );
    }
    if (type === 'FORM') {
      return (
        <div key={id}>
          <PopoverOptions data-testid="view_media" onClick={() => newTab(url)}>
            <IconView />
            {Translator('Visualizar Mídia')}
          </PopoverOptions>
          <PopoverOptions data-testid="edit_media" onClick={handleEdit}>
            <IconEdit />
            {Translator('Editar Mídia')}
          </PopoverOptions>
          <PopoverOptions data-testid="view_pdf" onClick={() => newTab(url)}>
            <IconPdf />
            {Translator('Visualizar PDF')}
          </PopoverOptions>
          <PopoverOptions
            data-testid="download_pdf"
            onClick={() => downloadFile(url, name)}
          >
            <IconDownload />
            {Translator('Baixar PDF')}
          </PopoverOptions>
          {hasAccess(['MEDIA.UPDATE', 'MEDIA.UPLOAD']) && (
            <PopoverOptions
              data-testid="delete_media"
              onClick={handleDeleteMedia}
            >
              <IconDeleteImage />
              {Translator('Excluir')}
            </PopoverOptions>
          )}
        </div>
      );
    }
  }

  return (
    <ContainerCard>
      <ContentCard
        data-testid={`card_content_${id}`}
        isVideo={type === 'VIDEO'}
      >
        <ContentChildren isVideo={type === 'VIDEO'}>{children}</ContentChildren>
      </ContentCard>
      <FooterCard>
        {name}
        <div>
          <Popover data-testid={`option_${id}`}>
            <ContainerPopoverOptions>
              {selectOptionsMedia()}
            </ContainerPopoverOptions>
          </Popover>
        </div>
      </FooterCard>
    </ContainerCard>
  );
};
export default Card;
