import React, { useEffect, useState } from 'react';

import { ChakraProvider } from '@chakra-ui/react';
import { QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import { ThemeProvider } from 'styled-components';
import { AccessProvider } from 'presentation/hooks/access';
import { IntlProvider } from 'react-intl';
import { defaultTheme } from './presentation/base/themes';
import { GlobalStyle } from './presentation/base/styles/global';

import store from './data/store';
import Notifications from './presentation/components/messages';
import translations from './infra/i18n/locales';
import { queryClient } from './services/queryClient';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

const persistor = persistStore(store);

export interface iSupportedLanguages {
  language:
    | 'pt-BR'
    | 'pt-br'
    | 'en-US'
    | 'en-us'
    | 'es'
    | 'es-ES'
    | 'es-es'
    | 'es-DO'
    | 'es-do'
    | 'es-GT'
    | 'es-gt'
    | 'es-HN'
    | 'es-hn'
    | 'es-MX'
    | 'es-mx'
    | 'es-PA'
    | 'es-pa'
    | 'es-PE'
    | 'es-pe'
    | 'es-SV'
    | 'es-SE'
    | 'es-sv'
    | 'es-se';
}

const App = (): JSX.Element => {
  const [selectedLocale, setSelectedLocale] =
    useState<iSupportedLanguages['language']>('es');

  useEffect(() => {
    let userLanguage = localStorage.getItem('i18nextLng');

    if (!userLanguage) {
      userLanguage = navigator.language;
      localStorage.setItem('i18nextLng', userLanguage);
    }

    setSelectedLocale(userLanguage as iSupportedLanguages['language']);
  }, []);

  return (
    <IntlProvider
      messages={translations[selectedLocale]}
      locale={String(selectedLocale)}
      defaultLocale="es"
    >
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <PersistGate persistor={persistor}>
            <AccessProvider>
              <ThemeProvider theme={defaultTheme}>
                <ChakraProvider>
                  <Notifications>
                    <div className="App">
                      <Outlet />
                      <ToastContainer
                        position="top-right"
                        autoClose={3000}
                        closeOnClick
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                      />
                      {/* <ReactQueryDevtools /> */}
                      <GlobalStyle />
                    </div>
                  </Notifications>
                </ChakraProvider>
              </ThemeProvider>
            </AccessProvider>
          </PersistGate>
        </QueryClientProvider>
      </Provider>
    </IntlProvider>
  );
};

export default App;
