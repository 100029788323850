import styled from 'styled-components';

export const ContainerListMessage = styled.div`
  margin-top: 2.4rem;
  width: 100%;
  padding: 0 7.2rem 0 7.2rem;
`;

export const ContentHeaderSimples = styled.div`
  /* margin-top: 9rem; */
`;

export const SearchContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 4rem;
`;

export const ListMessageDiv = styled.div`
  width: 100%;
  margin-top: 2rem;
`;

export const BoxSpace = styled.div`
  padding: 0 7.2rem 0 7.2rem;
`;
