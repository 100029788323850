/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable array-callback-return */
// eslint-disable-next-line react/jsx-one-expression-per-line
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ActionsList from 'presentation/components/actions-list/ActionsList';
import { makeRemoteDeleteUsersGroup } from 'main/factories/usecases/group/DeleteUsersGroup';
import { GetByIdGroup } from 'domain/usecases/group/remote';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/UpdateFactory';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { closeModal } from 'utils';
import { translator } from 'presentation/components/i18n';
import Translator from 'presentation/components/i18n/Translator';
import { Params } from '../ListParticipants/ListParticipants';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  container: {
    maxHeight: '100vh',
    overflowY: 'auto',
  },
  headerTable: {
    padding: '1.2rem',
  },
  accordionStyle: {
    boxShadow: 'none',
    maxHeight: '30rem',
    overflowY: 'auto',
  },
});

interface ownProps {
  // eslint-disable-next-line react/require-default-props
  dataList: GetByIdGroup.Model['userGroups'] | any[];
  handleRefetch: () => void;
}

type iColumns = 'fullname' | 'city' | 'phone' | 'email' | 'actions';

const ListGroups: React.FC<ownProps> = ({
  dataList,
  handleRefetch,
}): JSX.Element => {
  const classes = useStyles();

  const params = useParams<Params>();

  const columns = [
    {
      id: 'fullname',
      label: translator('Nome'),
      minWidth: 150,
      align: 'left',
      fontSize: '1.4rem',
    },
    {
      id: 'city',
      label: translator('Cidade'),
      minWidth: 200,
      align: 'left',
      fontSize: '1.4rem',
    },
    {
      id: 'phone',
      label: translator('Contato'),
      minWidth: 200,
      align: 'left',
      fontSize: '1.4rem',
    },

    {
      id: 'email',
      label: translator('E-mail'),
      minWidth: 100,
      align: 'left',
      fontSize: '1.4rem',
    },
    {
      id: 'actions',
      label: translator('Ações'),
      minWidth: 20,
      align: 'left',
      fontSize: '1.4rem',
    },
  ];

  const deletePatientFromGroup = (patientId: number) => {
    makeRemoteDeleteUsersGroup()
      .delete({
        groupId: Number(params?.groupId),
        body: { usersGroup: [{ id: patientId }] },
      })
      .then(() => {
        toast.success(Translator('Paciente removido com sucesso!'));
        handleRefetch();
      })
      .catch(() => {
        toast.error(Translator('Erro ao remover paciente!'));
      });
  };

  const RenderComponents: React.FC<{
    id: iColumns;
    item: GetByIdGroup.Model['userGroups'][0];
  }> = ({ id, item }) => {
    switch (id) {
      case 'fullname':
        return <div data-testid={`fullname_${item.id}`}>{item.fullname}</div>;
      case 'email':
        return <div data-testid={`email_${item.id}`}>{item.email}</div>;
      case 'city':
        return <div data-testid={`city_${item.id}`}>{item.city}</div>;
      case 'phone':
        return <div data-testid={`phone_${item.id}`}>{item.phone}</div>;
      case 'actions':
        return (
          <ActionsList
            actions={{
              deletePatient: () => {
                makeReduxActiveMessage().active({
                  active: MessageOptions.cancelEditModal,
                  actionOk: () => {
                    deletePatientFromGroup(item.id);
                    closeModal();
                  },
                  actionCancel: () => {
                    closeModal();
                  },
                  titleModal: translator('Remover paciente'),
                  messageEdit: translator(
                    'Tem certeza que deseja remover este paciente do grupo?',
                  ),
                  primaryActionMessage: translator('Sim, remover'),
                  secondaryActionMessage: translator('Não remover'),
                });
              },
            }}
          />
        );
      default:
        return <div>alo</div>;
    }
  };

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {columns.map(item => {
              return (
                <TableCell align="left" className={classes.headerTable}>
                  <div
                    style={{
                      color: '#8A8A8A',
                      fontWeight: 'normal',
                      fontSize: '1.4rem',
                    }}
                  >
                    {item.label}
                  </div>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataList?.map(item => (
            <TableRow key={item.id}>
              {columns.map((columnProps: any) => {
                return (
                  <TableCell
                    align={columnProps.align}
                    style={{
                      maxWidth: columnProps.maxWidth,
                      minWidth: columnProps.minWidth,
                      fontSize: columnProps.fontSize,
                    }}
                  >
                    <RenderComponents id={columnProps.id} item={item} />
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ListGroups;
