import { IconCam, IconFile, IconMediaLibrary } from 'presentation/base/icons';
import React, { HTMLAttributes } from 'react';
import {
  ContainerOptionsMedia,
  OptionsMedia,
} from './styles/StyledSelectMedias';

interface propsSelectMedias extends HTMLAttributes<HTMLDivElement> {
  setState: (e: number) => void;
  options: string[];
  active: number;
}

const SelectMedias: React.FC<propsSelectMedias> = ({
  active,
  options,
  setState,
}): JSX.Element => {
  return (
    <ContainerOptionsMedia>
      {options.map((elem, index) => (
        <OptionsMedia
          data-testid={`menu_option_${elem}`}
          active={active === index}
          onClick={() => setState(index)}
        >
          {index === 0 && <IconMediaLibrary />}
          {index === 1 && <IconCam />}
          {index === 2 && <IconFile />}
          {/* {index === 3 && <IconSound />} */}
          {elem}
        </OptionsMedia>
      ))}
    </ContainerOptionsMedia>
  );
};

export default SelectMedias;
