import styled from 'styled-components';

export const ContainerModal = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  z-index: 3;

  .css-1oi6yiz {
    width: 1.6rem;
    height: 1.6rem;
    border: 1px solid #efefef;
    border-radius: 2px;
  }

  .css-7venl8 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;

    color: #000000;
    margin-left: 8px;
  }
  .css-7venl8:hover {
    color: #0b77cd;
  }

  .css-1oi6yiz[aria-checked='true'],
  .css-1oi6yiz[data-checked] {
    box-shadow: none;
  }

  .css-1oi6yiz:focus,
  .css-1oi6yiz[data-focus] {
    box-shadow: none;
  }
`;

export const Container = styled.form`
  border-radius: 5px;
  border: 1px solid #efefef;
  background: #ffffff;
  width: 860px;
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 28px 16px 0;
  border-bottom: 1px solid #efefef;
`;

export const Title = styled.h3`
  color: #1c1413;
  font-weight: bold;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  padding: 0 36px;
  gap: 36px;
`;

export const Left = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  #input_video {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 350px;
  }
`;

export const Right = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 9px;

  p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;

    color: #444a51;
  }
`;

export const Media = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

interface iLegend {
  error?: boolean;
}

export const Legend = styled.div<iLegend>`
  width: 100%;
  display: flex;
  align-items: center;
  border: ${({ error }) => (!error ? '1px solid #efefef' : '1px solid red')};
  border-radius: 4px;
  gap: 14px;
  padding: 13px 14px 13px 14px;
`;

export const Footer = styled.footer`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 36px 30px 36px;
  margin-top: 48px;
`;

export const ContentDropzone = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledInput = styled.input`
  width: 100%;
  height: 50px;
  padding: 12px 0 12px 14px;
  outline: 0;
  border: 1px solid #efefef;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
`;

export const TextArea = styled.textarea`
  width: 100%;
  resize: none;
  outline: 0;
`;

export const List = styled.div`
  margin-top: 9px;
`;

export const Options = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  align-items: center;
  color: #7c7d7e;
`;

export const Box = styled.div`
  height: 253px;
  overflow: auto;

  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 4px;

  padding: 12px;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;

  p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    margin-bottom: 5px;
    margin-left: 10px;
  }
`;

export const Error = styled.p`
  font-family: 'Roboto';
  font-size: 12px;
  color: red;
  margin-top: 2px;
`;

export const FixedMedias = styled.div`
  display: flex;
  margin-top: 1.8rem;
  gap: 1.3rem;
  width: 100%;
`;
