export enum MessageTypes {
  SET_DATA = '@message/SET_DATA',
  MESSAGE_ACTIVE = '@message/MESSAGE_ACTIVE',
}

export enum MessageOptions {
  none = 'none',
  ImageModal = 'ImageModal',
  addingMediaModal = 'addingMediaModal',
  addingParticipantsModal = 'addingParticipantsModal',
  viewMediaModal = 'viewMediaModal',
  cancelEditModal = 'cancelEditModal',
  viewMessageModal = 'viewMessageModal',
}

export type iMessageOptions = keyof typeof MessageOptions;
