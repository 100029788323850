import { IconPencil, IconTrash } from 'presentation/base/icons';
import styled, { css } from 'styled-components';

export const ChipContent = styled.div`
  display: flex;
  align-items: center;
`;

export const SubmitView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 4;

  padding: 14px 72px;
  background-color: #ffffff;

  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;

  position: fixed;
  width: 100%;

  margin-top: 6rem;
`;

export const CurrentFlowTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;

  color: #656a6e;

  margin-left: 12px;
`;

interface ChipProps {
  active?: boolean;
}

export const Chip = styled.div<ChipProps>`
  height: 36px;

  background-color: ${({ active }) => (active ? '#0B77CD' : '#f7f7f7')};
  color: ${({ active }) => (active ? '#ffffff' : '#000000')};
  border: 1px solid ${({ active }) => (active ? '#0B77CD' : '#bab9b9')};
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 8px;

  padding: 10px 14px;
  cursor: pointer;
`;

export const ContainerSteps = styled.div`
  width: 100%;
  max-width: 589px;

  padding: 24px;

  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;

  .nice-dates-popover {
    width: 280px;
    background-color: ${({ theme }) => theme.colors.background.main};
    padding: 10px;
    box-shadow: 16px;
  }

  .nice-dates-navigation {
    padding-bottom: 8px;

    a {
      padding-bottom: 24px;

      &:before {
        border-color: ${({ theme }) => theme.colors.primary.main};
      }
    }
    .nice-dates-navigation_current {
      color: ${({ theme }) => theme.colors.secondary.main};
      font-size: 17px;
      font-weight: 500;
    }
  }

  .nice-dates-week-header {
    border-top: 1px solid rgba(255, 255, 255, 0.08);
    border-radius: 4px;
    box-shadow: none;
    /* padding-left: 12px; */
    /* padding-right: 12px; */

    .nice-dates-week-header_day {
      width: 100%;
      color: ${({ theme }) => theme.colors.secondary.main};
      font-size: 14px;
      text-transform: uppercase;
    }
  }

  .nice-dates-grid {
    height: 204px !important;
  }

  .nice-dates-grid_container {
    width: 100%;
    border: 1px solid ${({ theme }) => theme.colors.background.grey};
    border-radius: 6px;
    /* padding-left: 12px;
    padding-top: 12px; */
  }

  .nice-dates-day {
    font-size: 12px;
    width: 32;
    height: 32px !important;
    color: ${({ theme }) => theme.colors.typography.darkGray};
    border: 1px solid ${({ theme }) => theme.colors.background.grey};
    /* padding: 2px 0; */

    &:after {
      color: ${({ theme }) => theme.colors.background.grey};
      background-color: ${({ theme }) => theme.colors.primary.main};
      border: 1px solid ${({ theme }) => theme.colors.primary.main};
    }

    .nice-dates-day_month {
      display: none;
      border: ${({ theme }) => theme.colors.primary.main};
    }

    &.-wide {
      color: #686868;
    }

    &.-outside {
      color: ${({ theme }) => theme.colors.typography.gray2};
      background-color: ${({ theme }) => theme.colors.background.grey};
    }

    &:hover {
      color: ${({ theme }) => theme.colors.background.grey};
      background-color: ${({ theme }) => theme.colors.primary.main};
      border: ${({ theme }) => theme.colors.primary.main};
    }

    &.-selected {
      color: white;
      background-color: ${({ theme }) => theme.colors.primary.main};

      span {
        color: currentColor;
      }

      &:hover {
        &:after {
          background-color: ${({ theme }) => theme.colors.primary.main};
        }
      }

      &:after {
        transform: scale(1);
      }

      &:before {
        background-color: ${({ theme }) => theme.colors.primary.main};
      }

      &.-selected-middle {
        &:before {
          background-color: #72dec8;
        }
      }

      &.-selected-end {
        &:after {
          opacity: 1;
          background-color: ${({ theme }) => theme.colors.primary.main};
          border-radius: 999px;
        }
        &:before {
          background-color: ${({ theme }) => theme.colors.primary.main};
          border-color: #72dec8;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      &.-selected-start {
        &:after {
          opacity: 1;
          background-color: ${({ theme }) => theme.colors.primary.main};
          border-radius: 999px;
        }
        &:before {
          background-color: ${({ theme }) => theme.colors.primary.main};
          border-color: #72dec8;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }
`;

export const NormalTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`;

export const OptionsContent = styled.div`
  display: flex;
  align-items: center;

  gap: 16px;
`;
export const CheckBoxContent = styled.div`
  display: flex;
  align-items: center;
`;

export const TitleSteps = styled.div`
  font-weight: 600;
  font-size: 21px;

  color: #444a51;
`;

export const SubTitleSteps = styled.div`
  width: 100%;
  font-weight: 600;
  font-size: 16px;

  color: #444a51;

  display: flex;
  margin-top: 24px;
  margin-bottom: 8px;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 1.5rem 2rem;
  align-items: flex-start;
  justify-content: space-between;

  border-radius: 0.5rem;
  border: 0.1rem solid #e7e7e7;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const TitlePage = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  background: ${({ theme }) => theme.colors.background.grey};
  color: ${({ theme }) => theme.colors.typography.gray3};

  font: ${({ theme }) => theme.typography.h5};
  font-weight: 700;
`;

export const VerticalDivider = styled.div`
  width: 0.1rem;
  height: 100%;
  min-height: 40rem;
  background-color: #efefef;
  margin: 0 1rem;

  @media (max-width: 768px) {
    width: 100%;
    height: 1px;
    margin: 0;
    min-height: 0.1rem;
  }
`;

type ColumnProps = {
  disablePadding?: boolean;
};

export const Column = styled.div<ColumnProps>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  ${({ disablePadding }) =>
    disablePadding ? 'padding: 0' : 'padding: 2.4rem 3.2rem'};
`;

type RowProps = {
  size?: 'small' | 'medium' | 'large' | 'full';
  noMb?: boolean;
  disabledResponsive?: boolean;
};

export const Row = styled.div<RowProps>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2rem;
  margin-bottom: ${({ noMb }) => (noMb ? 0 : '2.7rem')};
  flex-direction: column;

  width: ${({ size, disabledResponsive }) => {
    if (!disabledResponsive)
      switch (size) {
        case 'small':
          return '25%';
        case 'medium':
          return '50%';
        case 'large':
          return '75%';
        case 'full':
          return '100%';
        default:
          return 'auto';
      }
    return '100%';
  }};

  @media (max-width: 1024px) {
    ${({ disabledResponsive }) =>
      disabledResponsive
        ? css`
            width: 100%;
          `
        : css`
            width: 75%;
          `}
  }
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

type TitleProps = {
  mb?: boolean;
  variant?: boolean;
};

export const Title = styled.h4<TitleProps>`
  font: ${({ theme }) => theme.typography.h4};
  ${({ mb }) => (mb ? 'margin-bottom: 4rem;' : 'margin-bottom: 2rem')};
  ${({ variant }) =>
    variant &&
    css`
      font-size: 1.6rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.typography.gray};
    `}
`;

export const Subtitle = styled.span`
  font: ${({ theme }) => theme.typography.h5};
  line-height: 22px;
  margin-bottom: 3.2rem;
  max-width: 50rem;
  color: ${({ theme }) => theme.colors.typography.gray};
`;

export const ContainerForm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 7.2rem;
  background-color: #fcfcfc;

  padding-top: 130px;

  @media (max-width: 768px) {
    padding: 0 0.5rem;
  }
`;

export const StepContainer = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5rem;
  padding-top: 4rem;
  padding-bottom: 1.2rem;
  /* padding-bottom: 2rem; */
  border: 1px solid #efefef;
  border-radius: 0.5rem;
  margin: 1rem 0;

  .step-container {
    width: 60%;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const ContentStepForm = styled.form`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;

  margin-top: 24px;
`;

export const FooterContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0 3rem 0;

  .buttons-right {
    display: flex;
    align-items: center;
    justify-content: space-around;

    .button-right + .button-right {
      margin-left: 0.8rem;
    }
  }
`;

type ContainerListProps = {
  isEmpty?: boolean;
};

export const ContainerList = styled.div<ContainerListProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  /* border: 1px solid #efefef; */
  border-radius: 5px;
  ${({ isEmpty }) =>
    isEmpty &&
    css`
      align-items: center;
      justify-content: center;
      padding: 2.1rem;
      font: ${({ theme }) => theme.typography.h7};
      font-size: 1.4rem;
      color: ${({ theme }) => theme.colors.typography.lightGray};
    `}
`;

export const ListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0;
  margin: 0;
  list-style: none;
  width: 100%;

  .list-item + .list-item {
    border-top: 1px solid ${({ theme }) => theme.colors.status.disable};
  }
`;

export const ListItemContainer = styled.li.attrs({
  className: 'list-item',
})`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.7rem;
  border-radius: 0.5rem;
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .btn_icon {
    margin-left: 1.5rem;
  }
  .btn_icon + .btn_icon {
    margin-left: 1.2rem;
  }
`;

export const Name = styled.span`
  font: ${({ theme }) => theme.typography.h5};
  color: ${({ theme }) => theme.colors.typography.dark};
`;

export const Email = styled.span`
  font: ${({ theme }) => theme.typography.h5};
  color: ${({ theme }) => theme.colors.typography.gray};
`;

export const StIconPencil = styled(IconPencil).attrs({
  className: 'btn_icon',
})`
  cursor: pointer;
`;

export const StIconDelete = styled(IconTrash).attrs({
  className: 'btn_icon',
})`
  cursor: pointer;
`;
