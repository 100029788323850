/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

const IconArrowSelect = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="34"
      height="32"
      viewBox="0 0 34 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.5 0.5H30.9167C32.3434 0.5 33.5 1.6566 33.5 3.08333V28.9167C33.5 30.3434 32.3434 31.5 30.9167 31.5H0.5V0.5Z"
        fill="white"
      />
      <path
        d="M21.1048 14.0679C20.9604 13.9243 20.765 13.8438 20.5614 13.8438C20.3578 13.8438 20.1624 13.9243 20.018 14.0679L17.2507 16.7967L14.5219 14.0679C14.3775 13.9243 14.1821 13.8438 13.9785 13.8438C13.7748 13.8438 13.5795 13.9243 13.435 14.0679C13.3628 14.1396 13.3054 14.2248 13.2663 14.3187C13.2272 14.4127 13.207 14.5134 13.207 14.6152C13.207 14.717 13.2272 14.8177 13.2663 14.9116C13.3054 15.0056 13.3628 15.0908 13.435 15.1625L16.7034 18.4308C16.775 18.5031 16.8603 18.5604 16.9542 18.5995C17.0482 18.6387 17.1489 18.6588 17.2507 18.6588C17.3524 18.6588 17.4532 18.6387 17.5471 18.5995C17.641 18.5604 17.7263 18.5031 17.798 18.4308L21.1048 15.1625C21.1771 15.0908 21.2344 15.0056 21.2736 14.9116C21.3127 14.8177 21.3328 14.717 21.3328 14.6152C21.3328 14.5134 21.3127 14.4127 21.2736 14.3187C21.2344 14.2248 21.1771 14.1396 21.1048 14.0679Z"
        fill="#5F6871"
      />
      <path
        d="M0.5 0.5H30.9167C32.3434 0.5 33.5 1.6566 33.5 3.08333V28.9167C33.5 30.3434 32.3434 31.5 30.9167 31.5H0.5V0.5Z"
        stroke="#BFC4CA"
      />
    </svg>
  );
};

export default IconArrowSelect;
