/* eslint-disable consistent-return */
import { css } from '@emotion/react';
import styled from 'styled-components';

export const Container = styled.div`
  max-width: 300px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Name = styled.div`
  margin-top: 5px;
  font-size: 16px;
`;

export const Role = styled.div`
  font-size: 14px;
  color: #7a7a7a;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  white-space: nowrap;
  gap: 10px;
`;

export const Exit = styled.button`
  max-width: 170px;
  max-height: 35px;
  width: 100%;
  height: 100%;
  border: 1px solid red;
  background: transparent;
  color: red;
  border-radius: 5px;
  margin-top: 40px;
`;

export const InfoUser = styled.div`
  margin-top: 8px;
  width: 100%;
  text-align: center;
  p {
    font-family: 'Roboto';
    color: #000000;
    font-weight: bold;
  }

  span {
    color: #7a7a7a;
    font-family: 'Roboto';
    font-size: 14px;
  }
`;

interface iOptions {
  active: boolean;
  variant: 'PRIMARY' | 'SECONDARY';
}

export const Options = styled.div<iOptions>`
  display: flex;
  align-items: center;
  border-radius: 5px;
  gap: 16px;
  cursor: pointer;
  font-weight: 500;
  padding: 8px 18px;

  border: ${({ variant }) =>
    variant === 'PRIMARY' ? '1px solid #df2c26' : '1px solid #0B77CD'};
  color: ${({ variant, active }) => {
    if (variant === 'PRIMARY' && active) {
      return 'white';
    }
    if (variant === 'SECONDARY' && active) {
      return 'white';
    }
    if (variant === 'PRIMARY' && !active) {
      return '#DF2C26;';
    }
    if (variant === 'SECONDARY' && !active) {
      return '#0B77CD';
    }
  }};
  background-color: ${({ variant, active }) => {
    if (variant === 'PRIMARY' && active) {
      return '#DF2C26';
    }
    if (variant === 'PRIMARY' && !active) {
      return 'none';
    }
    if (variant === 'SECONDARY' && active) {
      return '#0B77CD';
    }
    if (variant === 'SECONDARY' && !active) {
      return 'none';
    }
  }};

  :hover {
    color: white;
    background-color: ${({ variant }) =>
      variant === 'PRIMARY' ? '#df2c26' : '#0B77CD'};
    svg {
      path {
        fill: white;
      }
    }
  }
`;
