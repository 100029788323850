import { UpdateImageUser as UsecaseRemoteUpdateImageUser } from 'domain/usecases/user/remote';
import { makeApiUrl, makeHttpClient } from 'main/factories/http';

import { RemoteUpdateImageUser } from 'data/repository/user';

/**
 * send request via API.
 */
export const makeRemoteUpdateImageUser = (): UsecaseRemoteUpdateImageUser =>
  new RemoteUpdateImageUser(makeApiUrl('/users'), makeHttpClient());

/**
 * send request via REDUX.
 */
