import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from '@chakra-ui/react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { iStore } from 'domain/interfaces/models';
import { ListResearch } from 'domain/usecases/research/remote';
import _ from 'lodash';
import { makeRemoteListResearch } from 'main/factories/usecases/research/ListResearchFactory';
import { navigate } from 'main/routes/_CustomRouter';
import { IconOptionsResearch, IconPerson } from 'presentation/base/icons';
import { translator } from 'presentation/components/i18n';
import Pagination from 'presentation/components/Pagination';
import { useAccess } from 'presentation/hooks/access';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ActionsList from '../../actions-list/ActionsList';
import { iListResearch } from '../interface';
import {
  Container,
  ContainerAccordion,
  ContentLabelAccordion,
  ExpandedContainer,
  Groups,
  LabelAccordion,
} from './styles/StyledListResearch';

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  container: {
    maxHeight: '200px',
    width: '100%',
    border: 'none',
    overflowY: 'auto',
  },
  headerTable: {
    padding: '1.2rem',
    border: 'none',
  },
});

type iColumns = 'groups' | 'patient' | 'media' | 'actions';

const ResearchList: React.FC = (): JSX.Element => {
  const [researchResponse, setResearchResponse] = useState<ListResearch.Model>(
    {} as ListResearch.Model,
  );
  const [page, setPage] = useState<number>(1);

  const itemsPerPage = 8;

  const { user } = useSelector((store: iStore) => store.auth);
  const { hasAccess } = useAccess();

  const classes = useStyles();
  const columns = [
    {
      id: 'groups',
      label: translator('Grupos'),
      minWidth: 100,
      align: 'left',
      fontSize: '1.4rem',
    },
    {
      id: 'patient',
      label: translator('Pacientes'),
      minWidth: 100,
      align: 'left',
      fontSize: '1.4rem',
    },
    {
      id: 'media',
      label: translator('Mídias'),
      minWidth: 100,
      align: 'left',
      fontSize: '1.4rem',
    },
    {
      id: 'actions',
      label: translator('Ações'),
      // minWidth: 10,
      align: 'left',
      fontSize: '1.4rem',
      hiddenForRoles: ['SYSADMIN'],
    },
  ];

  useEffect(() => {
    makeRemoteListResearch()
      .list({})
      .then(response => {
        const researches = response.records;
        const myResearches = researches.filter((research: any) => {
          return research.organizers.some(
            (organizer: any) => organizer.id === user?.id,
          );
        });

        if (user?.role === 'ORGANIZER') {
          setResearchResponse({
            ...response,
            records: [...myResearches],
          });
        } else {
          setResearchResponse(response);
        }
      })
      .catch(() => {
        toast.error(translator('Erro ao buscar pesquisas!'));
      });
  }, []);

  const RenderComponents: React.FC<{
    id: iColumns;
    items: iListResearch['groups'][0];
    researchId: number;
    orgId: number;
  }> = ({ id, researchId, items, orgId }) => {
    switch (id) {
      case 'groups':
        return (
          <>
            <Groups data-testid={`groups_${researchId}`}>
              <IconPerson />
              {items?.name}
            </Groups>
          </>
        );
      case 'patient':
        return (
          <span data-testid={`patients_${researchId}`}>
            {`${items?.patients ?? 0} ${translator('pacientes')}`}
          </span>
        );
      case 'media':
        return (
          <span data-testid={`medias_${researchId}`}>
            {`${items?.mediasTotal ?? 0} ${translator('mídias')}`}
          </span>
        );
      case 'actions':
        return (
          <>
            {hasAccess(['GROUP.UPDATE']) && (
              <ActionsList
                actions={{
                  editMedia: () => {
                    navigate(`/group/${items?.id}/medias`);
                  },
                  listPatients: () => {
                    navigate(
                      `/research/${researchId}/group/${items?.id}?orgId=${orgId}`,
                    );
                  },
                }}
              />
            )}
          </>
        );
      default:
        return <div />;
    }
  };

  const displayData = useMemo(() => {
    const orderedRecords = _.orderBy(
      researchResponse?.records,
      item => item.name.toLowerCase(),
      ['asc'],
    );

    const start = (page - 1) * itemsPerPage;

    return {
      ...researchResponse,
      records: orderedRecords?.slice(start, start + itemsPerPage),
    };
  }, [researchResponse, page]);

  return (
    <Container>
      {hasAccess(['RESEARCH.LIST']) && (
        <>
          <Pagination
            currentPage={page}
            setPage={setPage}
            pageSize={
              researchResponse?.records?.length
                ? Math.ceil(researchResponse?.records?.length / itemsPerPage)
                : 0
            }
          />
          {displayData?.records?.map((elem, index) => (
            <Accordion allowToggle border="none">
              <AccordionItem key={elem.descr}>
                {({ isExpanded }) => (
                  <ExpandedContainer isExpanded={isExpanded} index={index}>
                    <ContainerAccordion>
                      <AccordionButton
                        _focus={{ outline: 'none' }}
                        _hover={{ background: 'transparent' }}
                      >
                        <ContentLabelAccordion>
                          <LabelAccordion
                            data-testid={`research_title_${index}`}
                          >
                            <AccordionIcon />
                            <span>
                              {`(${elem?.shortOrg?.toLocaleUpperCase()}) ${
                                elem?.name
                              }`}
                            </span>
                          </LabelAccordion>
                          {hasAccess(['RESEARCH.UPDATE', 'RESEARCH.READ']) && (
                            <IconOptionsResearch
                              className="icon_research"
                              id="btn_icon_config"
                              data-testid={`btn_icon_config_${index}`}
                              onClick={() =>
                                navigate(`/research/edit/${elem?.id}`)
                              }
                            />
                          )}
                        </ContentLabelAccordion>
                      </AccordionButton>
                    </ContainerAccordion>

                    <AccordionPanel>
                      <TableContainer
                        component={Paper}
                        className={classes.container}
                      >
                        <Table
                          className={classes.table}
                          size="small"
                          aria-label="a dense table"
                        >
                          <TableHead>
                            <TableRow>
                              {columns?.map(item => {
                                if (
                                  item.hiddenForRoles?.includes(
                                    user?.role || '-1',
                                  )
                                ) {
                                  return <></>;
                                }
                                return (
                                  <TableCell
                                    key={`tableCell_${item.id}`}
                                    align="left"
                                    className={classes.headerTable}
                                  >
                                    <div
                                      style={{
                                        color: '#8A8A8A',
                                        fontWeight: 'normal',
                                        fontSize: '1.4rem',
                                      }}
                                    >
                                      {item.label}
                                    </div>
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {elem?.groups?.map(group => (
                              <TableRow>
                                {columns?.map((columnProps: any) => {
                                  if (
                                    columnProps.hiddenForRoles?.includes(
                                      user?.role || '-1',
                                    )
                                  ) {
                                    return <></>;
                                  }

                                  return (
                                    <TableCell
                                      key={`tableCellColumn_${columnProps.id}`}
                                      align={columnProps.align}
                                      style={{
                                        maxWidth: columnProps.maxWidth,
                                        minWidth: columnProps.minWidth,
                                        fontSize: columnProps.fontSize,
                                      }}
                                    >
                                      <RenderComponents
                                        researchId={elem.id}
                                        orgId={elem.orgId}
                                        key={columnProps.id}
                                        id={columnProps.id}
                                        items={group}
                                      />
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </AccordionPanel>
                  </ExpandedContainer>
                )}
              </AccordionItem>
            </Accordion>
          ))}
        </>
      )}
    </Container>
  );
};

export default ResearchList;
