/* eslint-disable react/jsx-one-expression-per-line */
import React, { useCallback, useEffect, useState } from 'react';
import Dropzone from 'presentation/components/Dropzone';
import {
  IconClose,
  IconMedia,
  IconDefaultMedia,
  IconDefaultImage,
  IconDefaultDoc,
  IconImageMedia,
  IconTooth,
  IconEmptyVideo,
} from 'presentation/base/icons';
import { useForm } from 'react-hook-form';
import { Button, Input } from 'presentation/components/UI';
import { iMessage, iStore } from 'domain/interfaces/models';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { makeRemoteUploadMedia } from 'main/factories/usecases/media/UploadMediaFactory';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/UpdateFactory';
import { closeModal } from 'utils';
import { Checkbox, Stack, Switch } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { schemaCreateMedias } from 'validation/validators/createMedias/createMediasValidator';
import { formattedUrl } from 'utils/formattedUrl';
import { makeRemoteUpdateMedia } from 'main/factories/usecases/media/UpdateMediaFactory';

import { useTheme } from 'styled-components';

import { GetByIdMedia } from 'domain/usecases/media/remote';
import { makeRemoteGetByIdMedia } from 'main/factories/usecases/media/GetByIdMediaFactory';
import {
  Box,
  Container,
  ContainerModal,
  Content,
  ContentDropzone,
  Error,
  Footer,
  Header,
  Item,
  Left,
  Legend,
  Media,
  Right,
  StyledInput,
  TextArea,
  Title,
} from './styles/StyledModalAddingMedias';
import Translator from '../i18n/Translator';

interface propsAddingMediaModal {
  message: iMessage;
}

interface imageProps {
  file?: File;
  fileName: string;
  description: string;
}

interface pdfProps {
  file?: File;
  fileName: string;
  description: string;
}

interface FormInfoToSend {
  name: string;
  descr: string;
  url: string;
  link: string;
  file: string;
  type: 'IMAGE' | 'FORM' | 'VIDEO' | 'AUDIO' | 'TEXT';
  fixedByAdmin: string;
}

const ModalAddingMedias: React.FC<propsAddingMediaModal> = ({ message }) => {
  const [image, setImage] = useState<imageProps>({} as imageProps);
  const [pdf, setPdf] = useState<pdfProps>({} as pdfProps);
  const [selectedOrgs, setSelectedOrgs] = useState<Array<number>>([]);
  const [checked, setChecked] = useState(false);

  const { records } = useSelector((store: iStore) => store.org);
  const theme = useTheme();
  const { records: medias } = useSelector((store: iStore) => store.media);

  const msgName = MessageOptions.addingMediaModal;
  const msgCancel = MessageOptions.cancelEditModal;

  const { active, type, isEditing, id } = message;

  const {
    register,
    getValues,
    watch,
    reset,
    setValue,
    setError,
    formState: { errors },
    handleSubmit,
  } = useForm<FormInfoToSend>({
    shouldFocusError: true,
    reValidateMode: 'onChange',
    resolver: schemaCreateMedias,
    defaultValues: {
      descr: '',
      name: '',
      url: '',
      file: '',
      type: 'IMAGE',
      link: '',
    },
  });

  const handleAddingModal = () => {
    makeReduxActiveMessage().active({
      active: MessageOptions.addingMediaModal,
      type,
      actionOk: () => {
        closeModal();
      },
      actionCancel: () => {
        closeModal();
      },
    });
  };

  const handleModalCancelEdit = () => {
    if (msgCancel) {
      makeReduxActiveMessage().active({
        active: MessageOptions.cancelEditModal,
        actionOk: () => {
          reset();
          closeModal();
        },
        actionCancel: () => {
          handleAddingModal();
        },
        messageEdit: Translator(
          'Você tem certeza que deseja cancelar a edição?',
        ),
        titleModal: Translator('Cancelar edição'),
        cancelMessage: Translator('Deseja realmente cancelar?'),
        primaryActionMessage: Translator('Sim, cancelar'),
        secondaryActionMessage: Translator('Não cancelar'),
      });
    }
  };

  const handleClearData = () => {
    reset();
    closeModal();
    setSelectedOrgs([]);
  };

  const handleAllChecked = useCallback(() => {
    if (checked) {
      setSelectedOrgs([]);
    } else {
      setSelectedOrgs(records?.map(item => item.id) || []);
    }
    setChecked(prevState => !prevState);
  }, [records, checked]);

  const handleSubmitMedia = () => {
    const formData = new FormData();

    formData.append('orgs', selectedOrgs ? selectedOrgs.join(',') : '');

    formData.append('descr', getValues().descr);

    switch (type) {
      case 'IMAGE':
        formData.append('link', getValues().link);
        formData.append('file', getValues().file);
        formData.append('name', getValues().name);
        formData.append('type', 'IMAGE');
        break;
      case 'VIDEO':
        formData.append('url', getValues().url);
        formData.append('name', getValues().descr);
        formData.append('type', 'VIDEO');
        break;
      default:
        formData.append('file', getValues().file);
        formData.append('link', getValues().link);
        formData.append('name', getValues().name);
        formData.append('type', 'FORM');
        break;
    }

    if (isEditing) {
      makeRemoteUpdateMedia()
        .update({
          mediaId: Number(id),
          body: formData,
        })
        .then(() => {
          toast.success(Translator('Mídia atualizada com sucesso!'));
          setSelectedOrgs([]);

          handleClearData();
        })
        .catch(() => {
          toast.error(Translator('Erro ao atualizar mídia!'));
        });
    } else {
      makeRemoteUploadMedia()
        .upload({
          body: formData,
        })
        .then(() => {
          toast.success(Translator('Mídia criada com sucesso!'));
          setSelectedOrgs([]);

          handleClearData();
        })
        .catch(() => {
          toast.error(Translator('Falha ao criar mídia'));
        });
    }
  };

  useEffect(() => {
    if (type) {
      setValue('type', type);
    }
  }, [type, setValue, active]);

  useEffect(() => {
    if (id && isEditing && active === msgName) {
      const selectedMedias = medias?.find(media => media.id === Number(id));
      if (selectedMedias) {
        setValue('name', selectedMedias?.name);
        setValue('descr', selectedMedias?.descr);
        setValue(
          'url',
          selectedMedias?.url ? selectedMedias?.url : selectedMedias?.path,
        );
        setValue(
          'file',
          selectedMedias?.url ? selectedMedias?.url : selectedMedias?.path,
        );
        setValue(
          'link',
          selectedMedias?.link ? selectedMedias?.link : selectedMedias?.url,
        );
        setValue('type', selectedMedias?.type);
      } else {
        handleClearData();
      }
    }
  }, [id, setValue, medias, isEditing, active, msgName, records]);

  useEffect(() => {
    if (id && active === msgName && isEditing) {
      makeRemoteGetByIdMedia()
        .getById({ mediaId: id })
        .then(res => {
          res?.mediaOrgs?.map(item => {
            if (
              !selectedOrgs?.includes(item.orgId) &&
              records?.find(record => record.id === item.orgId)
            ) {
              setSelectedOrgs(prevState => [...prevState, item.orgId]);
              return item;
            }
            return selectedOrgs;
          });
        })
        .catch(() => {
          toast.error(Translator('Erro ao buscar mídia'));
        });
    }
  }, [id, active]);

  return (
    <>
      {msgName === active && (
        <ContainerModal>
          <Container
            onSubmit={handleSubmit(() => {
              handleSubmitMedia();
            })}
          >
            <Header>
              <div />
              {type === 'IMAGE' && (
                <Title data-testid="title">
                  {isEditing
                    ? Translator('Atualizar Imagem')
                    : Translator('Adicionar Imagem')}
                </Title>
              )}
              {type === 'VIDEO' && (
                <Title data-testid="title">
                  {' '}
                  {isEditing
                    ? Translator('Atualizar Vídeo')
                    : Translator('Adicionar Vídeo')}
                </Title>
              )}
              {type === 'FORM' && (
                <Title data-testid="title">
                  {' '}
                  {isEditing
                    ? Translator('Atualizar PDF')
                    : Translator('Adicionar PDF')}
                </Title>
              )}
              <div>
                <IconClose
                  data-testid="icon_close"
                  onClick={handleClearData}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            </Header>
            <Content>
              {type === 'VIDEO' && (
                <Left data-testid="left_content">
                  <Input
                    {...register('url')}
                    placeholder="https//:"
                    data-testid="input_url"
                    id="input_video"
                    name="url"
                    label={Translator('Insira o link do vídeo')}
                    error={Boolean(errors?.url)}
                    message={errors?.url?.message && errors?.url?.message}
                  />
                  <Media>
                    {!watch('url') ||
                    !watch('url').match(
                      /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/,
                    ) ? (
                      <IconEmptyVideo
                        data-testid="empty_video"
                        width="300"
                        height="166"
                      />
                    ) : (
                      <iframe
                        width="300"
                        height="166"
                        src={formattedUrl(watch('url'))}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      />
                    )}
                  </Media>

                  <Legend error={Boolean(errors?.descr)}>
                    {!watch('descr') ? (
                      <IconDefaultMedia
                        data-testid="default_media"
                        width="46px"
                        height="33px"
                      />
                    ) : (
                      <IconMedia data-testid="media" />
                    )}
                    <TextArea
                      {...register('descr')}
                      data-testid="input_descr"
                      name="descr"
                      placeholder={Translator(
                        'Digite aqui a legenda que será apresentada na sala.',
                      )}
                    />
                  </Legend>

                  <Error data-testid="error_message">
                    {errors?.descr && errors?.descr?.message}
                  </Error>
                </Left>
              )}
              {type === 'IMAGE' && (
                <Left data-testid="left_content">
                  <Media>
                    <ContentDropzone>
                      <Dropzone
                        {...register('file')}
                        data-testid="file_dropzone"
                        name="file"
                        defaultImage={watch('file')}
                        handleFileUpload={file => {
                          setImage({
                            ...image,
                            file,
                            fileName: file.name.replace(/\.[^/.]+$/, ''),
                          });
                          setValue('file', file);
                          setError('file', { type: 'onBlur' });
                        }}
                      />
                      <Error data-testid="error_message">
                        {errors?.file && errors?.file?.message}
                      </Error>
                      <StyledInput
                        {...register('name')}
                        data-testid="input_name"
                        name="name"
                        placeholder={Translator(
                          'Digite aqui o nome desta imagem',
                        )}
                      />
                      <Error data-testid="error_message">
                        {errors?.name && errors?.name?.message}
                      </Error>
                    </ContentDropzone>
                  </Media>
                  <Legend>
                    {!watch('descr') ? (
                      <IconDefaultImage
                        data-testid="default_image"
                        width="46px"
                        height="33px"
                      />
                    ) : (
                      <IconImageMedia
                        data-testid="default_media"
                        width="46px"
                        height="33px"
                      />
                    )}
                    <TextArea
                      {...register('descr')}
                      data-testid="input_descr"
                      name="descr"
                      placeholder={Translator(
                        'Digite aqui a legenda que será apresentada na sala.',
                      )}
                    />
                    <Error data-testid="error_message">
                      {errors?.descr && errors?.descr?.message}
                    </Error>
                  </Legend>
                  <Input
                    {...register('link')}
                    placeholder="https//:"
                    data-testid="input_link"
                    id="input_video"
                    name="link"
                    label="Adicionar link para conteúdo"
                    error={Boolean(errors?.link)}
                    message={errors?.link?.message && errors?.link?.message}
                  />
                </Left>
              )}
              {type === 'FORM' && (
                <Left data-testid="left_content">
                  <Media>
                    <ContentDropzone>
                      <Dropzone
                        {...register('file')}
                        data-testid="file_dropzone"
                        name="file"
                        defaultImage={watch('file')}
                        handleFileUpload={file => {
                          setPdf({
                            ...pdf,
                            file,
                            fileName: file.name.replace(/\.[^/.]+$/, ''),
                          });
                          setValue('file', file);
                          setError('file', { type: 'onBlur' });
                        }}
                      />
                      <Error data-testid="error_message">
                        {errors?.file && errors?.file?.message}
                      </Error>
                      <StyledInput
                        {...register('name')}
                        data-testid="input_name"
                        name="name"
                        placeholder={Translator(
                          'Digite aqui o nome do arquivo',
                        )}
                      />
                      <Error data-testid="error_message">
                        {errors?.name && errors?.name?.message}
                      </Error>
                    </ContentDropzone>
                  </Media>
                  <Legend>
                    {!watch('descr') ? (
                      <IconDefaultDoc
                        data-testid="default_doc"
                        width="32px"
                        height="45px"
                      />
                    ) : (
                      <IconTooth data-testid="icon_tooth" />
                    )}
                    <TextArea
                      {...register('descr')}
                      data-testid="input_descr"
                      name="descr"
                      placeholder={Translator(
                        'Digite aqui a legenda que será apresentada na sala.',
                      )}
                    />
                    <Error data-testid="error_message">
                      {errors?.descr && errors?.descr.message}
                    </Error>
                  </Legend>
                  <Input
                    {...register('link')}
                    placeholder="https//:"
                    data-testid="input_link"
                    id="input_link"
                    name="link"
                    label="Adicionar link para conteúdo"
                    error={Boolean(errors?.link)}
                    message={errors?.link?.message && errors?.link?.message}
                  />
                </Left>
              )}
              <Right data-testid="right_content">
                <p>
                  {Translator(
                    'Selecione as instituições que podem visualizar está mídia:',
                  )}
                </p>

                <Box>
                  <Item>
                    <Checkbox
                      data-testid="all_orgs"
                      size="lg"
                      onChange={() => handleAllChecked()}
                      isChecked={selectedOrgs?.length === records?.length}
                    >
                      {Translator('Todas as instituições')}
                    </Checkbox>
                  </Item>
                  {records?.map(item => (
                    <Stack pl={0} mt={3} spacing="0.8rem">
                      <Checkbox
                        data-testid={`org_${item.id}`}
                        size="lg"
                        isChecked={selectedOrgs.includes(item.id)}
                        onChange={() => {
                          setSelectedOrgs(prevState => {
                            const isChecked = prevState.includes(item.id);
                            if (isChecked) {
                              setChecked(false);
                              return prevState.filter(org => org !== item.id);
                            }
                            return [...prevState, item.id];
                          });
                        }}
                      >
                        {item.name}
                      </Checkbox>
                    </Stack>
                  ))}
                </Box>
              </Right>
            </Content>
            <Footer>
              <Button
                data-testid="btn_cancel"
                variant="secondary"
                size="medium"
                onClick={handleModalCancelEdit}
              >
                {Translator('Cancelar')}
              </Button>
              <Button
                data-testid="btn_save"
                color={theme.colors.secondary.main}
                variant="primary-custom"
                type="submit"
                size="medium"
                // disabled={Boolean(!isValid)}
              >
                {Translator('Confirmar')}
              </Button>
            </Footer>
          </Container>
        </ContainerModal>
      )}
    </>
  );
};

export default ModalAddingMedias;
