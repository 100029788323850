import { IconOrganization } from 'presentation/base/icons';
import { Header, HeaderSimples } from 'presentation/components/Header';
import React from 'react';
import Translator from 'presentation/components/i18n/Translator';
import ListOrganization from '../ListOrganizationsPage';
import {
  Body,
  Container,
  ContentHeaderSimples,
} from './styles/StyledListOrganizationsLandingPage';

const ListOrganizationsLandingPage: React.FC = (): JSX.Element => {
  return (
    <Container>
      <Header />

      <ContentHeaderSimples>
        <HeaderSimples
          title={Translator('Seleção de Organizações')}
          Icon={IconOrganization}
        />
      </ContentHeaderSimples>
      <Body>
        <ListOrganization />
      </Body>
    </Container>
  );
};

export default ListOrganizationsLandingPage;
