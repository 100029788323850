/* eslint-disable react/no-unused-prop-types */
import React from 'react';

import { Container, Content, Point } from './style/StyledStep';

interface ownProps {
  title: string;
  active: boolean;
  current: boolean;
}

type Props = ownProps;

const InternalStep: React.FC<Props> = (props): JSX.Element => {
  return (
    <Container>
      <Content active={props.active ?? false}>{props.title}</Content>

      <Point
        active={props.active ? props.active : false}
        current={props.current ? props.current : false}
      />
    </Container>
  );
};

export default InternalStep;
