/* eslint-disable react/require-default-props */
import React from 'react';
import { CreateMessageProps } from '../..';

import { EmailWritingArea } from './index';
import { ContainerNotes } from './styles/StyledInfoNotes';

interface NotesProps {
  createMessage: CreateMessageProps;
  setCreateMessage: (prevState: CreateMessageProps) => any;
  characterLimit?: number;
}

const Notes: React.FC<NotesProps> = ({
  setCreateMessage,
  createMessage,
  characterLimit,
}) => {
  return (
    <ContainerNotes>
      <EmailWritingArea
        createMessage={createMessage}
        setCreateMessage={setCreateMessage}
        characterLimit={characterLimit}
      />
    </ContainerNotes>
  );
};

export default Notes;
