/* eslint-disable react/require-default-props */
import { iStore } from 'domain/interfaces/models';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

export interface PrivateRouteType {
  children?: React.ReactElement;
  redirectPath?: string;
}

export const PrivateRoute: React.FC<PrivateRouteType> = ({
  children,
  redirectPath = '/',
}) => {
  const { accessToken } = useSelector((state: iStore) => state.auth);

  const condition = accessToken;

  if (!condition) {
    return <Navigate to={redirectPath} />;
  }

  return children || <Outlet />;
};

export default PrivateRoute;
