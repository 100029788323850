/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { FormEvent, useCallback, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { makeRemoteConfirmNewPassword } from 'main/factories/usecases/auth/ConfirmNewPassword';
import {
  IconEmail,
  IconLogo,
  IconPadlock,
  ImageLogin,
} from 'presentation/base/icons';
import { Icon } from 'presentation/components/icon';
import { Button } from 'presentation/components/UI';
import Input from 'presentation/components/UI/input/index';
import { toast } from 'react-toastify';
import { translator } from 'presentation/components/i18n';
import {
  BackButton,
  Body,
  Container,
  ContainerRight,
  ContentLeft,
  ContentRight,
  Header,
  Information,
  PrivacyPolice,
  Title,
} from './styles/RecoveryPasswordPageStyled';

const ConfirmNewPasswordPage: React.FC = (): JSX.Element => {
  const [password, newPassword] = useState('');
  const [confirmPassword, confirmNewPassword] = useState('');

  const { search } = useLocation();

  const params = useMemo(
    () => ({
      token: new URLSearchParams(search).get('token') ?? null,
    }),
    [search],
  );

  const handleSubmit = useCallback(
    (e: FormEvent): void => {
      e.preventDefault();

      if (params?.token) {
        makeRemoteConfirmNewPassword()
          .patch({
            body: { token: params.token, password },
          })
          .then(() => {
            toast.success(translator('Senha alterada com sucesso!'));
          })
          .catch(() => {
            toast.error(translator('Erro ao alterar a senha!'));
          });
      }
    },
    [password, params?.token],
  );

  const disabled = password !== confirmPassword;

  return (
    <Container>
      <Body>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ContainerRight>
            <ContentRight id="forgotAuthentication" onSubmit={handleSubmit}>
              <Header>
                <Icon src={IconLogo} width="200px" height="100px" />
              </Header>
              <Title>{translator('Configurações de senha')}</Title>

              <Information>
                {translator('Defina sua senha para acessar a plataforma.')}
              </Information>

              <Input
                id="validateNewPassword"
                data-testid="input_newPassword"
                name="newPassword"
                type="password"
                icon={IconEmail}
                label={translator('Nova senha')}
                placeholder={translator('Nova senha')}
                value={password}
                onChange={(e): void => newPassword(e.target.value)}
                autoFocus
              />
              <Input
                id="loginInput"
                data-testid="input_confirmNewPassword"
                name="validateConfirmPassword"
                type="password"
                label={translator('Confimar senha')}
                icon={IconPadlock}
                placeholder={translator('Confirmar senha')}
                value={confirmPassword}
                onChange={(e): void => confirmNewPassword(e.target.value)}
                autoFocus
              />

              <Button
                id="loginSubmit"
                data-testid="btn_submit"
                type="submit"
                rounded
                height="48px"
                disabled={disabled}
              >
                {translator('Enviar')}
              </Button>

              <BackButton>
                <Link data-testid="btn_back" to="/">
                  {translator('Voltar')}
                </Link>
              </BackButton>
            </ContentRight>
          </ContainerRight>
          <PrivacyPolice>
            <p data-testid="policies">
              {`@ ${new Date().getFullYear()} Copyright | `}
              {/* <a href="/" target="_blank"> */}
              {translator('Políticas de privacidade')}
              {/* </a> */}
              {translator(' e ')}
              {/* <a href="/" target="_blank"> */}
              {translator('Termos de uso')}
              {/* </a> */}
            </p>
          </PrivacyPolice>
        </div>
        <ContentLeft>
          <ImageLogin />
        </ContentLeft>
      </Body>
    </Container>
  );
};

export default ConfirmNewPasswordPage;
