import { MessageRecords } from 'domain/usecases/message/remote';
import { makeRemoteListMessages } from 'main/factories/usecases/message/ListMessages';
import { IconPlus } from 'presentation/base/icons';
import Translator from 'presentation/components/i18n/Translator';
import { iListMessageResponse } from 'presentation/components/Lists/interface';
import ListMessage from 'presentation/components/Lists/ListMessage/ListMessage';
import SearchListBroadcastGroup from 'presentation/components/search-list/SearchListBroadcastGroups';
import { Button } from 'presentation/components/UI';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import {
  ContainerListMessage,
  ListMessageDiv,
  SearchContent,
} from './styles/StyledListMessagePage';

const ListMessagePage: React.FC = (): JSX.Element => {
  const [messages, setMessages] = useState<MessageRecords[]>([]);
  const [filterName, setFilterName] = useState<string>('');

  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    makeRemoteListMessages()
      .list({
        query: {
          limit: 9999,
        },
      })
      .then(res => setMessages(res.records))
      .catch(console.error);
  }, []);

  useEffect(() => {
    // Apply debounce on filterName and call list again
    const timeout = setTimeout(() => {
      makeRemoteListMessages()
        .list({
          query: {
            limit: 9999,
            name: filterName.length > 0 ? filterName : undefined,
          },
        })
        .then(res => setMessages(res.records))
        .catch(console.error);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [filterName]);

  return (
    <ContainerListMessage>
      <SearchContent>
        <SearchListBroadcastGroup
          placeholder={Translator('Buscar mensagem...')}
          onChange={setFilterName}
        >
          <h4>Em desenvolvimento</h4>
        </SearchListBroadcastGroup>
        <Button
          data-testid="btn_create_message"
          variant="primary-custom"
          size="medium"
          icon={IconPlus}
          color={theme.colors.secondary.main}
          onClick={() => navigate('/messages/create')}
        >
          {Translator('Nova mensagem')}
        </Button>
      </SearchContent>
      <ListMessageDiv>
        <ListMessage messages={messages} />
      </ListMessageDiv>
    </ContainerListMessage>
  );
};

export default ListMessagePage;
