import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Body = styled.div`
  display: flex;
`;

export const ContentHeaderSimples = styled.div`
  /* margin-top: 9rem; */
`;
