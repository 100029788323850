import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import {
  InvalidCredentialsError,
  NotFound,
  UnexpectedError,
} from 'domain/errors';
import { GetGroups } from 'domain/usecases/groups/remote';
import 'infra/global/variables';

export class RemoteGetAllGroups implements GetGroups {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetGroups.Model>;

  constructor(url: string, httClient: HttpClient<GetGroups.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  getAll = async (params: GetGroups.Params): Promise<GetGroups.Model> => {
    let query = '';
    Object.entries(params).forEach(([key, value]) => {
      query += `${key}=${value}&`;
    });

    const httpResponse = await this.httpClient.request({
      url: `${this.url}?${query}`,
      method: 'get',
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      default:
        throw new UnexpectedError();
    }
  };
}
