import styled from 'styled-components';

export const Container = styled.div``;

interface CheckBoxProps {
  active?: boolean;
}

export const CheckBox = styled.div<CheckBoxProps>`
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 18px;
  height: 18px;

  background-color: #fff;
  border-radius: 3px;
  border: ${({ active }) => (active ? 'none' : '1px solid #bab9b9')};
`;
