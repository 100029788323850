/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import { Uploader } from 'presentation/components/UI';

import { Container, Img, Initials } from './styles/StyledAvatar';

interface AvatarProps {
  src?: string;
  size?: string;
  fullName?: string;
  haveUpdated?: () => void;
  hasBorder?: boolean;
}

const Avatar: React.FC<AvatarProps> = ({
  src,
  size,
  fullName,
  haveUpdated,
  hasBorder = false,
}) => {
  const [error, setError] = useState(false);
  const [initials, setInitials] = useState<string>('');

  useEffect(() => {
    if (!fullName) return;

    const formatName = fullName.toUpperCase().split(' ');

    if (formatName.length > 1) {
      setInitials(`${formatName[0][0] ?? ''}${formatName[1][0] ?? ''}`);
    }

    if (formatName.length === 1) {
      setInitials(`${formatName[0][0] ?? ''}`);
    }
  }, [fullName]);

  useEffect(() => {
    setError(false);
  }, [src]);

  return (
    <Container hasBorder={hasBorder}>
      {error ? (
        <Initials size={size}>{initials}</Initials>
      ) : (
        <Img src={src} size={size} onError={e => setError(true)} />
      )}

      <div id="uploader">
        <Uploader
          setUpdate={setError}
          haveUpdated={() => {
            haveUpdated?.();

            // Wait half second to update profile image
            setTimeout(() => {
              setError(false);
            }, 500);
          }}
        />
      </div>
    </Container>
  );
};

export default Avatar;
