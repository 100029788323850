/* eslint-disable react/no-unknown-property */
import React from 'react';

const IconCheckPrimary = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width ?? '10'}
      height={props.height ?? '8'}
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.10775 0.255056C8.27365 0.089653 8.49441 -0.00178848 8.72345 2.65108e-05C8.95249 0.0018415 9.1719 0.0967713 9.33539 0.264784C9.49887 0.432797 9.59364 0.660752 9.59969 0.900549C9.60575 1.14034 9.52261 1.37323 9.36784 1.55005L4.66903 7.70436C4.58823 7.79551 4.49071 7.86865 4.38231 7.91942C4.2739 7.97018 4.15683 7.99754 4.0381 7.99984C3.91937 8.00214 3.80142 7.97935 3.69129 7.93282C3.58117 7.88629 3.48113 7.81698 3.39717 7.72903L0.281114 4.46564C0.194337 4.38096 0.124736 4.27884 0.0764616 4.16537C0.0281876 4.05191 0.00222988 3.92942 0.000137455 3.80522C-0.00195497 3.68102 0.0198608 3.55765 0.0642828 3.44248C0.108705 3.3273 0.174823 3.22267 0.258694 3.13483C0.342564 3.047 0.442469 2.97775 0.552447 2.93123C0.662425 2.88471 0.780224 2.86186 0.898817 2.86405C1.01741 2.86624 1.13436 2.89343 1.24271 2.94398C1.35105 2.99454 1.44856 3.06743 1.52942 3.15831L3.99541 5.73967L8.08538 0.28219C8.09274 0.272694 8.10061 0.263635 8.10893 0.255056H8.10775Z"
        fill="white"
      />
    </svg>
  );
};

export default IconCheckPrimary;
