import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { DeleteOrg as UsecaseRemoteDeleteOrg } from 'domain/usecases/org/remote';

import { RemoteDeleteOrg } from 'data/repository/org';

/**
 * send request via API.
 */
export const makeRemoteDeleteOrg = (): UsecaseRemoteDeleteOrg =>
  new RemoteDeleteOrg(makeApiUrl('/orgs/{org}/'), makeHttpClient());
