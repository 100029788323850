import styled, { css } from 'styled-components';

interface propsCard {
  isVideo?: boolean;
}

export const ContainerCard = styled.div`
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border: ${({ theme }) => `1px solid ${theme.colors.status.disable}`};
  border-radius: 2px;
  width: 40.6rem;
  display: flex;
  flex-direction: column;
  height: 24rem;

  @media (max-width: 1366px) {
    width: 35.6rem;
  }
`;

export const ContentCard = styled.div<propsCard>`
  background: #515151;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 19rem;
  cursor: pointer;

  ${({ isVideo }) =>
    isVideo &&
    css`
      background: none;
    `}
`;

export const FooterCard = styled.div`
  display: flex;
  width: 100%;
  height: 5rem;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.4rem;
  :hover {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;

export const ContentChildren = styled.div<propsCard>`
  width: ${({ isVideo }) => (isVideo ? '100%' : '80%')};
  display: flex;
  height: ${({ isVideo }) => (isVideo ? '100%' : '90%')};
  align-items: center;
  justify-content: center;
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`;

export const ContainerPopoverOptions = styled.div`
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  width: 26rem;
`;

export const PopoverOptions = styled.div`
  display: flex;
  gap: 1.7rem;
  align-items: center;
  padding: 1.5rem;
  width: 100%;
  font-size: 1.6rem;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.typography.gray};
  :hover {
    font-weight: bold;
  }
`;
