import { IconResearch } from 'presentation/base/icons';
import { Header, HeaderSimples } from 'presentation/components/Header';
import Translator from 'presentation/components/i18n/Translator';
import { Footer } from 'presentation/components/UI';
import { ListsHomePage } from 'presentation/pages';
import React from 'react';
import { Body, Container, ContentHeaderSimples } from './styles/StyledHomePage';

const HomePage: React.FC = (): JSX.Element => {
  return (
    <Container>
      <Header />

      <ContentHeaderSimples>
        <HeaderSimples title={Translator('Pesquisas')} Icon={IconResearch} />
      </ContentHeaderSimples>
      <Body>
        <ListsHomePage />
        {/* {activeMenu === 1 && <MediaLibraryPage />}
        {activeMenu === 2 && <ListMessagePage />} */}
      </Body>
      <Footer isPadding />
    </Container>
  );
};

export default HomePage;
