/* eslint-disable react/require-default-props */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

import { Container } from './styles/StyledPopover';

const CssPopover = withStyles({
  root: {
    '& .MuiPopover-paper': {
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      borderRadius: '8px 0px 8px 8px',
    },
  },
})(Popover);

interface ownProps {
  children: JSX.Element;
  content: JSX.Element;
  elevation?: number;
  originX?: 'left' | 'center' | 'right';
  originY?: 'top' | 'center' | 'bottom';
  positionX?: 'left' | 'center' | 'right';
  positionY?: 'top' | 'center' | 'bottom';
}

export const PopoverPopupState: React.FC<ownProps> = ({
  children,
  content,
  elevation = 0,
  originX = 'center',
  originY = 'bottom',
  positionX = 'center',
  positionY = 'top',
}): JSX.Element => {
  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState: any) => {
        return (
          <div>
            <Container {...bindTrigger(popupState)}>{children}</Container>
            <CssPopover
              style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: originY,
                horizontal: originX,
              }}
              transformOrigin={{
                vertical: positionY,
                horizontal: positionX,
              }}
              elevation={elevation}
            >
              {content}
            </CssPopover>
          </div>
        );
      }}
    </PopupState>
  );
};

export default PopoverPopupState;
