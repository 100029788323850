import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { UpdateMediasFromGroup as UsecaseRemoteUpdateMediasFromGroup } from 'domain/usecases/group/remote';
// import { GetByIdGroup as UsecaseReduxGetByIdGroup } from 'domain/usecases/group/redux';

import { RemoteUpdateMediasFromGroup } from 'data/repository/group';
// import { ReduxGetByIdGroup } from 'data/store/reducer/group/usecases';

/**
 * send request via API.
 */
export const makeRemoteUpdateMediasFromGroup =
  (): UsecaseRemoteUpdateMediasFromGroup =>
    new RemoteUpdateMediasFromGroup(
      makeApiUrl('/groups/{group}/medias'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxGetByIdGroup =
  (): UsecaseReduxGetByIdGroup =>
    new ReduxGetByIdGroup(); */
