import { DeleteOrg } from 'domain/usecases/org/remote';
import {
  NotFound,
  BadRequestError,
  Forbidden,
  UnexpectedError,
  BadGateway,
} from 'domain/errors';
import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import 'infra/global/variables';

export class RemoteDeleteOrg implements DeleteOrg {
  private readonly url: string;

  private readonly httpClient: HttpClient<DeleteOrg.Model>;

  constructor(url: string, httClient: HttpClient<DeleteOrg.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  delete: (params: DeleteOrg.Params) => Promise<DeleteOrg.Model> = async (
    params: DeleteOrg.Params,
  ): Promise<DeleteOrg.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url.replace('{org}', `${params.body.orgId}`),
      method: 'delete',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.accepted:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new Forbidden();
      case HttpStatusCode.badGateway:
        throw new BadGateway();
      default:
        throw new UnexpectedError();
    }
  };
}
