export enum AuthTypes {
  LOGIN = '@auth/LOGIN',
  LOGIN_SUCCESS = '@auth/LOGIN_SUCCESS',
  LOGIN_FAILED = '@auth/LOGIN_FAILED',
  REFRESH = '@auth/REFRESH',
  REFRESH_SUCCESS = '@auth/REFRESH_SUCCESS',
  REFRESH_FAILED = '@auth/REFRESH_FAILED',
  LOGOUT = '@auth/LOGOUT',
  UPDATE_USER = '@auth/UPDATE_USER',
  UPDATE_USER_SUCCESS = '@auth/UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILED = '@auth/UPDATE_USER_FAILED',
}
