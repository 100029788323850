import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Translator from 'presentation/components/i18n/Translator';

export const schema = yup.object().shape({
  password: yup
    .string()
    .required(Translator('A senha é obrigatória'))
    .min(6, Translator('A senha deve ter no mínimo 6 caracteres'))

    .matches(
      /^(?=.*[a-z])(?=.*[0-9])/,
      Translator(
        'A senha deve conter pelo menos um número e uma letra maiúscula',
      ),
    ),
  confirmPassword: yup
    .string()
    .required(Translator('A confirmação de senha é obrigatória'))
    .oneOf([yup.ref('password'), null], Translator('As senhas não conferem')),
});

export const schemaChangePassword = yupResolver(schema);
