import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  min-width: 32.5rem;
  min-height: 3.6rem;
  justify-content: space-between;
  border-radius: 4px;
  padding: 0 5px 0 13px;
  border: 1px solid #efefef;

  input {
    max-width: 38.5rem;
    padding: 8px;
    text-decoration: none;
    border: none;
    outline: 0;
  }
`;

export const ContentSearch = styled.div`
  display: flex;
  align-items: center;
`;
