import React, { useEffect } from 'react';
import HomePage from 'presentation/pages/HomePage/index';
import { makeReduxListMedia } from 'main/factories/usecases/media/ListMediaFactory';

export const HomeFactory: React.FC = () => {
  useEffect(() => {
    makeReduxListMedia().list({});
  }, []);

  return <HomePage />;
};
