import { IconPlus } from 'presentation/base/icons';
import { translator } from 'presentation/components/i18n';
import ResearchList from 'presentation/components/Lists/ListResearch/ListResearch';
import SearchListBroadcastGroup from 'presentation/components/search-list/SearchListBroadcastGroups';
import { Button } from 'presentation/components/UI';
import { withAccess } from 'presentation/hooks/access';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import {
  ContainerLists,
  ListResearch,
  NavigationMenu,
  SearchContent,
} from './styles/StyledListsHomePage';

const ListsHomePage: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <ContainerLists>
      <NavigationMenu>
        <SearchContent>
          <SearchListBroadcastGroup>
            <h4>{translator('Em desenvolvimento')}</h4>
          </SearchListBroadcastGroup>
          <Button
            data-testid="btn_create_research"
            variant="primary-custom"
            color={theme.colors.secondary.main}
            icon={IconPlus}
            size="large"
            onClick={() => navigate('/research/create')}
            roles={['RESEARCH.CREATE']}
          >
            {translator('Nova Pesquisa')}
          </Button>
        </SearchContent>
      </NavigationMenu>
      <ListResearch>
        <ResearchList />
      </ListResearch>
    </ContainerLists>
  );
};

export default withAccess(ListsHomePage);
