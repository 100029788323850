import { Login } from 'domain/usecases/auth/remote';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
  NotFound,
} from 'domain/errors';
import Translator from 'presentation/components/i18n/Translator';
import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import 'infra/global/variables';
import { toast } from 'react-toastify';

export class RemoteLoginAuth implements Login {
  private readonly url: string;

  private readonly httpClient: HttpClient<Login.Model>;

  constructor(url: string, httClient: HttpClient<Login.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  login = async (params: Login.Params): Promise<Login.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params,
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        toast.success(Translator('Login realizado com sucesso!'));
        return httpResponse.body;

      case HttpStatusCode.badRequest:
        throw new BadRequestError();

      case HttpStatusCode.unauthorized:
        toast.error(Translator('Usuário ou senha inválidos'));
        throw new InvalidCredentialsError();

      case HttpStatusCode.notFound:
        toast.error(Translator('Usuário ou senha inválidos'));
        throw new NotFound();

      default:
        toast.error(Translator('Erro inesperado'));
        throw new UnexpectedError();
    }
  };
}
