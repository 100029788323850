import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  z-index: 3;
`;

export const Content = styled.div`
  width: 71.4rem;
  border-radius: 5px;
  overflow: hidden;
`;

export const HeaderCard = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000000;
  padding: 1.8rem;
  background: ${({ theme }) => theme.colors.background.main};
  font-weight: 500;
`;

export const ImageContainer = styled.div`
  background: #515151;
  width: 100%;
  height: 100%;
`;

export const Image = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  max-height: 600px;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    max-height: 600px;
  }
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.8rem;
  background: ${({ theme }) => theme.colors.background.main};

  :hover {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;
