import React from 'react';

const IconDefaultMedia = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 34 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.66582e-06 1.95465C-0.00256688 0.875882 0.871229 0 1.95 0H31.85C32.9251 0 33.7974 0.870213 33.8 1.94535L33.848 22.0953C33.8506 23.1741 32.9768 24.05 31.898 24.05H1.99805C0.922913 24.05 0.0506213 23.1798 0.0480574 22.1046L5.66582e-06 1.95465Z"
        fill="#BABABA"
      />
      <path d="M1.94532 1.95H31.8453V22.1H1.94532V1.95Z" fill="#F4F4F4" />
      <path
        d="M21.2882 9.22879C21.2882 11.5807 19.3816 13.4873 17.0297 13.4873C14.6778 13.4873 12.7713 11.5807 12.7713 9.22879C12.7713 6.87691 14.6778 4.97034 17.0297 4.97034C19.3816 4.97034 21.2882 6.87691 21.2882 9.22879Z"
        fill="#CFCECE"
      />
      <path
        d="M19.0887 8.77847C19.4353 8.97862 19.4353 9.47899 19.0887 9.67914L16.3902 11.2371C16.0435 11.4372 15.6102 11.1871 15.6102 10.7868V7.67085C15.6102 7.27055 16.0435 7.02037 16.3902 7.22052L19.0887 8.77847Z"
        fill="#8A8A8A"
      />
      <path
        d="M7.09379 17.6824C7.09379 17.3235 7.3848 17.0324 7.74379 17.0324H27.0263C27.3853 17.0324 27.6763 17.3235 27.6763 17.6824V17.8019C27.6763 18.1609 27.3853 18.4519 27.0263 18.4519H7.74379C7.3848 18.4519 7.09379 18.1609 7.09379 17.8019V17.6824Z"
        fill="#8A8A8A"
      />
      <path
        d="M7.09379 17.6824C7.09379 17.3235 7.3848 17.0324 7.74379 17.0324H22.0581C22.4171 17.0324 22.7081 17.3235 22.7081 17.6824V17.8019C22.7081 18.1609 22.4171 18.4519 22.0581 18.4519H7.74379C7.3848 18.4519 7.09379 18.1609 7.09379 17.8019V17.6824Z"
        fill="#CFCECE"
      />
    </svg>
  );
};

export default IconDefaultMedia;
