import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { DeleteMedia as UsecaseRemoteDeleteMedia } from 'domain/usecases/media/remote';
// import { DeleteMedia as UsecaseReduxDeleteMedia } from 'domain/usecases/media/redux';

import { RemoteDeleteMedia } from 'data/repository/media';
// import { ReduxDeleteMedia } from 'data/store/reducer/media/usecases';

/**
 * send request via API.
 */
export const makeRemoteDeleteMedia = (): UsecaseRemoteDeleteMedia =>
  new RemoteDeleteMedia(makeApiUrl('/medias/{media}'), makeHttpClient());

/**
 * send request via REDUX.
 */
/* export const makeReduxDeleteMedia =
  (): UsecaseReduxDeleteMedia =>
    new ReduxDeleteMedia(); */
