import { iOrg } from 'domain/interfaces/models/Org';
import { OrgTypes } from 'domain/interfaces/redux/org/types';
import { OrgActions } from './actions';

export const initialState: iOrg = {
  loading: false,
  records: undefined,
};

const reducer = (state = initialState, action: OrgActions): iOrg => {
  switch (action.type) {
    case OrgTypes.LIST:
      return { ...state, loading: true };
    case OrgTypes.LIST_FAILED:
      return { ...state, loading: false };
    case OrgTypes.LIST_SUCCESS: {
      return { ...action.payload, loading: false };
    }
    default:
      return state;
  }
};

export default reducer;
