/* eslint-disable no-console */
import { GetByIdResearch } from 'domain/usecases/research/remote';
import { makeReduxListOrg } from 'main/factories/usecases/org/ListOrgFactory';
import { makeRemoteGetByIdResearch } from 'main/factories/usecases/research/GetByIdResearchFactory';
import EditSearch from 'presentation/components/Forms/EditSearch';
import { useDidUpdateEffect } from 'presentation/hooks/useDidUpdateEffect';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const EditResearchPage: React.FC = (): JSX.Element => {
  const [research, setResearch] = useState<GetByIdResearch.Model>(
    {} as GetByIdResearch.Model,
  );
  const [loading, setLoading] = useState(true);

  const isFirstRender = useDidUpdateEffect();

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    let isMounted = true;

    async function getResearch() {
      if (!id) return;
      setLoading(true);

      try {
        if (isFirstRender) return;

        const response = await makeRemoteGetByIdResearch().getById({
          researchId: Number(id),
        });

        if (isMounted) {
          setResearch(response);
        }
      } catch (error) {
        console.error(error);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    }
    getResearch();

    makeReduxListOrg().list({
      query: {
        limit: 9999,
        offset: 0,
      },
    });

    return () => {
      isMounted = false;
    };
  }, [id, isFirstRender]);

  return loading ? <div>...loading</div> : <EditSearch research={research} />;
};

export default EditResearchPage;
