import React from 'react';

const IconExitAccountBlue = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.4375 0H10.5625C11.9438 0 13 1.04 13 2.4V7.2H6.0125L7.88125 5.36C8.20625 5.04 8.20625 4.56 7.88125 4.24C7.55625 3.92 7.06875 3.92 6.74375 4.24L3.49375 7.44C3.16875 7.76 3.16875 8.24 3.49375 8.56L6.74375 11.76C7.06875 12.08 7.55625 12.08 7.88125 11.76C8.20625 11.44 8.20625 10.96 7.88125 10.64L6.0125 8.8H13V13.6C13 14.96 11.9438 16 10.5625 16H2.4375C1.05625 16 0 14.96 0 13.6V2.4C0 1.04 1.05625 0 2.4375 0Z"
        fill="#0B77CD"
      />
    </svg>
  );
};

export default IconExitAccountBlue;
