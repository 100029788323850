import NewMessageForm from 'presentation/components/Forms/NewMessageForm';
import { Header } from 'presentation/components/Header';
import React from 'react';

// import { Container } from './styles';

const NewMessagePage: React.FC = () => {
  return (
    <div>
      <div style={{ position: 'fixed', width: '100%', zIndex: 4 }}>
        <Header />
      </div>
      <div
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: '#FCFCFC',
        }}
      >
        <NewMessageForm />
      </div>
    </div>
  );
};

export default NewMessagePage;
