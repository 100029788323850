import React from 'react';

const IconPerson = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 12.25C0 9.92 4.66 8.75 7 8.75C9.34 8.75 14 9.92 14 12.25V14H0V12.25Z"
        fill={props.fill ?? '#BAB9B9'}
      />
      <path
        d="M10.5 3.5C10.5 5.43 8.93 7 7 7C5.07 7 3.5 5.43 3.5 3.5C3.5 1.57 5.07 0 7 0C8.93 0 10.5 1.57 10.5 3.5Z"
        fill={props.fill ?? '#BAB9B9'}
      />
      <path
        d="M16 12.25C16 10.77 15.2 9.65 14.04 8.81C16.5 9.08 20 10.23 20 12.25V14H16V12.25Z"
        fill={props.fill ?? '#BAB9B9'}
      />
      <path
        d="M16.5 3.5C16.5 5.43 14.93 7 13 7C12.46 7 11.96 6.87 11.5 6.65C12.13 5.76 12.5 4.67 12.5 3.5C12.5 2.33 12.13 1.24 11.5 0.35C11.96 0.13 12.46 0 13 0C14.93 0 16.5 1.57 16.5 3.5Z"
        fill={props.fill ?? '#BAB9B9'}
      />
    </svg>
  );
};

export default IconPerson;
