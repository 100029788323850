import store from 'data/store';
import { loginRequest } from 'data/store/reducer/auth/actions/login';
import { iActionLogin } from 'domain/interfaces/redux/auth/login';
import { iActionUpdate } from 'domain/interfaces/redux/auth/update';
import { LoginAuth } from 'domain/usecases/auth/redux';
import { Dispatch } from 'redux';
import { updateRequest } from '../actions/update';

export class ReduxLoginAuth implements LoginAuth {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  login = (params: LoginAuth.Params): iActionLogin =>
    this.send(loginRequest(params));

  update = (params: Partial<LoginAuth.Model['user']>): iActionUpdate =>
    this.send(updateRequest(params));
}
