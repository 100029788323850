import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { ListMedia as UsecaseRemoteListMedia } from 'domain/usecases/media/remote';
import { ListMedia as UsecaseReduxListMedia } from 'domain/usecases/media/redux';

import { RemoteListMedia } from 'data/repository/media';
import { ReduxListMedia } from 'data/store/reducer/media/usecases';

/**
 * send request via API.
 */
export const makeRemoteListMedia = (): UsecaseRemoteListMedia =>
  new RemoteListMedia(makeApiUrl('/medias/'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxListMedia = (): UsecaseReduxListMedia =>
  new ReduxListMedia();
