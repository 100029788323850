/* eslint-disable react/no-unknown-property */
import React from 'react';

const IconClosePrimary = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width ?? '8'}
      height={props.height ?? '8'}
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L7 7"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M7 1L1 7"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default IconClosePrimary;
