import { iMessage } from 'domain/interfaces/models';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { IconClose } from 'presentation/base/icons';
import { Button } from 'presentation/components/UI';
import React from 'react';
import { useTheme } from 'styled-components';
import {
  ActionCancel,
  Container,
  ContainerModal,
  Content,
  Footer,
  Header,
  Message,
  Text,
  Title,
} from './styles/StyledCancelEditModal';

interface propsCancelEditModal {
  message: iMessage;
}

const CancelEditModal: React.FC<propsCancelEditModal> = ({ message }) => {
  const msgName = MessageOptions.cancelEditModal;

  const theme = useTheme();

  const {
    active,
    actionCancel,
    actionOk,
    messageEdit,
    titleModal,
    cancelMessage,
    primaryActionMessage,
    secondaryActionMessage,
  } = message;

  return (
    <>
      {msgName === active && (
        <ContainerModal>
          <Container>
            <Header>
              <Title>{titleModal}</Title>
              <IconClose
                data-testid="close_modal"
                style={{
                  cursor: 'pointer',
                  position: 'absolute',
                  right: '28px',
                }}
                onClick={actionCancel}
              />
            </Header>
            <Content>
              <Message>
                {messageEdit && <Text>{messageEdit}</Text>}
                {cancelMessage && <ActionCancel>{cancelMessage}</ActionCancel>}
              </Message>

              <Footer>
                <Button
                  data-testid="btn_primary"
                  variant="secondary"
                  size="medium"
                  onClick={actionOk}
                >
                  {primaryActionMessage}
                </Button>
                <Button
                  data-testid="btn_secondary"
                  variant="primary-custom"
                  color={theme.colors.secondary.main}
                  size="medium"
                  onClick={actionCancel}
                >
                  {secondaryActionMessage}
                </Button>
              </Footer>
            </Content>
          </Container>
        </ContainerModal>
      )}
    </>
  );
};

export default CancelEditModal;
