import { Header } from 'presentation/components/Header';
import * as React from 'react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { createRoot } from 'react-dom/client';

import Router from './main/routes';
import HistoryProvider from './main/routes/_CustomRouter';

Sentry.init({
  dsn: 'https://6acf2cbb634f48b2acc9cc8b8c09ca3a@sentry.v4h.cloud/18',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

const root = createRoot(document.getElementById('root')!);

root.render(
  <React.StrictMode>
    <HistoryProvider>
      <Router />
    </HistoryProvider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
