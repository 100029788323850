import { all } from 'redux-saga/effects';

import GroupTasks from './groups';
import MediaTasks from './media';
import AuthTasks from './auth';
import ResearchTasks from './research';
import OrgTasks from './org';

export default function* rootSaga(): any {
  return yield all([
    ...AuthTasks,
    ...GroupTasks,
    ...MediaTasks,
    ...ResearchTasks,
    ...OrgTasks,
  ]);
}
