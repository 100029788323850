import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { DeleteUsersGroup as UsecaseRemoteDeleteUsersGroup } from 'domain/usecases/group/remote';
// import { DeleteGroup as UsecaseReduxDeleteGroup } from 'domain/usecases/group/redux';

import { RemoteDeleteUsersGroup } from 'data/repository/group';
// import { ReduxDeleteGroup } from 'data/store/reducer/group/usecases';

/**
 * send request via API.
 */
export const makeRemoteDeleteUsersGroup = (): UsecaseRemoteDeleteUsersGroup =>
  new RemoteDeleteUsersGroup(
    makeApiUrl('/groups/{group}/users'),
    makeHttpClient(),
  );

/**
 * send request via REDUX.
 */
/* export const makeReduxDeleteGroup =
  (): UsecaseReduxDeleteGroup =>
    new ReduxDeleteGroup(); */
