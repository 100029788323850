import React from 'react';

const IconView = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="17"
      height="12"
      viewBox="0 0 17 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.27273 0C4.5124 0 1.30107 2.32779 0 5.61364C1.30107 8.89949 4.5124 11.2273 8.27273 11.2273C12.0331 11.2273 15.2444 8.89949 16.5455 5.61364C15.2444 2.32779 12.0331 0 8.27273 0ZM8.27273 9.35606C6.19703 9.35606 4.5124 7.67946 4.5124 5.61364C4.5124 3.54782 6.19703 1.87121 8.27273 1.87121C10.3484 1.87121 12.0331 3.54782 12.0331 5.61364C12.0331 7.67946 10.3484 9.35606 8.27273 9.35606ZM8.27273 3.36818C7.0243 3.36818 6.01653 4.37115 6.01653 5.61364C6.01653 6.85612 7.0243 7.85909 8.27273 7.85909C9.52116 7.85909 10.5289 6.85612 10.5289 5.61364C10.5289 4.37115 9.52116 3.36818 8.27273 3.36818Z"
        fill={props.fill ?? '#7A7A7A'}
      />
    </svg>
  );
};

export default IconView;
