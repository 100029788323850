import { takeLatest } from 'redux-saga/effects';
import { MediaTypes } from 'domain/interfaces/redux/media/types';
import { onList, onListSuccess, onListFailed } from './List';

const tasks = [
  takeLatest(MediaTypes.LIST, onList),
  takeLatest(MediaTypes.LIST_SUCCESS, onListSuccess),
  takeLatest(MediaTypes.LIST_FAILED, onListFailed),
];

export default tasks;
