import { ForgotPassword } from 'domain/usecases/auth/remote';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
  NotFound,
} from 'domain/errors';
import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import 'infra/global/variables';
import { toast } from 'react-toastify';
import Translator from 'presentation/components/i18n/Translator';

export class RemoteForgotPassword implements ForgotPassword {
  private readonly url: string;

  private readonly httpClient: HttpClient<ForgotPassword.Model>;

  constructor(url: string, httClient: HttpClient<ForgotPassword.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  forgot = async (
    params: ForgotPassword.Params,
  ): Promise<ForgotPassword.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params,
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.noContent:
        toast.success(Translator('E-mail de recuperação enviado com sucesso!'));
        return httpResponse.body;

      case HttpStatusCode.badRequest:
        throw new BadRequestError();

      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();

      case HttpStatusCode.notFound:
        throw new NotFound();

      default:
        throw new UnexpectedError();
    }
  };
}
