import { UpdateImageUser } from 'domain/usecases/user/remote';
import {
  BadRequestError,
  Forbidden,
  UnexpectedError,
  UnProcessableEntity,
} from 'domain/errors';
import { HttpClient, HttpStatusCode } from 'data/protocols/http';
import 'infra/global/variables';

export class RemoteUpdateImageUser implements UpdateImageUser {
  private readonly url: string;

  private readonly httpClient: HttpClient<UpdateImageUser.Model>;

  constructor(url: string, httClient: HttpClient<UpdateImageUser.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  update = async (
    params: UpdateImageUser.Params,
  ): Promise<UpdateImageUser.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.user}/avatar`,
      method: 'post',
      body: params.file,
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new Forbidden();
      case HttpStatusCode.unprocessableEntity:
        throw new UnProcessableEntity();
      default:
        throw new UnexpectedError();
    }
  };
}
