import styled from 'styled-components';

export const ContainerLists = styled.div`
  margin-top: 2.6rem;
  width: 100%;
  padding: 0 7.2rem 7rem 7.2rem;
`;

export const SearchContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 2.7rem;
`;

export const ListResearch = styled.div`
  width: 100%;
  margin-top: 22px;
`;

export const MenuList = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

export const NavigationMenu = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;
