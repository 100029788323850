/* eslint-disable react/require-default-props */
import React from 'react';
import {
  Email,
  Left,
  ListItemContainer,
  Name,
  Right,
  StIconDelete,
  StIconPencil,
} from './styles';

type ListItemProps = {
  item: {
    userId: string;
    fullname: string;
    email?: string;
  };
  disabledActions?: boolean;
  handleDelete?: (id: string) => void;
  handleEdit?: (id: string) => void;
};

export const ListItem: React.FC<ListItemProps> = ({
  item,
  disabledActions = false,
  handleDelete,
  handleEdit,
}) => {
  return (
    <ListItemContainer>
      <Left>
        {/* <img alt="empty" /> */}
        <Name data-testid={`fullname_${item.userId}`}>{item.fullname}</Name>
      </Left>
      <Right>
        <Email data-testid={`email_${item.userId}`}>{item.email}</Email>
        {!disabledActions && (
          <>
            <StIconPencil
              data-testid={`btn_edit_${item.userId}`}
              onClick={() => handleEdit?.(item.userId ?? item.email)}
            />
            <StIconDelete
              data-testid={`btn_delete_${item.userId}`}
              onClick={() => handleDelete?.(item.userId ?? item.email)}
            />
          </>
        )}
      </Right>
    </ListItemContainer>
  );
};

export default ListItem;
