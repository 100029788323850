import styled, { css } from 'styled-components';

export const Title = styled.h4`
  display: flex;
  align-items: center;
  justify-content: center;
  font: ${({ theme }) => theme.typography.h5};
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 1.6rem;
  color: ${({ theme }) => theme.colors.primary.main};
`;

export const LinkToEdit = styled.span`
  font: ${({ theme }) => theme.typography.h7};
  font-size: 1.4rem;
  margin-left: 0.8rem;
  color: ${({ theme }) => theme.colors.secondary.main};
  cursor: pointer;
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  .description + .description {
    margin-left: 5.4rem;
  }
`;

export const TitleInformation = styled.h5`
  margin-bottom: 0.8rem;
  font: ${({ theme }) => theme.typography.h5};
  font-weight: 300;
  color: ${({ theme }) => theme.colors.typography.gray};
`;

export const Description = styled.p.attrs({
  className: 'description',
})`
  max-width: 35rem;
  font: ${({ theme }) => theme.typography.h7};
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.typography.darkGray};
`;

export const HorizontalDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.status.disable};
  margin-bottom: 2.2rem;
`;
