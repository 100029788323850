/* eslint-disable no-extra-boolean-cast */
import { iStore } from 'domain/interfaces/models';
import { makeReduxLogin } from 'main/factories/usecases/auth/LoginFactory';
import { makeRemoteGetByIdUser } from 'main/factories/usecases/user/GetByIdUserFactory';
import { makeRemoteUpdateUser } from 'main/factories/usecases/user/UpdateUser';
import { translator } from 'presentation/components/i18n';
import { Button, Input } from 'presentation/components/UI';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { schemaChangePassword } from 'validation/validators/changePassword';
import { Container, Form, RulesNewPassword, Title } from './styles';

const ChangePassword: React.FC = () => {
  const { id } = useParams();

  const formHook = useForm({
    shouldFocusError: true,
    resolver: schemaChangePassword,
  });

  const {
    getValues,
    handleSubmit,
    formState: { errors, isDirty },
    control,
    reset,
  } = formHook;

  async function handleUpdate() {
    try {
      await makeRemoteUpdateUser().update({
        userId: Number(id),
        user: {
          password: getValues()?.password,
        },
      });

      toast.success(translator('Usuário atualizado com sucesso!'));
    } catch (err) {
      toast.error(translator('Erro ao atualizar usuário!'));
    } finally {
      reset(getValues());
    }
  }

  return (
    <Container>
      <Title>{translator('Mudar senha')}</Title>
      <FormProvider {...formHook}>
        <Form onSubmit={handleSubmit(handleUpdate)}>
          <Controller
            control={control}
            name="password"
            render={({ field }) => (
              <Input
                data-testid="input_newPassword"
                label={translator('Nova senha')}
                width="360px"
                message={String(errors?.password?.message)}
                error={!!errors?.password}
                type="password"
                placeholder={translator('Digite nova senha')}
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="confirmPassword"
            render={({ field }) => (
              <Input
                data-testid="input_confirmPassword"
                label={translator('Repita a nova senha')}
                width="360px"
                message={String(errors?.confirmPassword?.message) ?? ''}
                error={!!errors?.confirmPassword}
                type="password"
                placeholder={translator('Digite novamente a nova senha')}
                {...field}
              />
            )}
          />
          <RulesNewPassword>
            <p>{translator('Regras para a nova senha:')}</p>
            <span>{translator('- Mínimo de 6 dígitos')}</span>
            <span>{translator('- Possuir letras e números')}</span>
            <span>{translator('- Ser diferente de seus dados pessoais')}</span>
          </RulesNewPassword>
          <Button
            data-testid="button_changePassword"
            variant="primary"
            size="medium"
            type="submit"
            disabled={
              !!errors?.password || !!errors?.confirmPassword || !isDirty
            }
            style={{ alignSelf: 'center', justifySelf: 'center' }}
          >
            {translator('Salvar Alterações')}
          </Button>
        </Form>
      </FormProvider>
    </Container>
  );
};

export default ChangePassword;
