import { takeLatest } from 'redux-saga/effects';
import { AuthTypes } from 'domain/interfaces/redux/auth/types';
import { onLogin, onLoginSuccess, onLoginFailed } from './Login';
import { onRefresh, onRefreshSuccess, onRefreshFailed } from './Refresh';

const tasks = [
  takeLatest(AuthTypes.LOGIN, onLogin),
  takeLatest(AuthTypes.LOGIN_SUCCESS, onLoginSuccess),
  takeLatest(AuthTypes.LOGIN_FAILED, onLoginFailed),
  takeLatest(AuthTypes.REFRESH, onRefresh),
  takeLatest(AuthTypes.REFRESH_SUCCESS, onRefreshSuccess),
  takeLatest(AuthTypes.REFRESH_FAILED, onRefreshFailed),
];

export default tasks;
