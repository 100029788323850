import { Checkbox } from '@material-ui/core';
import { IconChecked } from 'presentation/base/icons';
import Translator from 'presentation/components/i18n/Translator';
import Input from 'presentation/components/UI/input';
import React from 'react';
import { CreateMessageProps } from '..';
import { Notes } from '../step2/notes';
import {
  CheckBoxContent,
  ContainerSteps,
  NormalTitle,
  OptionsContent,
  SubTitleSteps,
  TitleSteps,
} from '../styles';
import { CheckBox } from './styles';

interface Step3Props {
  createMessage: CreateMessageProps;
  setCreateMessage: (prevState: CreateMessageProps) => any;
}

const Step3: React.FC<Step3Props> = ({ createMessage, setCreateMessage }) => {
  const handleChoseChannel = (currentChannel: string) => {
    if (createMessage?.channel?.includes(currentChannel)) {
      const newChannel = createMessage?.channel?.filter(
        channel => channel !== currentChannel,
      );

      setCreateMessage({
        ...createMessage,
        channel: newChannel,
      });

      return;
    }

    if (createMessage?.channel?.length > 0) {
      setCreateMessage({
        ...createMessage,
        channel: [...createMessage.channel, currentChannel],
      });
    } else {
      setCreateMessage({
        ...createMessage,
        channel: [currentChannel],
      });
    }
  };

  return (
    <ContainerSteps>
      <TitleSteps>{Translator('3. Mensagem')}</TitleSteps>
      <SubTitleSteps style={{ marginBottom: 12 }}>
        {Translator('Enviar por')}
        <p style={{ color: 'red' }}>*</p>
      </SubTitleSteps>
      <OptionsContent>
        <CheckBoxContent>
          <CheckBox
            data-testid="checkbox-sms"
            onClick={() => handleChoseChannel('SMS')}
            active={createMessage?.channel?.includes('SMS')}
          >
            {createMessage?.channel?.includes('SMS') ? (
              <IconChecked />
            ) : (
              <div />
            )}
          </CheckBox>
          <NormalTitle style={{ marginLeft: 8 }}>SMS</NormalTitle>
        </CheckBoxContent>
        <CheckBoxContent>
          <CheckBox
            data-testid="checkbox-email"
            onClick={() => handleChoseChannel('EMAIL')}
            active={createMessage?.channel?.includes('EMAIL')}
          >
            {createMessage?.channel?.includes('EMAIL') ? (
              <IconChecked />
            ) : (
              <div />
            )}
          </CheckBox>
          <NormalTitle style={{ marginLeft: 8 }}>E-mail</NormalTitle>
        </CheckBoxContent>
      </OptionsContent>
      <SubTitleSteps style={{ marginBottom: 12 }}>
        {Translator('Título')}
        <p style={{ color: 'red' }}>*</p>
      </SubTitleSteps>
      <Input
        data-testid="input-title"
        placeholder={Translator('Digite o título')}
        onChange={e =>
          setCreateMessage({ ...createMessage, subject: e.target.value })
        }
      />
      <SubTitleSteps style={{ marginBottom: 12 }}>
        {Translator('Conteúdo')}
        <p style={{ color: 'red' }}>*</p>
      </SubTitleSteps>
      <Notes
        createMessage={createMessage}
        setCreateMessage={setCreateMessage}
        characterLimit={1600}
      />
    </ContainerSteps>
  );
};

export default Step3;
