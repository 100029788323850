import { IconMediaLibrary } from 'presentation/base/icons';
import { Header, HeaderSimples } from 'presentation/components/Header';
import Translator from 'presentation/components/i18n/Translator';
import { MediaLibraryPage } from 'presentation/pages';
import React from 'react';
import {
  Body,
  Container,
  ContentHeaderSimples,
} from './styles/StyledMediaLibrary';

const MediaLibraryLandingPage: React.FC = (): JSX.Element => {
  return (
    <Container>
      <Header />

      <ContentHeaderSimples>
        <HeaderSimples
          title={Translator('Biblioteca de Mídias')}
          Icon={IconMediaLibrary}
        />
      </ContentHeaderSimples>
      <Body>
        <MediaLibraryPage />
      </Body>
    </Container>
  );
};

export default MediaLibraryLandingPage;
