/* eslint-disable react/require-default-props */
/* eslint-disable prefer-template */
import React, { useState } from 'react';
import { ptBR } from 'date-fns/locale';
import { DatePicker, DatePickerProps } from 'react-nice-dates';
import 'react-nice-dates/build/style.css';
import Input from 'presentation/components/UI/input';

interface DatepickerProps {
  date: Date;
  onDateChange: (date: Date | null) => void;
  disabled?: boolean;
}

export const Datepicker: React.FC<DatepickerProps> = ({
  date,
  onDateChange,
  disabled = false,
}) => {
  return (
    <DatePicker
      date={date}
      onDateChange={onDateChange}
      locale={ptBR}
      weekdayFormat="EEEEE"
      minimumDate={new Date()}
    >
      {({ inputProps, focused }) => (
        <Input
          disabled={disabled}
          className={'input' + (focused ? ' -focused' : '')}
          {...inputProps}
        />
      )}
    </DatePicker>
  );
};

export default Datepicker;
