import { iMedia } from 'domain/interfaces/models/Media';
import { MediaTypes } from 'domain/interfaces/redux/media/types';
import { MediaActions } from './actions';

export const initialState: iMedia = {
  loading: false,
  records: [],
};

const reducer = (state = initialState, action: MediaActions): iMedia => {
  switch (action.type) {
    case MediaTypes.LIST:
      return { ...state, loading: true };
    case MediaTypes.LIST_FAILED:
      return { ...state, loading: false };
    case MediaTypes.LIST_SUCCESS: {
      return { ...action.payload, loading: false };
    }
    default:
      return state;
  }
};

export default reducer;
