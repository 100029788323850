/* eslint-disable react/button-has-type */
import { useDisclosure, useToast, ToastId } from '@chakra-ui/react';
import { Button } from 'presentation/components/UI';
import { ViewMedia } from 'presentation/components/UI/modals/viewMedia';
import { ToastNotify } from 'presentation/components/UI/toastNotify';
import React, { useCallback, useEffect, useState } from 'react';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/UpdateFactory';

import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';
import {
  IconBackButton,
  IconNurse,
  IconDeskWaitingRoom,
  IconForm,
  IconLogoLaoha,
  IconMedia,
  IconTooth,
} from '../../base/icons';
import {
  BackDrop,
  Container,
  ContentForm,
  ContentTags,
  HeaderBack,
  Image,
  Logos,
  Main,
  ModalTest,
  Tag,
  TagText,
} from './styles/WaitingRoomPageStyled';

const WaitingRoomPage: React.FC = () => {
  const [isHint, setIsHint] = useState(true);
  const [openedMedia, setOpenedMedia] = useState(false);
  const [isStart, setIsStart] = useState(false);
  const modalName = MessageOptions.viewMediaModal;
  const { active } = useSelector((store: iStore) => store.message);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
  const toast = useToast({
    containerStyle: {
      width: '758px',
      maxWidth: '100%',
      height: '68px',
    },
  });
  const toastIdRef = React.useRef<ToastId>();

  function closeAll() {
    toast.closeAll();
  }

  function handleClick() {
    setIsHint(!isHint);
    onOpen();
  }

  function toastInfo() {
    if (!toast.isActive('info-toast'))
      toastIdRef.current = toast({
        id: 'info-toast',
        position: 'top',
        duration: 999999,
        render: () => (
          // eslint-disable-next-line react/jsx-no-bind
          <ToastNotify close={closeAll} icon={IconNurse} spin={false}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                height: '60px',
                fontSize: '16px',
                color: '#1C1413',
              }}
            >
              {/* <b>Se prepare!</b>
              <br /> */}
              <p>
                Selecionamos estas mídias para você interagir enquanto aguarda
                sua consulta.
              </p>
            </div>
          </ToastNotify>
        ),
      });
  }

  const startNotification = useCallback(() => {
    const id = 'start-toast';
    if (toast.isActive('info-toast')) toast.close('info-toast');

    if (!toast.isActive(id) && active === modalName) {
      toastIdRef.current = toast({
        id,
        position: 'top',
        duration: 999999,
        render: () => (
          <ToastNotify icon={IconNurse}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '60px',
                fontSize: '16px',
                color: '#1C1413',
                marginRight: '19px',
              }}
            >
              {/* <b>Se prepare!</b>
              <br /> */}
              <p>
                <b>O profissional está pronto! </b>
                Você será redirecionado para a tela pré-consulta.
              </p>
            </div>
          </ToastNotify>
        ),
      });
    }
  }, [active, modalName, toast]);

  function handleOpenModal(media: any) {
    // TODO: fix type for receive media
    makeReduxActiveMessage().active({
      active: MessageOptions.viewMediaModal,
      url: media,
    });
    setIsHint(false);
    setOpenedMedia(true);
    toastInfo();
  }

  useEffect(() => {
    if (isStart) startNotification();
  }, [active, isStart, startNotification]);

  useEffect(() => {
    async function check() {
      await sleep(5000);

      if (!openedMedia) {
        handleOpenModal('https://i.im.ge/2022/07/15/FoCL9C.png');
      }
    }

    async function start() {
      await sleep(8000);
      setIsStart(true);

      startNotification();
    }

    // check();
    start();
  }, []);

  return (
    <Container id="container">
      <HeaderBack>
        <IconBackButton />
        Voltar para Agenda de atendimentos
      </HeaderBack>
      <Main>
        {isStart && active !== modalName && (
          <ToastNotify width="680px">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '60px',
                fontSize: '16px',
                color: '#1C1413',
                marginRight: '19px',
              }}
            >
              <p>
                <b>O profissional está pronto! </b>
                Você será redirecionado para a tela pré-consulta.
              </p>
            </div>
          </ToastNotify>
        )}
        {!isStart && (
          <BackDrop>
            <ContentForm isHint={isHint}>
              <ContentTags>
                <Tag
                  onClick={() => {
                    handleOpenModal('https://i.im.ge/2022/07/15/FoCL9C.png');
                  }}
                >
                  <TagText>
                    <IconTooth />
                  </TagText>
                  Colgate
                </Tag>
                <Tag
                  onClick={() =>
                    handleOpenModal('https://i.im.ge/2022/07/15/FoC1Q9.png')
                  }
                >
                  <TagText>
                    <IconMedia />
                  </TagText>
                  Vídeo sobre escovação
                </Tag>
                <Tag
                  onClick={() =>
                    handleOpenModal('https://i.im.ge/2022/07/15/FoCaPY.png')
                  }
                >
                  <TagText>
                    <IconForm />
                  </TagText>
                  Formulário pré consulta
                </Tag>
              </ContentTags>
            </ContentForm>
            {isHint && (
              <ModalTest>
                <p>Você pode interagir com as mídias acima enquanto espera!</p>
                <Button
                  color="#0B77CD"
                  variant="primary-custom"
                  onClick={() => handleClick()}
                >
                  OK
                </Button>
              </ModalTest>
            )}
          </BackDrop>
        )}
        <Image>
          <IconDeskWaitingRoom id="icon_desk" />
          <Logos>
            <IconLogoLaoha id="icon" />
            <IconLogoLaoha id="icon" />
          </Logos>
        </Image>
      </Main>
      <ViewMedia
        closeCallback={() => {
          toast.closeAll();
        }}
      />
    </Container>
  );
};

export default WaitingRoomPage;
