import { ListMedia } from 'domain/usecases/media/redux/ListMedia';
import {
  iActionList,
  iActionListFailed,
  iActionListSuccess,
  MediaTypes,
} from 'domain/interfaces/redux/media/list';

export const listRequest = (payload: ListMedia.Params): iActionList => ({
  type: MediaTypes.LIST,
  payload,
});

export const listSuccess = (params: ListMedia.Model): iActionListSuccess => ({
  type: MediaTypes.LIST_SUCCESS,
  payload: params,
});

export const listFailed = (): iActionListFailed => ({
  type: MediaTypes.LIST_FAILED,
});
