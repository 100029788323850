import { IconPencil, IconTrash } from 'presentation/base/icons';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 1.5rem 2rem;
  align-items: flex-start;
  justify-content: space-between;

  border-radius: 0.5rem;
  border: 0.1rem solid #e7e7e7;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const TitlePage = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  background: ${({ theme }) => theme.colors.background.grey};
  color: ${({ theme }) => theme.colors.typography.gray3};

  font: ${({ theme }) => theme.typography.h5};
  font-weight: 700;
`;

export const VerticalDivider = styled.div`
  width: 0.1rem;
  height: 100%;
  min-height: 40rem;
  background-color: #efefef;
  margin: 0 1rem;

  @media (max-width: 768px) {
    width: 100%;
    height: 1px;
    margin: 0;
    min-height: 0.1rem;
  }
`;

type ColumnProps = {
  disablePadding?: boolean;
};

export const Column = styled.div<ColumnProps>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  ${({ disablePadding }) =>
    disablePadding ? 'padding: 0' : 'padding: 2.4rem 3.2rem'};
`;

type RowProps = {
  size?: 'small' | 'medium' | 'large' | 'full';
  noMb?: boolean;
  disabledResponsive?: boolean;
};

export const Row = styled.div<RowProps>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2rem;
  margin-bottom: ${({ noMb }) => (noMb ? 0 : '2.7rem')};

  width: ${({ size, disabledResponsive }) => {
    if (!disabledResponsive)
      switch (size) {
        case 'small':
          return '25%';
        case 'medium':
          return '50%';
        case 'large':
          return '75%';
        case 'full':
          return '100%';
        default:
          return 'auto';
      }
    return '100%';
  }};

  @media (max-width: 1024px) {
    ${({ disabledResponsive }) =>
      disabledResponsive
        ? css`
            width: 100%;
          `
        : css`
            width: 75%;
          `}
  }
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

type TitleProps = {
  mb?: boolean;
  variant?: boolean;
};

export const Title = styled.h4<TitleProps>`
  font: ${({ theme }) => theme.typography.h4};
  ${({ mb }) => (mb ? 'margin-bottom: 4rem;' : 'margin-bottom: 2rem')};
  ${({ variant }) =>
    variant &&
    css`
      font-size: 1.6rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.typography.gray};
    `}
`;

export const Subtitle = styled.span`
  font: ${({ theme }) => theme.typography.h5};
  line-height: 22px;
  margin-bottom: 3.2rem;
  max-width: 50rem;
  color: ${({ theme }) => theme.colors.typography.gray};
`;

export const ContainerForm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 7.2rem;

  @media (max-width: 768px) {
    padding: 0 0.5rem;
  }
`;

export const StepContainer = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5rem;
  padding-top: 4rem;
  padding-bottom: 1.2rem;
  /* padding-bottom: 2rem; */
  border: 1px solid #efefef;
  border-radius: 0.5rem;
  margin: 1rem 0;

  .step-container {
    width: 60%;
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

export const ContentStepForm = styled.form`
  width: 100%;
`;

export const FooterContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0 3rem 0;

  .buttons-right {
    display: flex;
    align-items: center;
    justify-content: space-around;

    .button-right + .button-right {
      margin-left: 0.8rem;
    }
  }
`;

type ContainerListProps = {
  isEmpty?: boolean;
};

export const ContainerList = styled.div<ContainerListProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border: 1px solid #efefef;
  border-radius: 5px;
  ${({ isEmpty }) =>
    isEmpty &&
    css`
      align-items: center;
      justify-content: center;
      padding: 2.1rem;
      font: ${({ theme }) => theme.typography.h7};
      font-size: 1.4rem;
      color: ${({ theme }) => theme.colors.typography.lightGray};
    `}
`;

export const ListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0;
  margin: 0;
  list-style: none;
  width: 100%;

  .list-item + .list-item {
    border-top: 1px solid ${({ theme }) => theme.colors.status.disable};
  }
`;

export const ListItemContainer = styled.li.attrs({
  className: 'list-item',
})`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.7rem;
  border-radius: 0.5rem;
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .btn_icon {
    margin-left: 1.5rem;
  }
  .btn_icon + .btn_icon {
    margin-left: 1.2rem;
  }
`;

export const Name = styled.span`
  font: ${({ theme }) => theme.typography.h5};
  color: ${({ theme }) => theme.colors.typography.dark};
`;

export const Email = styled.span`
  font: ${({ theme }) => theme.typography.h5};
  color: ${({ theme }) => theme.colors.typography.gray};
`;

export const StIconPencil = styled(IconPencil).attrs({
  className: 'btn_icon',
})`
  cursor: pointer;
`;

export const StIconDelete = styled(IconTrash).attrs({
  className: 'btn_icon',
})`
  cursor: pointer;
`;
