import { makeApiUrl, makeHttpClient } from 'main/factories/http';
import { GetByIdResearch as UsecaseRemoteGetByIdResearch } from 'domain/usecases/research/remote';
// import { GetByIdResearch as UsecaseReduxGetByIdResearch } from 'domain/usecases/research/redux';

import { RemoteGetByIdResearch } from 'data/repository/research';
// import { ReduxGetByIdResearch } from 'data/store/reducer/research/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetByIdResearch = (): UsecaseRemoteGetByIdResearch =>
  new RemoteGetByIdResearch(
    makeApiUrl('/researches/{research}'),
    makeHttpClient(),
  );

/**
 * send request via REDUX.
 */
/* export const makeReduxGetByIdResearch =
  (): UsecaseReduxGetByIdResearch =>
    new ReduxGetByIdResearch(); */
